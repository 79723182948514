export const DISCIPLINES_LIST_REQUEST = "DISCIPLINES_LIST_REQUEST"
export const DISCIPLINES_LIST_SUCCESS = "DISCIPLINES_LIST_SUCCESS"
export const DISCIPLINES_LIST_FAIL = "DISCIPLINES_LIST_FAIL"

export const DISCIPLINES_DETAILS_REQUEST = "DISCIPLINES_DETAILS_REQUEST"
export const DISCIPLINES_DETAILS_SUCCESS = "DISCIPLINES_DETAILS_SUCCESS"
export const DISCIPLINES_DETAILS_FAIL = "DISCIPLINES_DETAILS_FAIL"

export const DISCIPLINES_CREATE_REQUEST = "DISCIPLINES_CREATE_REQUEST"
export const DISCIPLINES_CREATE_SUCCESS = "DISCIPLINES_CREATE_SUCCESS"
export const DISCIPLINES_CREATE_FAIL = "DISCIPLINES_CREATE_FAIL"
export const DISCIPLINES_CREATE_RESET = "DISCIPLINES_CREATE_RESET"

export const DISCIPLINES_DELETE_REQUEST = "DISCIPLINES_DELETE_REQUEST"
export const DISCIPLINES_DELETE_SUCCESS = "DISCIPLINES_DELETE_SUCCESS"
export const DISCIPLINES_DELETE_FAIL = "DISCIPLINES_DELETE_FAIL"

export const DISCIPLINES_UPDATE_REQUEST = "DISCIPLINES_UPDATE_REQUEST"
export const DISCIPLINES_UPDATE_SUCCESS = "DISCIPLINES_UPDATE_SUCCESS"
export const DISCIPLINES_UPDATE_FAIL = "DISCIPLINES_UPDATE_FAIL"
export const DISCIPLINES_UPDATE_RESET = "DISCIPLINES_UPDATE_RESET"
