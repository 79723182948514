import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'

const MemberCard = (props) => {

    let handleCards = (i, e) => {
        let newFormValues = [...props.formCards];
        newFormValues[i][e.target.name] = e.target.value;
        props.setFormCards(newFormValues);
    }

    let addCards = () => {
        props.setFormCards([...props.formCards, {title: "", price: "", calendar: ""}])
    }

    let removeCards = (i) => {
        let newFormValues = [...props.formCards];
        newFormValues.splice(i, 1);
        props.setFormCards(newFormValues)
    }

    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Carte d'adhérents</p>
                    <br/>
                    <p className="label-2">Informations complémentaires</p>
                    <div className="name">
                        <textarea className="input-offer-desc" placeholder="Informations"
                                  maxLength="10000" rows="5" value={props.descCardsPrecisions}
                                  onChange={e => props.setDescCardsPrecisions(e.target.value)}
                                  style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Description</p>
                    <div className="name" style={{flexDirection: "column"}}>
                        {props.formCards.map((element, index) => (
                            <div className="name" key={`${element}_${index}`}>
                                <input name="title" className="input-offer-title" placeholder="Nom de la carte"
                                       onChange={(e) => handleCards(index, e)} value={element.title}/>
                                <input name="price" className="input-offer-price" placeholder="Prix"
                                       value={element.price}
                                       onChange={(e) => handleCards(index, e)}/>
                                <select name="calendar" className="state-offer" placeholder="Date"
                                        value={element.calendar}
                                        onChange={(e) => handleCards(index, e)}/>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeCards(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <br/>
                        <button className="button-add" type="button" style={{width: "30%"}}
                                onClick={() => addCards()}>Ajouter une carte
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberCard
