import {
    CIRCUITS_CREATE_FAIL,
    CIRCUITS_CREATE_REQUEST,
    CIRCUITS_CREATE_SUCCESS,
    CIRCUITS_CREATE_RESET,
    CIRCUITS_DELETE_FAIL,
    CIRCUITS_DELETE_REQUEST,
    CIRCUITS_DELETE_SUCCESS,
    CIRCUITS_DETAILS_FAIL,
    CIRCUITS_DETAILS_REQUEST,
    CIRCUITS_DETAILS_SUCCESS,
    CIRCUITS_LIST_FAIL,
    CIRCUITS_LIST_REQUEST,
    CIRCUITS_LIST_SUCCESS,
    CIRCUITS_UPDATE_FAIL,
    CIRCUITS_UPDATE_REQUEST,
    CIRCUITS_UPDATE_SUCCESS,
    CIRCUITS_UPDATE_RESET
} from "../constants/circuitsConstants";

export const circuitsListReducers = (state = {circuits: []}, action) => {
    switch (action.type) {
        case CIRCUITS_LIST_REQUEST:
            return {loading: true, circuits: []}
        case CIRCUITS_LIST_SUCCESS:
            return {loading: false, circuits: action.payload}
        case CIRCUITS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const circuitsDetailsReducers = (state = {circuits: {}}, action) => {
    switch (action.type) {
        case CIRCUITS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case CIRCUITS_DETAILS_SUCCESS:
            return {loading: false, circuits: action.payload}
        case CIRCUITS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const circuitsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case CIRCUITS_CREATE_REQUEST:
            return {loading: true}
        case CIRCUITS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case CIRCUITS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case CIRCUITS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const circuitsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case CIRCUITS_DELETE_REQUEST:
            return {loading: true}
        case CIRCUITS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case CIRCUITS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const circuitsUpdateReducers = (state = {circuits: {}}, action) => {
    switch (action.type) {
        case CIRCUITS_UPDATE_REQUEST:
            return {loading: true}
        case CIRCUITS_UPDATE_SUCCESS:
            return {loading: false, success: true, circuits: action.payload}
        case CIRCUITS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case CIRCUITS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
