import {
    ACTIVITY_TYPES_CREATE_FAIL,
    ACTIVITY_TYPES_CREATE_REQUEST,
    ACTIVITY_TYPES_CREATE_RESET,
    ACTIVITY_TYPES_CREATE_SUCCESS,
    ACTIVITY_TYPES_DELETE_FAIL,
    ACTIVITY_TYPES_DELETE_REQUEST,
    ACTIVITY_TYPES_DELETE_SUCCESS,
    ACTIVITY_TYPES_DETAILS_FAIL,
    ACTIVITY_TYPES_DETAILS_REQUEST,
    ACTIVITY_TYPES_DETAILS_SUCCESS,
    ACTIVITY_TYPES_LIST_FAIL,
    ACTIVITY_TYPES_LIST_REQUEST,
    ACTIVITY_TYPES_LIST_SUCCESS,
    ACTIVITY_TYPES_UPDATE_FAIL,
    ACTIVITY_TYPES_UPDATE_REQUEST,
    ACTIVITY_TYPES_UPDATE_SUCCESS,
    ACTIVITY_TYPES_UPDATE_RESET
} from "../constants/activityTypesConstants";

export const activityTypesListReducers = (state = {ActivityTypes: []}, action) => {
    switch (action.type) {
        case ACTIVITY_TYPES_LIST_REQUEST:
            return {loading: true, ActivityTypes: []}
        case ACTIVITY_TYPES_LIST_SUCCESS:
            return {loading: false, ActivityTypes: action.payload}
        case ACTIVITY_TYPES_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const activityTypesDetailsReducers = (state = {ActivityTypes: {}}, action) => {
    switch (action.type) {
        case ACTIVITY_TYPES_DETAILS_REQUEST:
            return {loading: true, ...state}
        case ACTIVITY_TYPES_DETAILS_SUCCESS:
            return {loading: false, ActivityTypes: action.payload}
        case ACTIVITY_TYPES_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const activityTypesCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case ACTIVITY_TYPES_CREATE_REQUEST:
            return {loading: true}
        case ACTIVITY_TYPES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case ACTIVITY_TYPES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ACTIVITY_TYPES_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const activityTypesDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case ACTIVITY_TYPES_DELETE_REQUEST:
            return {loading: true}
        case ACTIVITY_TYPES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case ACTIVITY_TYPES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const activityTypesUpdateReducers = (state = {ActivityTypes: {}}, action) => {
    switch (action.type) {
        case ACTIVITY_TYPES_UPDATE_REQUEST:
            return {loading: true}
        case ACTIVITY_TYPES_UPDATE_SUCCESS:
            return {loading: false, success: true, ActivityTypes: action.payload}
        case ACTIVITY_TYPES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case ACTIVITY_TYPES_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
