import React from 'react'
import '../../assets/css/breadcrumb.css'
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {useNavigate} from "react-router-dom";

const BreadcrumbBDD = (props) => {
    let history = useNavigate()
    let lowerName = props.name.toLowerCase()
    return (
        <div className="breadcrumb">
            <HomeRoundedIcon className="home_icon" onClick={() => history.push('/admin')}/>
            <ArrowForwardIosRoundedIcon className="arrow_icon"/>
            <span className="text" onClick={() => history.push('/admin/bdd')}>Bdd</span>
            <ArrowForwardIosRoundedIcon className="arrow_icon"/>
            <span className="text" onClick={() => history.push(`/admin/bdd/${lowerName}`)}>{props.name}</span>
        </div>
    )
}

export default BreadcrumbBDD
