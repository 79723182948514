export const ACTIVITY_TYPES_LIST_REQUEST = "ACTIVITY_TYPES_LIST_REQUEST"
export const ACTIVITY_TYPES_LIST_SUCCESS = "ACTIVITY_TYPES_LIST_SUCCESS"
export const ACTIVITY_TYPES_LIST_FAIL = "ACTIVITY_TYPES_LIST_FAIL"

export const ACTIVITY_TYPES_DETAILS_REQUEST = "ACTIVITY_TYPES_DETAILS_REQUEST"
export const ACTIVITY_TYPES_DETAILS_SUCCESS = "ACTIVITY_TYPES_DETAILS_SUCCESS"
export const ACTIVITY_TYPES_DETAILS_FAIL = "ACTIVITY_TYPES_DETAILS_FAIL"

export const ACTIVITY_TYPES_CREATE_REQUEST = "ACTIVITY_TYPES_CREATE_REQUEST"
export const ACTIVITY_TYPES_CREATE_SUCCESS = "ACTIVITY_TYPES_CREATE_SUCCESS"
export const ACTIVITY_TYPES_CREATE_FAIL = "ACTIVITY_TYPES_CREATE_FAIL"
export const ACTIVITY_TYPES_CREATE_RESET = "ACTIVITY_TYPES_CREATE_RESET"

export const ACTIVITY_TYPES_DELETE_REQUEST = "ACTIVITY_TYPES_DELETE_REQUEST"
export const ACTIVITY_TYPES_DELETE_SUCCESS = "ACTIVITY_TYPES_DELETE_SUCCESS"
export const ACTIVITY_TYPES_DELETE_FAIL = "ACTIVITY_TYPES_DELETE_FAIL"

export const ACTIVITY_TYPES_UPDATE_REQUEST = "ACTIVITY_TYPES_UPDATE_REQUEST"
export const ACTIVITY_TYPES_UPDATE_SUCCESS = "ACTIVITY_TYPES_UPDATE_SUCCESS"
export const ACTIVITY_TYPES_UPDATE_FAIL = "ACTIVITY_TYPES_UPDATE_FAIL"
export const ACTIVITY_TYPES_UPDATE_RESET = "ACTIVITY_TYPES_UPDATE_RESET"
