import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import SelectComponents from "../ui/SelectComponents";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";


const Vehicules = (props) => {

    // Vehicules Acceptés

    let handleVehicleTypeAcceptedChange = (i, e) => {
        let newFormValues = [...props.formVehiculeTypesAcceptedValues];
        newFormValues[i]["vehiculeTypesId"] = e;
        props.setformVehiculeTypesAcceptedValues(newFormValues);
    }

    let addVehicleTypeAcceptedFormFields = () => {
        props.setformVehiculeTypesAcceptedValues([...props.formVehiculeTypesAcceptedValues, {vehiculeTypesId: ""}])
    }

    let removeVehicleTypeAcceptedFormFields = (i) => {
        let newFormValues = [...props.formVehiculeTypesAcceptedValues];
        newFormValues.splice(i, 1);
        props.setformVehiculeTypesAcceptedValues(newFormValues)
    }

    // Vehicules Refusés

    let handleVehicleTypeRefusedChange = (i, e) => {
        let newFormValues = [...props.formVehiculeTypesRefusedValues];
        newFormValues[i]["vehiculeTypesId"] = e;
        props.setformVehiculeTypesRefusedValues(newFormValues);
    }

    let addVehicleTypeRefusedFormFields = () => {
        props.setformVehiculeTypesRefusedValues([...props.formVehiculeTypesRefusedValues, {vehiculeTypesId: ""}])
    }

    let removeVehicleTypeRefusedFormFields = (i) => {
        let newFormValues = [...props.formVehiculeTypesRefusedValues];
        newFormValues.splice(i, 1);
        props.setformVehiculeTypesRefusedValues(newFormValues)
    }

    return (
        <div className="coordonnees">
            <div className="names">
                <p className="first-subTitles">Précisions supplémentaires</p>
                <div className="name">
                    <CKEditor
                        editor={ ClassicEditor }
                        data={props.descPrecisions}
                        onChange={ ( event, editor ) => {
                            const data = editor.getData();
                            props.setDescPrecisions(data)
                        } }
                    />
                </div>
            </div>
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Véhicules autorisés</p>
                    <p className="label">Type de véhicule</p>
                    {props.formVehiculeTypesAcceptedValues.map((element, index) => (
                        <div className="name" key={`${element}_${index}`}>
                            <DriveEtaIcon className="name-icon"/>
                            <SelectComponents setValue={handleVehicleTypeAcceptedChange} index={index}
                                              listToFiltered={props.vehiculeTypes} value={element.vehiculeTypesId}
                                              placeholder={"Choisissez votre type de véhicule"}/>
                            {
                                index ?
                                    <button type="button" className="button-remove"
                                            onClick={() => removeVehicleTypeAcceptedFormFields(index)}>Remove</button>
                                    : null
                            }
                        </div>
                    ))}
                    <button className="button-add" type="button"
                            onClick={() => addVehicleTypeAcceptedFormFields()}>Add
                    </button>
                </div>
                <div className="names">
                    <p className="first-subTitles">Véhicules refusés</p>
                    <p className="label">Type de véhicule</p>
                    {props.formVehiculeTypesRefusedValues.map((element, index) => (
                        <div className="name" key={`${element}_${index}`}>
                            <DriveEtaIcon className="name-icon"/>
                            <SelectComponents setValue={handleVehicleTypeRefusedChange} index={index}
                                              listToFiltered={props.vehiculeTypes} value={element.vehiculeTypesId}
                                              placeholder={"Choisissez votre type de véhicule"}/>
                            {
                                index ?
                                    <button type="button" className="button-remove"
                                            onClick={() => removeVehicleTypeRefusedFormFields(index)}>Remove</button>
                                    : null
                            }
                        </div>
                    ))}
                    <button className="button-add" type="button"
                            onClick={() => addVehicleTypeRefusedFormFields()}>Add
                    </button>

                </div>
            </div>
        </div>
    )
}

export default Vehicules
