import {
    DICTIONNAIRE_CREATE_FAIL,
    DICTIONNAIRE_CREATE_REQUEST,
    DICTIONNAIRE_CREATE_SUCCESS,
    DICTIONNAIRE_CREATE_RESET,
    DICTIONNAIRE_DELETE_FAIL,
    DICTIONNAIRE_DELETE_REQUEST,
    DICTIONNAIRE_DELETE_SUCCESS,
    DICTIONNAIRE_DETAILS_FAIL,
    DICTIONNAIRE_DETAILS_REQUEST,
    DICTIONNAIRE_DETAILS_SUCCESS,
    DICTIONNAIRE_LIST_FAIL,
    DICTIONNAIRE_LIST_REQUEST,
    DICTIONNAIRE_LIST_SUCCESS,
    DICTIONNAIRE_UPDATE_FAIL,
    DICTIONNAIRE_UPDATE_REQUEST,
    DICTIONNAIRE_UPDATE_SUCCESS,
    DICTIONNAIRE_UPDATE_RESET
} from "../constants/dictionnaireConstants";

export const dictionnaireListReducers = (state = {Dictionnaire: []}, action) => {
    switch (action.type) {
        case DICTIONNAIRE_LIST_REQUEST:
            return {loading: true, Dictionnaire: []}
        case DICTIONNAIRE_LIST_SUCCESS:
            return {loading: false, Dictionnaire: action.payload}
        case DICTIONNAIRE_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const dictionnaireDetailsReducers = (state = {Dictionnaire: {}}, action) => {
    switch (action.type) {
        case DICTIONNAIRE_DETAILS_REQUEST:
            return {loading: true, ...state}
        case DICTIONNAIRE_DETAILS_SUCCESS:
            return {loading: false, Dictionnaire: action.payload}
        case DICTIONNAIRE_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const dictionnaireCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case DICTIONNAIRE_CREATE_REQUEST:
            return {loading: true}
        case DICTIONNAIRE_CREATE_SUCCESS:
            return {loading: false, success: true}
        case DICTIONNAIRE_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DICTIONNAIRE_CREATE_RESET:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const dictionnaireDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case DICTIONNAIRE_DELETE_REQUEST:
            return {loading: true}
        case DICTIONNAIRE_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DICTIONNAIRE_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const dictionnaireUpdateReducers = (state = {Dictionnaire: {}}, action) => {
    switch (action.type) {
        case DICTIONNAIRE_UPDATE_REQUEST:
            return {loading: true}
        case DICTIONNAIRE_UPDATE_SUCCESS:
            return {loading: false, success: true, Dictionnaire: action.payload}
        case DICTIONNAIRE_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case DICTIONNAIRE_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
