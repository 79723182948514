import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import Snackbar from "@mui/material/Snackbar";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {listOrganisations} from "../../../../actions/organisationsActions";
import MuiAlert from "@mui/material/Alert";
import {InfoRounded} from "@material-ui/icons";
import axios from "../../../../axios";
import {CONTACT_COMMERCIAL_CREATE_RESET} from '../../../../constants/contactCommercialConstants';
import {createContactCommercial} from "../../../../actions/contactCommercialActions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewContactCommercial = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    const [picture, setPicture] = useState()
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [infos, setInfos] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formValues, setFormValues] = useState([{organizationId: ""}])

    const saveFile = (e) => {
        setPicture(e.target.files[0]);
    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        let publicIdPP = ""

        try {
            if (picture) {
                formDataProfile.append("file", picture);
                formDataProfile.append("folder", "contacts/profile");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdPP = res.data.public_id
            }

            return [publicIdPP]
        } catch (ex) {
            console.log(ex);
        }

    };

    const handleClose = () => {
        setOpen(false)
    }

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let contactCommercialCreate = useSelector(state => state.contactCommercialCreate)
    const {success: successCreate, error: errorCreate} = contactCommercialCreate

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]["organizationId"] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, {organizationId: ""}])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const AddHandler = async () => {
        uploadFile().then(res => {
            let data = {
                firstname: firstname,
                lastname: lastname,
                picture: res[0],
                phone: phone,
                mail: email,
                infos: infos,
                organizationIds: formValues
            }
            dispatch(createContactCommercial(data))
        })

    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/contact-commercial')
                dispatch({type: CONTACT_COMMERCIAL_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 3000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    useEffect(() => {
        dispatch(listOrganisations())
    }, [dispatch])


    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le contact commercial a bien été ajouté !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Contact-Commercial"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Contact Commercial</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subCoord">
                        <div className="names">
                            <p className="label-2">Prénom</p>
                            <input className="input" placeholder="Prénom"
                                   value={firstname}
                                   onChange={e => setFirstname(e.target.value)}
                            />
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={lastname}
                                   onChange={e => setLastname(e.target.value)}
                            />
                            <p className="label-2">Image de profil</p>
                            <input className="input" placeholder="Pictures"
                                   type="file"
                                   onChange={e => saveFile(e)}
                            />
                            <p className="label-2">Téléphone</p>
                            <input className="input" placeholder="Téléphone"
                                   value={phone}
                                   onChange={e => setPhone(e.target.value)}
                            />
                            <p className="label-2">Adresse Mail</p>
                            <input className="input" placeholder="Email"
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                            />
                            <p className="label-2">Informations</p>
                            <textarea className="input" placeholder="Informations"
                                      value={infos}
                                      onChange={e => setInfos(e.target.value)}
                            />
                            <p className="label-2">Organisations</p>
                            {formValues.map((element, index) => (
                                <div className="name" style={{width: "400px"}}>
                                    <select className="state" value={element.organizationId}
                                            onChange={e => handleChange(index, e)}>
                                        <option className="sub" value="">-- Choisissez une
                                            Organisation --
                                        </option>
                                        {organisations.map(orga => {
                                            return <option key={orga.id}
                                                           value={orga.id}>{orga.name}</option>
                                        })}
                                    </select>
                                    {
                                        index ?
                                            <button type="button" className="button-remove"
                                                    onClick={() => removeFormFields(index)}>Remove</button>
                                            : null
                                    }
                                </div>
                            ))}
                            <button className="button-add" type="button"
                                    onClick={() => addFormFields()}>Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewContactCommercial
