export const DICTIONNAIRE_LIST_REQUEST = "DICTIONNAIRE_LIST_REQUEST"
export const DICTIONNAIRE_LIST_SUCCESS = "DICTIONNAIRE_LIST_SUCCESS"
export const DICTIONNAIRE_LIST_FAIL = "DICTIONNAIRE_LIST_FAIL"

export const DICTIONNAIRE_DETAILS_REQUEST = "DICTIONNAIRE_DETAILS_REQUEST"
export const DICTIONNAIRE_DETAILS_SUCCESS = "DICTIONNAIRE_DETAILS_SUCCESS"
export const DICTIONNAIRE_DETAILS_FAIL = "DICTIONNAIRE_DETAILS_FAIL"

export const DICTIONNAIRE_CREATE_REQUEST = "DICTIONNAIRE_CREATE_REQUEST"
export const DICTIONNAIRE_CREATE_SUCCESS = "DICTIONNAIRE_CREATE_SUCCESS"
export const DICTIONNAIRE_CREATE_FAIL = "DICTIONNAIRE_CREATE_FAIL"
export const DICTIONNAIRE_CREATE_RESET = "DICTIONNAIRE_CREATE_RESET"

export const DICTIONNAIRE_DELETE_REQUEST = "DICTIONNAIRE_DELETE_REQUEST"
export const DICTIONNAIRE_DELETE_SUCCESS = "DICTIONNAIRE_DELETE_SUCCESS"
export const DICTIONNAIRE_DELETE_FAIL = "DICTIONNAIRE_DELETE_FAIL"

export const DICTIONNAIRE_UPDATE_REQUEST = "DICTIONNAIRE_UPDATE_REQUEST"
export const DICTIONNAIRE_UPDATE_SUCCESS = "DICTIONNAIRE_UPDATE_SUCCESS"
export const DICTIONNAIRE_UPDATE_FAIL = "DICTIONNAIRE_UPDATE_FAIL"
export const DICTIONNAIRE_UPDATE_RESET = "DICTIONNAIRE_UPDATE_RESET"
