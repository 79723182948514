import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Documents = (props) => {

    return (
        <div className="presentations">
            <div className="subPres">
                <div className="images">
                    <p className="first-subTitles">Documents</p>
                    <p className="label">RIB / IBAN</p>
                    <div className="name">
                        <CreditCardIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "RIB")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">KBIS</p>
                    <div className="name">
                        <AccountBalanceIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "KBIS")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">Assurance RC</p>
                    <div className="name">
                        <AccountBalanceIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "RC")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">Statuts signés</p>
                    <div className="name">
                        <AccountBalanceIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "STATUS")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">Formulaire URSAFF</p>
                    <div className="name">
                        <AccountBalanceIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "URSAFF")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">CNI Recto</p>
                    <div className="name">
                        <PermIdentityIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "CNIRECTO")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                    <p className="label">CNI Verso</p>
                    <div className="name">
                        <PermIdentityIcon className="icon"/>
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "CNIVERSO")}
                               style={{margin: "auto"}}/>
                        <button className="delete_button_doc">
                            <DeleteIcon className="delete_icon"/>
                            <p style={{margin: "auto"}}>Supprimer</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Documents