import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Documents = (props) => {

    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Documents à présenter</p>
                    <br/>
                    <p className="label-2">Pièce d'identité</p>
                    {props.documents?.map((document, index) => {
                        if (props.allDocuments.includes(document.id) && document.category === 'Pièce d\'identité') {
                            return (
                                <button className="am-button actif" onClick={
                                    () => {
                                        props.removeArray(document.id, props.allDocuments, props.setAllDocuments)
                                    }}>
                                    {document.name}
                                </button>)
                        } else if (!props.allDocuments.includes(document.id) && document.category === 'Pièce d\'identité') {
                            return (
                                <button className="am-button"
                                        onClick={() => props.setAllDocuments([...props.allDocuments, document.id])}>
                                    {document.name}
                                </button>)
                        }
                        return null
                    })}
                    <br/><br/>
                    <p className="label-2">Conduite</p>
                    {props.documents?.map((document, index) => {
                        if (props.allDocuments.includes(document.id) && document.category === 'Conduite') {
                            return (
                                <button className="am-button actif" onClick={
                                    () => {
                                        props.removeArray(document.id, props.allDocuments, props.setAllDocuments)
                                    }}>
                                    {document.name}
                                </button>)
                        } else if (!props.allDocuments.includes(document.id) && document.category === 'Conduite') {
                            return (
                                <button className="am-button"
                                        onClick={() => props.setAllDocuments([...props.allDocuments, document.id])}>
                                    {document.name}
                                </button>)
                        }
                        return null
                    })}
                    <br/><br/>
                    <p className="label-2">Assurance</p>
                    {props.documents?.map((document, index) => {
                        if (props.allDocuments.includes(document.id) && document.category === 'Assurance') {
                            return (
                                <button className="am-button actif" onClick={
                                    () => {
                                        props.removeArray(document.id, props.allDocuments, props.setAllDocuments)
                                    }}>
                                    {document.name}
                                </button>)
                        } else if (!props.allDocuments.includes(document.id) && document.category === 'Assurance') {
                            return (
                                <button className="am-button"
                                        onClick={() => props.setAllDocuments([...props.allDocuments, document.id])}>
                                    {document.name}
                                </button>)
                        }
                        return null
                    })}
                </div>
            </div>
        </div>
    )
}

export default Documents
