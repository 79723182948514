import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listActivityTypes} from "../../../actions/activityTypesActions";
import {listActivityPhotosTypes} from "../../../actions/activityPhotosTypesActions";
import '../../../assets/css/calendar.css'
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const ActivityTypes = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {loading, error, ActivityTypes: activityTypesSimple} = activityTypesList

    let activityPhotosTypesList = useSelector(state => state.activityPhotosTypesList)
    const {loading: loadingAT, error: errorAT, ActivityTypes} = activityPhotosTypesList

    const columnsActivityType = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link"
                           href={`activite-simple/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        }
    ]

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        }, {
            label: "Type d'activité",
            name: "activityType",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`activite/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        }, {
            label: "Type de véhicule",
            name: "vehicleType",
            options: {
                filter: true,
                sort: true,

            }
        }, {
            label: "Photos Profile",
            name: "photos",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img alt={"ActivityAccelerioPP"}
                        src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${val}`} width={"100px"}/>)
                },
            }
        }, {
            label: "Photos Cover",
            name: "thumbnail",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img alt={"ActivityAccelerioThumbnail"}
                        src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${val}`} width={"100px"}/>)
                },
            }
        }
    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    console.log(ActivityTypes)
    useEffect(() => {
        dispatch(listActivityTypes())
        dispatch(listActivityPhotosTypes())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>

            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Activités</h2>
                <button className="new_button"
                        onClick={() => history('/activite-simple/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={activityTypesSimple}
                            columns={columnsActivityType}
                            options={options}
                        />
                    )}
            </div>

            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Association Image - Type de Véhicule</h2>
                <button className="new_button"
                        onClick={() => history('/activite/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loadingAT ? <p style={{color: "white"}}>Loading...</p> : errorAT ?
                    <p style={{color: "white"}}>{errorAT}</p> : (
                        <MUIDataTable
                            data={ActivityTypes}
                            columns={columns}
                            options={options}
                        />
                    )}
            </div>
        </ThemeProvider>
    )
}

export default ActivityTypes
