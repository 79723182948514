import React, {useState} from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import SelectComponents from "../ui/SelectComponents";
import Switch from '@mui/material/Switch';

const Description = (props) => {

    let handleActivity = (i, e) => {
        let newFormValues = [...props.formTypeActivityValues];
        newFormValues[i]["id"] = e;
        props.setFormTypeActivityValues(newFormValues);
    }

    let addActivityFormFields = () => {
        props.setFormTypeActivityValues([...props.formTypeActivityValues, {id: null}])
    }

    let removeActivityFormFields = (i) => {
        let newFormValues = [...props.formTypeActivityValues];
        newFormValues.splice(i, 1);
        props.setFormTypeActivityValues(newFormValues)
    }

    let handleLanguages = (i, e) => {
        let newFormValues = [...props.formLangValues];
        newFormValues[i]["id"] = e.target.value;
        props.setFormLangValues(newFormValues);
    }

    let addLangFormFields = () => {
        props.setFormLangValues([...props.formLangValues, {id: null}])
    }

    let removeLangFormFields = (i) => {
        let newFormValues = [...props.formLangValues];
        newFormValues.splice(i, 1);
        props.setFormLangValues(newFormValues)
    }


    const handleChange = (event) => {
        props.setPublished(event.target.checked ? 1 : 0);
    };

    const handleChangeBooking = (event) => {
        props.setBooking(event.target.checked ? 1 : 0);
    };

    const handleChecked = (event) => {
        props.setBilleterie(event.target.checked ? 1 : 0)
    }

    console.log(props.billeterie)
    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p style={{color: "white"}}>{props.published ? "Présent dans le moteur de recherche" : "Non-présent dans le moteur de recherche"}</p>
                    <Switch
                        checked={props.published}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <p style={{color: "white"}}>{props.booking ? "Module de paiement disponible" : "Module de paiement pas disponible"}</p>
                    <Switch
                        checked={props.booking}
                        onChange={handleChangeBooking}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2 className="first-subTitles">Description</h2>
                        <div
                            style={{display: "flex", border: "1px solid white", borderRadius: "10px", padding: "15px"}}>
                            <Switch
                                checked={props.billeterie}
                                onChange={handleChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <label style={{margin: "auto", color: "white"}} htmlFor="option">Ceci est une billeterie</label>
                        </div>
                    </div>
                    <br/>
                    <p className="label-2">Titre de l'offre</p>
                    <div className="name">
                        <input className="input-offer-desc" placeholder="Titre de l'offre"
                               value={props.descTitle}
                               onChange={e => props.setDescTitle(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Slug</p>
                    <div className="name">
                        <input className="input-offer-desc" placeholder="Slug"
                               value={props.slug}
                               onChange={e => props.setSlug(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>

                    <p className="label-2">Commission</p>
                    <div className="name">
                        <input className="input-offer-desc" placeholder="Commission"
                               type="number"
                               value={props.commission}
                               onChange={e => props.setCommission(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Présentation</p>
                    <div className="name">
                        <CKEditor
                            className="input-offer-desc"
                            editor={ClassicEditor}
                            data={props.descModalites || ""}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setDescModalites(data)
                            }}
                        />
                    </div>
                    <p className="label-2">Niveaux acceptés</p>
                    <div>
                        {props.Levels?.map((levels, index) => {
                            if (props.allLevels.includes(levels.id)) {
                                return (
                                    <button className="am-button-lvl actif" onClick={
                                        () => {
                                            props.removeArray(levels.id, props.allLevels, props.setAllLevels)
                                        }}>
                                        {levels.name}
                                    </button>)
                            } else if (!props.allLevels.includes(levels.id)) {
                                return (
                                    <button className="am-button-lvl"
                                            onClick={() => props.setAllLevels([...props.allLevels, levels.id])}>
                                        {levels.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                    <p className="label-2">Programme</p>
                    <div className="name">
                        <CKEditor
                            className="input-offer-desc"
                            editor={ClassicEditor}
                            data={props.programme || " "}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                props.setProgramme(data)
                            }}
                        />
                    </div>
                    <div>
                        <p className="label-2">Organisateur</p>
                        <div className="name">
                            <select className="input-offer-desc" name="organisateur" value={props.organisateur}
                                    onChange={e => props.setOrganisateur(e.target.value)}>
                                <option value="">Choisir un organisateur</option>
                                {props.organisations.map(elt => (
                                    <option value={elt.id}>{elt.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="names">
                        <p className="first-subTitles">Activités</p>
                        <p className="label">Type d'activites</p>
                        {props.formTypeActivityValues.map((element, index) => (
                            <div className="name" key={`${element}_${index}`}>
                                <DriveEtaIcon className="name-icon"/>
                                <SelectComponents setValue={handleActivity} index={index}
                                                  listToFiltered={props.activityList} value={element.id}
                                                  placeholder={"Choisissez votre type d'activité"}/>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeActivityFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button"
                                onClick={() => addActivityFormFields()}>Add
                        </button>
                    </div>

                    <div className="names">
                        <p className="first-subTitles">Langues</p>
                        <p className="label">Langues</p>
                        {props.formLangValues.map((element, index) => (
                            <div className="name" key={`${element}_${index}`}>
                                <DriveEtaIcon className="name-icon"/>
                                <select className="input-offer-desc" value={element.id} onChange={e => handleLanguages(index, e)}>
                                    <option value={""}>Choisissez une langue</option>
                                    {props.listLang.map(elt => (
                                        <option value={elt.id}>{elt.fullName}</option>
                                    ))}
                                </select>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeLangFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button"
                                onClick={() => addLangFormFields()}>Add
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Description
