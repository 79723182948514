import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listDisciplines} from "../../../actions/disciplinesActions";
import {API_URL} from "../../../config";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Disciplines = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let disciplinesList = useSelector(state => state.disciplinesList)
    const {loading, error, Disciplines} = disciplinesList

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        }, {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`disciplines/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        }, {
            label: "Thumbnail",
            name: "thumbnail",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img alt={"AccelerioDisciplinesMiniature"}
                        src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${val}`}/>)
                },
            }
        }, {
            label: "Photos Profile",
            name: "profile_picture",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img alt="AccelerioImagePP"
                        src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${val}`}/>)
                },
            }
        }, {
            label: "Descriptions",
            name: "description",
            options: {
                filter: true,
                sort: true,
            }
        }
    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listDisciplines())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Disciplines</h2>
                <button className="new_button"
                        onClick={() => history('/disciplines/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={Disciplines}
                            columns={columns}
                            options={options}
                        />
                    )}
            </div>
        </ThemeProvider>
    )
}

export default Disciplines
