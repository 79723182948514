import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {deleteRaceCategory, getRaceCategory, updateRaceCategory} from "../../../../actions/raceCategoryActions";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { RACE_CATEGORY_UPDATE_RESET } from '../../../../constants/raceCategoryConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditRaceCategory = (props) => {
    const {id: raceCategoryId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let raceCategoryDetails = useSelector(state => state.raceCategoryDetails)
    const {loading, error, RaceCategory} = raceCategoryDetails

    let raceCategoryUpdate = useSelector(state => state.raceCategoryUpdate)
    const {error: errorUpdate, success: successUpdate} = raceCategoryUpdate

    let raceCategoryDelete = useSelector(state => state.raceCategoryDelete)
    const {success: successDelete} = raceCategoryDelete

    const [name, setName] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    const EditHandler = () => {
        let data = {
            name: name
        }
        dispatch(updateRaceCategory(data, raceCategoryId))
    }

    const deleteHandler = () => {
        dispatch(deleteRaceCategory(raceCategoryId))
    }

    useEffect(() => {
        if (successDelete) {
            history('/race-category')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getRaceCategory(raceCategoryId))
            dispatch({type: RACE_CATEGORY_UPDATE_RESET})
            setTimeout(() => {
                history('/race-category')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (raceCategoryId !== RaceCategory[0]?.id.toString()) {
            dispatch(getRaceCategory(raceCategoryId))
        } else {
            setName(RaceCategory[0].name)
        }
    }, [dispatch, history, raceCategoryId, RaceCategory, successUpdate, errorUpdate, successDelete])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    La catégorie a bien été modifiée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Race Category</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditRaceCategory
