import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import MuiAlert from "@mui/material/Alert";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {listCircuits} from "../../../../actions/circuitsActions";
import {listDisciplines} from "../../../../actions/disciplinesActions";
import {listContactCommercial} from "../../../../actions/contactCommercialActions";
import {createOrganisations} from "../../../../actions/organisationsActions";
import Snackbar from "@mui/material/Snackbar";
import '../../../../assets/css/newBackOfficeBdd.css';
import '../../../../assets/css/style.css'
import {listSegments} from "../../../../actions/segmentsActions";
import {listPilots} from "../../../../actions/pilotsActions";
import axios from "../../../../axios";
import DepartementRegion from "../../../../ressources/departements-region.json"
import Activite from '../../../../components/organizations/Activite';
import Adresses from '../../../../components/organizations/Adresses';
import Documents from '../../../../components/organizations/Documents';
import OrgaCoordonnees from '../../../../components/organizations/OrgaCoordonnees';
import {ORGANISATIONS_CREATE_RESET} from '../../../../constants/organisationsConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NouvelleOrganisation = (props) => {
    const [tabs, setTabs] = useState(1)

    let history = useNavigate()
    const dispatch = useDispatch()

    let contactCommercialList = useSelector(state => state.contactCommercialList)
    const {contactCommercial} = contactCommercialList

    let organisationsCreate = useSelector(state => state.organisationsCreate)
    const {success: successCreate, error: errorCreate} = organisationsCreate

    let pilotsList = useSelector(state => state.pilotsList)
    const {pilots} = pilotsList

    let circuitsList = useSelector(state => state.circuitsList)
    const {circuits} = circuitsList

    let segmentsList = useSelector(state => state.segmentsList)
    const {Segments} = segmentsList

    // pilot list not added

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formContactValues, setFormContactValues] = useState([{contactCommercialId: ""}])
    const [formCircuitValues, setFormCircuitValues] = useState([{circuitId: ""}])
    const [formSegmentValues, setFormSegmentValues] = useState([{segmentId: ""}])

    let handleContactChange = (i, e) => {
        let newFormValues = [...formContactValues];
        newFormValues[i]["contactCommercialId"] = e.target.value;
        setFormContactValues(newFormValues);
    }

    let handleCircuitChange = (i, e) => {
        let newFormValues = [...formCircuitValues];
        newFormValues[i]["circuitId"] = e.target.value;
        setFormCircuitValues(newFormValues);
    }

    let handleSegmentChange = (i, e) => {
        let newFormValues = [...formSegmentValues];
        newFormValues[i]["segmentId"] = e.target.value;
        setFormSegmentValues(newFormValues);
    }

    let addContactFormFields = () => {
        setFormContactValues([...formContactValues, {contactCommercialId: ""}])
    }

    let addCircuitFormFields = () => {
        setFormCircuitValues([...formCircuitValues, {circuitId: ""}])
    }

    let addSegmentFormFields = () => {
        setFormSegmentValues([...formSegmentValues, {segmentId: ""}])
    }

    let removeContactFormFields = (i) => {
        let newFormValues = [...formContactValues];
        newFormValues.splice(i, 1);
        setFormContactValues(newFormValues)
    }

    let removeCircuitFormFields = (i) => {
        let newFormValues = [...formCircuitValues];
        newFormValues.splice(i, 1);
        setFormCircuitValues(newFormValues)
    }

    let removeSegmentFormFields = (i) => {
        let newFormValues = [...formSegmentValues];
        newFormValues.splice(i, 1);
        setFormSegmentValues(newFormValues)
    }

    const [orgaAdress, setOrgaAdress] = useState("")
    const [orgaComplement, setOrgaComplement] = useState("")
    const [orgaCode, setOrgaCode] = useState("")
    const [orgaVille, setOrgaVille] = useState("")
    const [orgaPays, setOrgaPays] = useState("")
    const [orgaAdressLiv, setOrgaAdressLiv] = useState("")
    const [orgaComplementLiv, setOrgaComplementLiv] = useState("")
    const [orgaCodeLiv, setOrgaCodeLiv] = useState("")
    const [orgaVilleLiv, setOrgaVilleLiv] = useState("")
    const [orgaPaysLiv, setOrgaPaysLiv] = useState("")

    const statusArray = ["Association", "Entreprise", "Auto-entrepreneur"]
    const compteArray = ["Non créé", "En cours", "Certifié"]
    const typeArray = ["Club / Team", "Ecole de pilotage", "Garage / Préparateur / Concession",
        "Constructeur / Equipementier", "Promoteur (Evènemet / Compétition)", "Média",
        "Fédération", "Assurance / Administration", "Shop / Ditributeur / Magasin", "Autre"]

    const [orgaName, setOrgaName] = useState("")
    const [orgaStatus, setOrgaStatus] = useState(statusArray[0])
    const [orgaCompte, setOrgaCompte] = useState(compteArray[0])
    const [orgaMail, setOrgaMail] = useState("")
    const [orgaPhone, setOrgaPhone] = useState("")
    const [orgaDesc, setOrgaDesc] = useState("")
    const [orgaType, setOrgaType] = useState(typeArray[0])
    const [orgaUser, setOrgaUser] = useState("")

    const [orgaRIB, setOrgaRIB] = useState("")
    const [orgaKBIS, setOrgaKBIS] = useState("")
    const [orgaRC, setOrgaRC] = useState("")
    const [orgaSigned, setOrgaSigned] = useState("")
    const [orgaURSAFF, setOrgaURSAFF] = useState("")
    const [orgaCNIrecto, setOrgaCNIRecto] = useState("")
    const [orgaCNIverso, setOrgaCNIVerso] = useState("")

    const [facebook, setFacebook] = useState("")
    const [instagram, setInstagram] = useState("")
    const [linkedin, setLinkedin] = useState("")

    const handleClose = () => {
        setOpen(false)
    }

    const saveFile = (e, type) => {
        switch (type) {
            case "RIB":
                setOrgaRIB(e.target.files[0]);
                break
            case "KBIS":
                setOrgaKBIS(e.target.files[0]);
                break
            case "RC":
                setOrgaRC(e.target.files[0]);
                break
            case "STATUS":
                setOrgaSigned(e.target.files[0]);
                break
            case "URSAFF":
                setOrgaURSAFF(e.target.files[0]);
                break
            case "CNIRECTO":
                setOrgaCNIRecto(e.target.files[0]);
                break
            case "CNIVERSO":
                setOrgaCNIVerso(e.target.files[0]);
                break
            default:
                break
        }

    };

    const uploadFile = async () => {
        const formDataRIB = new FormData();
        const formDataKBIS = new FormData();
        const formDataRC = new FormData();
        const formDataSTATUS = new FormData();
        const formDataURSAFF = new FormData();
        const formDataCNIR = new FormData();
        const formDataCNIV = new FormData();

        let publicIdRIB = ""
        let publicIdKBIS = ""
        let publicIdRC = ""
        let publicIdStatus = ""
        let publicIdUrsaff = ""
        let publicIdCNIR = ""
        let publicIdCNIV = ""

        try {
            if (orgaRIB) {
                formDataRIB.append("file", orgaRIB);
                formDataRIB.append("folder", `organisations/${orgaName}`);
                formDataRIB.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataRIB)
                publicIdRIB = res.data.public_id
            }

            if (orgaKBIS) {
                formDataKBIS.append("file", orgaKBIS);
                formDataKBIS.append("folder", `organisations/${orgaName}`);
                formDataKBIS.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataKBIS)
                publicIdKBIS = res2.data.public_id
            }

            if (orgaRC) {
                formDataRC.append("file", orgaRC);
                formDataRC.append("folder", `organisations/${orgaName}`);
                formDataRC.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataRC)
                publicIdRC = res2.data.public_id
            }

            if (orgaSigned) {
                formDataSTATUS.append("file", orgaSigned);
                formDataSTATUS.append("folder", `organisations/${orgaName}`);
                formDataSTATUS.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataSTATUS)
                publicIdStatus = res2.data.public_id
            }

            if (orgaURSAFF) {
                formDataURSAFF.append("file", orgaURSAFF);
                formDataURSAFF.append("folder", `organisations/${orgaName}`);
                formDataURSAFF.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataURSAFF)
                publicIdUrsaff = res2.data.public_id
            }

            if (orgaCNIrecto) {
                formDataCNIR.append("file", orgaCNIrecto);
                formDataCNIR.append("folder", `organisations/${orgaName}`);
                formDataCNIR.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataCNIR)
                publicIdCNIR = res2.data.public_id
            }

            if (orgaCNIverso) {
                formDataCNIV.append("file", orgaCNIverso);
                formDataCNIV.append("folder", `organisations/${orgaName}`);
                formDataCNIV.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/auto/upload', formDataCNIV)
                publicIdCNIV = res2.data.public_id
            }

            return [publicIdRIB, publicIdKBIS, publicIdRC, publicIdStatus, publicIdUrsaff, publicIdCNIR, publicIdCNIV]
        } catch (ex) {
            console.log(ex);
        }

    };

    const getDepartmentRegion = (postalCode) => {
        let departement = ""
        let region = ""

        {
            DepartementRegion.forEach(c => {
                if (postalCode) {
                    let twoNumbers = ""
                    twoNumbers = postalCode[0] + postalCode[1]
                    if (parseInt(twoNumbers) === c.num_dep || twoNumbers === c.num_dep) {
                        departement = c.dep_name
                        region = c.region_name
                    }
                }
            })
        }

        return (departement, region)
    }

    const AddHandler = async () => {
        let depRegBillingData = getDepartmentRegion(orgaCode)
        let orgaBillingDepartment = depRegBillingData[0]
        let orgaBillingRegion = depRegBillingData[1]

        let depRegdelivData = getDepartmentRegion(orgaCodeLiv)
        let orgaDeliverDepartment = depRegdelivData[0]
        let orgaDeliverRegion = depRegdelivData[1]

        uploadFile().then(res => {
            let data = {

                //Address
                billingAddress: orgaAdress,
                billingAddress2: orgaComplement,
                billingZipCode: orgaCode,
                billingCity: orgaVille,
                billingCountry: orgaPays,
                billingDepartment: orgaBillingDepartment,
                billingRegion: orgaBillingRegion,
                deliverAddress: orgaAdressLiv,
                deliverAddress2: orgaComplementLiv,
                deliverZipCode: orgaCodeLiv,
                deliverCity: orgaVilleLiv,
                deliverCountry: orgaPaysLiv,
                deliverDepartment: orgaDeliverDepartment,
                deliverRegion: orgaDeliverRegion,

                //Organizations
                name: orgaName,
                status: orgaStatus,
                certificationCompte: orgaCompte,
                mail: orgaMail,
                phone: orgaPhone,
                description: orgaDesc,
                type: orgaType,

                //Organization_ContactCommercial
                contactIds: formContactValues,

                //Organizations_Pilots
                users: orgaUser,

                //LegalsDocuments
                rib: res[0],
                kbis: res[1],
                rc: res[2],
                statusSigned: res[3],
                ursaff: res[4],
                CNIrecto: res[5],
                CNIverso: res[6],

                //Organizations_Circuits
                circuitsIds: formCircuitValues,

                //Organizations_Segments
                segmentIds: formSegmentValues,

                //Organizations_RS
                facebook: facebook,
                linkedin: linkedin,
                instagram: instagram
            }
            dispatch(createOrganisations(data))
        })
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/organisations')
                dispatch({type: ORGANISATIONS_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    useEffect(() => {
        dispatch(listSegments())
        dispatch(listCircuits())
    }, [dispatch])

    useEffect(() => {
        dispatch(listDisciplines())
        dispatch(listPilots())
    }, [dispatch])

    useEffect(() => {
        dispatch(listContactCommercial())
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'organisation a bien été ajoutée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Organisations"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Organisation</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className={tabs === 1 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(1)}>
                    <AccountCircleIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Coordonnées</p>
                </button>
                <button className={tabs === 2 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(2)}>
                    <BuildIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Activité</p>
                </button>
                <button className={tabs === 3 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(3)}>
                    <RoomIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Adresses</p>
                </button>
                <button className={tabs === 4 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(4)}>
                    <HomeIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Documents juridiques</p>
                </button>

            </div>
            <div className="circuitMenus">
                {tabs === 3 ? <Adresses
                    orgaAdress={orgaAdress} setOrgaAdress={setOrgaAdress}
                    orgaComplement={orgaComplement} setOrgaComplement={setOrgaComplement}
                    orgaCode={orgaCode} setOrgaCode={setOrgaCode}
                    orgaVille={orgaVille} setOrgaVille={setOrgaVille}
                    orgaPays={orgaPays} setOrgaPays={setOrgaPays}
                    orgaAdressLiv={orgaAdressLiv} setOrgaAdressLiv={setOrgaAdressLiv}
                    orgaComplementLiv={orgaComplementLiv}
                    setOrgaComplementLiv={setOrgaComplementLiv}
                    orgaCodeLiv={orgaCodeLiv} setOrgaCodeLiv={setOrgaCodeLiv}
                    orgaVilleLiv={orgaVilleLiv} setOrgaVilleLiv={setOrgaVilleLiv}
                    orgaPaysLiv={orgaPaysLiv} setOrgaPaysLiv={setOrgaPaysLiv}
                /> : null}
                {tabs === 1 ? <OrgaCoordonnees
                    orgaName={orgaName} setOrgaName={setOrgaName}
                    orgaStatus={orgaStatus} setOrgaStatus={setOrgaStatus}
                    orgaCompte={orgaCompte} setOrgaCompte={setOrgaCompte}
                    orgaMail={orgaMail} setOrgaMail={setOrgaMail}
                    orgaPhone={orgaPhone} setOrgaPhone={setOrgaPhone}
                    orgaDesc={orgaDesc} setOrgaDesc={setOrgaDesc}
                    orgaType={orgaType} setOrgaType={setOrgaType}
                    facebook={facebook} instagram={instagram} linkedin={linkedin}
                    setFacebook={setFacebook} setInstagram={setInstagram} setLinkedin={setLinkedin}
                    pilots={pilots}
                    orgaUser={orgaUser} setOrgaUser={setOrgaUser}
                    Contact_Commercial={contactCommercial} handleContactChange={handleContactChange}
                    formContactValues={formContactValues}
                    addContactFormFields={addContactFormFields}
                    removeContactFormFields={removeContactFormFields}
                /> : null}
                {tabs === 4 ? <Documents
                    orgaRIB={orgaRIB} setOrgaRIB={setOrgaRIB}
                    orgaKBIS={orgaKBIS} setOrgaKBIS={setOrgaKBIS}
                    orgaRC={orgaRC} setOrgaRC={setOrgaRC}
                    orgaSigned={orgaSigned} setOrgaSigned={setOrgaSigned}
                    orgaURSAFF={orgaURSAFF} setOrgaURSAFF={setOrgaURSAFF}
                    orgaCNIrecto={orgaCNIrecto} setOrgaCNIRecto={setOrgaCNIRecto}
                    orgaCNIverso={orgaCNIverso} setOrgaCNIVerso={setOrgaCNIVerso}
                    saveFile={saveFile}
                /> : null}
                {tabs === 2 ? <Activite
                    circuits={circuits} Segments={Segments}
                    formCircuitValues={formCircuitValues} formSegmentValues={formSegmentValues}
                    handleCircuitChange={handleCircuitChange}
                    handleSegmentChange={handleSegmentChange}
                    addCircuitFormFields={addCircuitFormFields}
                    addSegmentFormFields={addSegmentFormFields}
                    removeCircuitFormFields={removeCircuitFormFields}
                    removeSegmentFormFields={removeSegmentFormFields}
                /> : null}
            </div>
        </div>
    )
}

export default NouvelleOrganisation
