import axios from "../axios"

import {
    SEGMENTS_CREATE_FAIL,
    SEGMENTS_CREATE_REQUEST,
    SEGMENTS_CREATE_SUCCESS,
    SEGMENTS_DELETE_FAIL,
    SEGMENTS_DELETE_REQUEST,
    SEGMENTS_DELETE_SUCCESS,
    SEGMENTS_DETAILS_FAIL,
    SEGMENTS_DETAILS_REQUEST,
    SEGMENTS_DETAILS_SUCCESS,
    SEGMENTS_LIST_FAIL,
    SEGMENTS_LIST_REQUEST,
    SEGMENTS_LIST_SUCCESS,
    SEGMENTS_UPDATE_FAIL,
    SEGMENTS_UPDATE_REQUEST,
    SEGMENTS_UPDATE_SUCCESS
} from "../constants/segmentsConstants";

export const listSegments = () => async (dispatch) => {
    try {
        dispatch({type: SEGMENTS_LIST_REQUEST})
        const {data} = await axios.get('/api/segments')
        dispatch({
            type: SEGMENTS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SEGMENTS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getSegments = (id) => async (dispatch) => {
    try {
        dispatch({type: SEGMENTS_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/segments/${id}`)
        dispatch({
            type: SEGMENTS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: SEGMENTS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteSegments = (id) => async (dispatch) => {
    try {
        dispatch({
            type: SEGMENTS_DELETE_REQUEST,
        })
        await axios.delete(`/api/segments/${id}`)

        dispatch({
            type: SEGMENTS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: SEGMENTS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createSegments = (createdSegments) => async (dispatch) => {
    try {
        dispatch({
            type: SEGMENTS_CREATE_REQUEST,
        })
        await axios.post(`/api/segments`, createdSegments)

        dispatch({
            type: SEGMENTS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: SEGMENTS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateSegments = (updatedSegments, id) => async (dispatch) => {
    try {
        dispatch({
            type: SEGMENTS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/api/segments/${id}`, updatedSegments)

        dispatch({
            type: SEGMENTS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: SEGMENTS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
