import {createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: "#CEFD34",
        }
    },
    overrides: {
        MuiSvgIcon: {
            fontSizeMedium: {
                color: "white",
            }
        }
    }
});

export default theme