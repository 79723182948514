import axios from "../axios"

import {
    CIRCUITS_CREATE_FAIL,
    CIRCUITS_CREATE_REQUEST, CIRCUITS_CREATE_SUCCESS,
    CIRCUITS_DELETE_FAIL,
    CIRCUITS_DELETE_REQUEST,
    CIRCUITS_DELETE_SUCCESS,
    CIRCUITS_DETAILS_FAIL,
    CIRCUITS_DETAILS_REQUEST,
    CIRCUITS_DETAILS_SUCCESS,
    CIRCUITS_LIST_FAIL,
    CIRCUITS_LIST_REQUEST,
    CIRCUITS_LIST_SUCCESS,
    CIRCUITS_UPDATE_FAIL,
    CIRCUITS_UPDATE_REQUEST,
    CIRCUITS_UPDATE_SUCCESS
} from "../constants/circuitsConstants";

export const listCircuits = () => async (dispatch) => {
    try {
        dispatch({type: CIRCUITS_LIST_REQUEST})
        const {data} = await axios.get('/circuits')
        dispatch({
            type: CIRCUITS_LIST_SUCCESS,
            payload: data.allCircuits,
        })
    } catch (error) {
        dispatch({
            type: CIRCUITS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getCircuits = (id) => async (dispatch) => {
    try {
        dispatch({type: CIRCUITS_DETAILS_REQUEST})
        const {data} = await axios.get(`/circuits/${id}`)
        dispatch({
            type: CIRCUITS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: CIRCUITS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteCircuits = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CIRCUITS_DELETE_REQUEST,
        })
        await axios.delete(`/circuits/${id}`)

        dispatch({
            type: CIRCUITS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CIRCUITS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createCircuits = (createdCircuits) => async (dispatch) => {
    try {
        dispatch({
            type: CIRCUITS_CREATE_REQUEST,
        })
        await axios.post(`/circuits`, createdCircuits)

        dispatch({
            type: CIRCUITS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CIRCUITS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateCircuits = (updatedCircuits, id) => async (dispatch) => {
    try {
        dispatch({
            type: CIRCUITS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/circuits/${id}`, updatedCircuits)

        dispatch({
            type: CIRCUITS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: CIRCUITS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
