import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import MuiAlert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {InfoRounded} from "@material-ui/icons";
import {createFacilities} from "../../../../actions/facilitiesActions";
import Snackbar from "@mui/material/Snackbar";
import '../../../../assets/css/newBackOfficeBdd.css'
import {useNavigate} from "react-router-dom";
import axios from "../../../../axios";
import {FACILITIES_CREATE_RESET} from '../../../../constants/facilitiesConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewFacilities = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let facilitiesCreate = useSelector(state => state.facilitiesCreate)
    const {success: successCreate, error: errorCreate} = facilitiesCreate

    const [facilitiesName, setFacilitiesName] = useState("")
    const [facilitiesPicture, setFacilitiesPicture] = useState()
    const [facilitiesCategory, setFacilitiesCategory] = useState("")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const saveFile = (e) => {
        setFacilitiesPicture(e.target.files[0]);
    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        let publicIdPP = ""

        try {
            if (facilitiesPicture) {
                formDataProfile.append("file", facilitiesPicture);
                formDataProfile.append("folder", "facilities");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdPP = res.data.public_id
            }

            return [publicIdPP]
        } catch (ex) {
            console.log(ex);
        }

    };

    const AddHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: facilitiesName,
                icon: res[0],
                category: facilitiesCategory,
            }
            dispatch(createFacilities(data))
        })

    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/facilities')
                dispatch({type: FACILITIES_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'équipement a bien été ajouté !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Facilities"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Facilities</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={facilitiesName}
                                   onChange={e => setFacilitiesName(e.target.value)}
                            />
                            <p className="label-2">Image de profil</p>
                            <input className="input" placeholder="Photo"
                                   type="file"
                                   onChange={e => saveFile(e)}
                            />
                            <p className="label-2">Catégorie</p>
                            <select className="state" value={facilitiesCategory}
                                    onChange={e => setFacilitiesCategory(e.target.value)}>
                                <option className="sub" value="">-- Choisissez une catégorie --
                                </option>
                                <option value="Confort">Confort</option>
                                <option value="Ambiance">Ambiance</option>
                                <option value="Restauration">Restauration</option>
                                <option value="Parking">Parking</option>
                                <option value="Course">Course</option>
                                <option value="Sécurité">Sécurité</option>
                                <option value="Entretien">Entretien</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewFacilities
