import {
    CONTACT_COMMERCIAL_CREATE_FAIL,
    CONTACT_COMMERCIAL_CREATE_REQUEST,
    CONTACT_COMMERCIAL_CREATE_SUCCESS,
    CONTACT_COMMERCIAL_CREATE_RESET,
    CONTACT_COMMERCIAL_DELETE_FAIL,
    CONTACT_COMMERCIAL_DELETE_REQUEST,
    CONTACT_COMMERCIAL_DELETE_SUCCESS,
    CONTACT_COMMERCIAL_DETAILS_FAIL,
    CONTACT_COMMERCIAL_DETAILS_REQUEST,
    CONTACT_COMMERCIAL_DETAILS_SUCCESS,
    CONTACT_COMMERCIAL_LIST_FAIL,
    CONTACT_COMMERCIAL_LIST_REQUEST,
    CONTACT_COMMERCIAL_LIST_SUCCESS,
    CONTACT_COMMERCIAL_UPDATE_FAIL,
    CONTACT_COMMERCIAL_UPDATE_REQUEST,
    CONTACT_COMMERCIAL_UPDATE_SUCCESS,
    CONTACT_COMMERCIAL_UPDATE_RESET
} from "../constants/contactCommercialConstants";

export const contactCommercialListReducers = (state = {contactCommercial: []}, action) => {
    switch (action.type) {
        case CONTACT_COMMERCIAL_LIST_REQUEST:
            return {loading: true, contactCommercial: []}
        case CONTACT_COMMERCIAL_LIST_SUCCESS:
            return {loading: false, contactCommercial: action.payload}
        case CONTACT_COMMERCIAL_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const contactCommercialDetailsReducers = (state = {contactCommercial: {}}, action) => {
    switch (action.type) {
        case CONTACT_COMMERCIAL_DETAILS_REQUEST:
            return {loading: true, ...state}
        case CONTACT_COMMERCIAL_DETAILS_SUCCESS:
            return {loading: false, contactCommercial: action.payload}
        case CONTACT_COMMERCIAL_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const contactCommercialCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_COMMERCIAL_CREATE_REQUEST:
            return {loading: true}
        case CONTACT_COMMERCIAL_CREATE_SUCCESS:
            return {loading: false, success: true}
        case CONTACT_COMMERCIAL_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case CONTACT_COMMERCIAL_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const contactCommercialDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case CONTACT_COMMERCIAL_DELETE_REQUEST:
            return {loading: true}
        case CONTACT_COMMERCIAL_DELETE_SUCCESS:
            return {loading: false, success: true}
        case CONTACT_COMMERCIAL_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const contactCommercialUpdateReducers = (state = {contactCommercial: {}}, action) => {
    switch (action.type) {
        case CONTACT_COMMERCIAL_UPDATE_REQUEST:
            return {loading: true}
        case CONTACT_COMMERCIAL_UPDATE_SUCCESS:
            return {loading: false, success: true, contactCommercial: action.payload}
        case CONTACT_COMMERCIAL_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case CONTACT_COMMERCIAL_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
