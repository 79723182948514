import {
    OFFERS_CREATE_FAIL,
    OFFERS_CREATE_REQUEST,
    OFFERS_CREATE_SUCCESS,
    OFFERS_CREATE_RESET,
    OFFERS_DELETE_FAIL,
    OFFERS_DELETE_REQUEST,
    OFFERS_DELETE_SUCCESS,
    OFFERS_DETAILS_FAIL,
    OFFERS_DETAILS_REQUEST,
    OFFERS_DETAILS_SUCCESS,
    OFFERS_LIST_FAIL,
    OFFERS_LIST_REQUEST,
    OFFERS_LIST_SUCCESS,
    OFFERS_UPDATE_FAIL,
    OFFERS_UPDATE_REQUEST,
    OFFERS_UPDATE_SUCCESS,
    OFFERS_UPDATE_RESET
} from "../constants/offersConstants";

export const offersListReducers = (state = {offers: []}, action) => {
    switch (action.type) {
        case OFFERS_LIST_REQUEST:
            return {loading: true, offers: []}
        case OFFERS_LIST_SUCCESS:
            return {loading: false, offers: action.payload}
        case OFFERS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const offersDetailsReducers = (state = {offers: {}}, action) => {
    switch (action.type) {
        case OFFERS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case OFFERS_DETAILS_SUCCESS:
            return {loading: false, offers: action.payload}
        case OFFERS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const offersCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case OFFERS_CREATE_REQUEST:
            return {loading: true}
        case OFFERS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case OFFERS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case OFFERS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const offersDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case OFFERS_DELETE_REQUEST:
            return {loading: true}
        case OFFERS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case OFFERS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const offersUpdateReducers = (state = {offers: {}}, action) => {
    switch (action.type) {
        case OFFERS_UPDATE_REQUEST:
            return {loading: true}
        case OFFERS_UPDATE_SUCCESS:
            return {loading: false, success: true, offers: action.payload}
        case OFFERS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case OFFERS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
