import React from 'react'
import ContactsIcon from '@material-ui/icons/Contacts';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Activite = (props) => {

    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Dirige le</p>
                    <p className="label">Circuits</p>
                    {props.formCircuitValues.map((element, index) => (
                        <div className="name" key={`${element}_${index}`}>
                            <ContactsIcon className="name-icon"/>
                            <select className="state" value={element.circuitId}
                                    onChange={e => props.handleCircuitChange(index, e)}>
                                <option className="sub" value="">-- Choisissez un Circuit --</option>
                                {props.circuits.map(circuit => {
                                    return <option key={circuit.id} value={circuit.id}>{circuit.name}</option>
                                })}
                            </select>
                            {
                                index ?
                                    <button type="button" className="button-remove"
                                            onClick={() => props.removeCircuitFormFields(index)}>Remove</button>
                                    : null
                            }
                        </div>
                    ))}
                    <button className="button-add" type="button" onClick={() => props.addCircuitFormFields()}>Add
                    </button>
                </div>
                <div className="status">
                    <p className="first-subTitles">Expertise / Domaine d'activité</p>
                    <p className="label">Segments</p>
                    {props.formSegmentValues.map((element, index) => (
                        <div className="name" key={`${element}_${index}`}>
                            <ContactsIcon className="name-icon"/>
                            <select className="state" value={element.segmentId}
                                    onChange={e => props.handleSegmentChange(index, e)}>
                                <option className="sub" value="">-- Choisissez votre segment --</option>
                                {props.Segments.map(segment => {
                                    return <option key={segment.id} value={segment.id}>{segment.name}</option>
                                })}
                            </select>
                            {
                                index ?
                                    <button type="button" className="button-remove"
                                            onClick={() => props.removeSegmentFormFields(index)}>Remove</button>
                                    : null
                            }
                        </div>
                    ))}
                    <button className="button-add" type="button" onClick={() => props.addSegmentFormFields()}>Add
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Activite