import axios from "../axios"

import {
    CONTACT_COMMERCIAL_CREATE_FAIL,
    CONTACT_COMMERCIAL_CREATE_REQUEST, CONTACT_COMMERCIAL_CREATE_SUCCESS,
    CONTACT_COMMERCIAL_DELETE_FAIL,
    CONTACT_COMMERCIAL_DELETE_REQUEST,
    CONTACT_COMMERCIAL_DELETE_SUCCESS,
    CONTACT_COMMERCIAL_DETAILS_FAIL,
    CONTACT_COMMERCIAL_DETAILS_REQUEST,
    CONTACT_COMMERCIAL_DETAILS_SUCCESS,
    CONTACT_COMMERCIAL_LIST_FAIL,
    CONTACT_COMMERCIAL_LIST_REQUEST,
    CONTACT_COMMERCIAL_LIST_SUCCESS,
    CONTACT_COMMERCIAL_UPDATE_FAIL,
    CONTACT_COMMERCIAL_UPDATE_REQUEST,
    CONTACT_COMMERCIAL_UPDATE_SUCCESS
} from "../constants/contactCommercialConstants";

export const listContactCommercial = () => async (dispatch) => {
    try {
        dispatch({type: CONTACT_COMMERCIAL_LIST_REQUEST})
        const {data} = await axios.get('/contactCommercial')
        dispatch({
            type: CONTACT_COMMERCIAL_LIST_SUCCESS,
            payload: data.Contact_Commercial,
        })
    } catch (error) {
        dispatch({
            type: CONTACT_COMMERCIAL_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getContactCommercial = (id) => async (dispatch) => {
    try {
        dispatch({type: CONTACT_COMMERCIAL_DETAILS_REQUEST})
        const {data} = await axios.get(`/contactCommercial/${id}`)
        dispatch({
            type: CONTACT_COMMERCIAL_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: CONTACT_COMMERCIAL_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteContactCommercial = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CONTACT_COMMERCIAL_DELETE_REQUEST,
        })
        await axios.delete(`/contactCommercial/${id}`)

        dispatch({
            type: CONTACT_COMMERCIAL_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CONTACT_COMMERCIAL_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createContactCommercial = (createdContactCommercial) => async (dispatch) => {
    try {
        dispatch({
            type: CONTACT_COMMERCIAL_CREATE_REQUEST,
        })
        await axios.post(`/contactCommercial`, createdContactCommercial)

        dispatch({
            type: CONTACT_COMMERCIAL_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CONTACT_COMMERCIAL_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateContactCommercial = (updatedContactCommercial, id) => async (dispatch) => {
    try {
        dispatch({
            type: CONTACT_COMMERCIAL_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/contactCommercial/${id}`, updatedContactCommercial)

        dispatch({
            type: CONTACT_COMMERCIAL_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: CONTACT_COMMERCIAL_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
