import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import { listDocuments } from '../../../actions/documentsActions';
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Documents = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let documentsList = useSelector(state => state.documentsList)
    const {loading, error, documents} = documentsList

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`documents/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        },
        {
            label: "Catégorie",
            name: "category",
            options: {
                filter: true,
                sort: true,
            }
        }
    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listDocuments())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Documents</h2>
                <button className="new_button" onClick={() => history('/documents/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ? <p style={{color: "white"}}>{error}</p> : (
                    <MUIDataTable
                        data={documents}
                        columns={columns}
                        options={options}
                    />
                )}
            </div>
        </ThemeProvider>
    )
}

export default Documents
