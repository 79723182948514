export const FACILITIES_LIST_REQUEST = "FACILITIES_LIST_REQUEST"
export const FACILITIES_LIST_SUCCESS = "FACILITIES_LIST_SUCCESS"
export const FACILITIES_LIST_FAIL = "FACILITIES_LIST_FAIL"

export const FACILITIES_DETAILS_REQUEST = "FACILITIES_DETAILS_REQUEST"
export const FACILITIES_DETAILS_SUCCESS = "FACILITIES_DETAILS_SUCCESS"
export const FACILITIES_DETAILS_FAIL = "FACILITIES_DETAILS_FAIL"

export const FACILITIES_CREATE_REQUEST = "FACILITIES_CREATE_REQUEST"
export const FACILITIES_CREATE_SUCCESS = "FACILITIES_CREATE_SUCCESS"
export const FACILITIES_CREATE_FAIL = "FACILITIES_CREATE_FAIL"
export const FACILITIES_CREATE_RESET = "FACILITIES_CREATE_RESET"

export const FACILITIES_DELETE_REQUEST = "FACILITIES_DELETE_REQUEST"
export const FACILITIES_DELETE_SUCCESS = "FACILITIES_DELETE_SUCCESS"
export const FACILITIES_DELETE_FAIL = "FACILITIES_DELETE_FAIL"

export const FACILITIES_UPDATE_REQUEST = "FACILITIES_UPDATE_REQUEST"
export const FACILITIES_UPDATE_SUCCESS = "FACILITIES_UPDATE_SUCCESS"
export const FACILITIES_UPDATE_FAIL = "FACILITIES_UPDATE_FAIL"
export const FACILITIES_UPDATE_RESET = "FACILITIES_UPDATE_RESET"
