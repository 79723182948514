import {
    RACE_CATEGORY_CREATE_FAIL,
    RACE_CATEGORY_CREATE_REQUEST,
    RACE_CATEGORY_CREATE_SUCCESS,
    RACE_CATEGORY_CREATE_RESET,
    RACE_CATEGORY_DELETE_FAIL,
    RACE_CATEGORY_DELETE_REQUEST,
    RACE_CATEGORY_DELETE_SUCCESS,
    RACE_CATEGORY_DETAILS_FAIL,
    RACE_CATEGORY_DETAILS_REQUEST,
    RACE_CATEGORY_DETAILS_SUCCESS,
    RACE_CATEGORY_LIST_FAIL,
    RACE_CATEGORY_LIST_REQUEST,
    RACE_CATEGORY_LIST_SUCCESS,
    RACE_CATEGORY_UPDATE_FAIL,
    RACE_CATEGORY_UPDATE_REQUEST,
    RACE_CATEGORY_UPDATE_SUCCESS,
    RACE_CATEGORY_UPDATE_RESET
} from "../constants/raceCategoryConstants";

export const raceCategoryListReducers = (state = {RaceCategory: []}, action) => {
    switch (action.type) {
        case RACE_CATEGORY_LIST_REQUEST:
            return {loading: true, RaceCategory: []}
        case RACE_CATEGORY_LIST_SUCCESS:
            return {loading: false, RaceCategory: action.payload}
        case RACE_CATEGORY_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const raceCategoryDetailsReducers = (state = {RaceCategory: {}}, action) => {
    switch (action.type) {
        case RACE_CATEGORY_DETAILS_REQUEST:
            return {loading: true, ...state}
        case RACE_CATEGORY_DETAILS_SUCCESS:
            return {loading: false, RaceCategory: action.payload}
        case RACE_CATEGORY_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const raceCategoryCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case RACE_CATEGORY_CREATE_REQUEST:
            return {loading: true}
        case RACE_CATEGORY_CREATE_SUCCESS:
            return {loading: false, success: true}
        case RACE_CATEGORY_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case RACE_CATEGORY_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const raceCategoryDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case RACE_CATEGORY_DELETE_REQUEST:
            return {loading: true}
        case RACE_CATEGORY_DELETE_SUCCESS:
            return {loading: false, success: true}
        case RACE_CATEGORY_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const raceCategoryUpdateReducers = (state = {RaceCategory: {}}, action) => {
    switch (action.type) {
        case RACE_CATEGORY_UPDATE_REQUEST:
            return {loading: true}
        case RACE_CATEGORY_UPDATE_SUCCESS:
            return {loading: false, success: true, RaceCategory: action.payload}
        case RACE_CATEGORY_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case RACE_CATEGORY_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
