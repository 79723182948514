import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listChampionnats} from "../../../actions/championnatsActions";
import {listOrganisations} from "../../../actions/organisationsActions";
import {listDisciplines} from "../../../actions/disciplinesActions";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Championnats = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let championnatsList = useSelector(state => state.championnatsList)
    const {loading, error, Championnats} = championnatsList

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let disciplinesList = useSelector(state => state.disciplinesList)
    const {Disciplines} = disciplinesList


    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`championnats/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        }, {
            label: "Photos Profile",
            name: "profile_picture",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img alt={"AccelerioPPChampionship"}
                        src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${val}`}/>)
                },
            }
        },
        {
            label: "Organisateur",
            name: "organization_id",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        return organisations.map((o) => {
                            if (o.id === val) {
                                return (<a className="link"
                                           href={`organisations/edit/${val}`}>{o.name}</a>)
                            }
                            return null
                        })
                    } else {
                        return null
                    }
                },
            }
        },
        {
            label: "Disciplines",
            name: "disciplines_id",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        return Disciplines.map((d) => {
                            if (d.id === val) {
                                return (<a className="link"
                                           href={`disciplines/edit/${val}`}>{d.name}</a>)
                            }
                            return null
                        })
                    } else {
                        return null
                    }

                },
            }
        },
        {
            label: "Fédérations",
            name: "federation_id",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        return organisations.map((o) => {
                            if (o.id === val) {
                                return (<a className="link"
                                           href={`organisations/edit/${val}`}>{o.name}</a>)
                            }
                            return null
                        })
                    } else {
                        return null
                    }
                },
            }
        },
    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listChampionnats())
        dispatch(listOrganisations())
        dispatch(listDisciplines())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>

            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Championnats</h2>
                <button className="new_button"
                        onClick={() => history('/championnats/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={Championnats}
                            columns={columns}
                            options={options}
                        />
                    )}
            </div>
        </ThemeProvider>
    )
}

export default Championnats
