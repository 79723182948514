import {
    DOCUMENTS_CREATE_FAIL,
    DOCUMENTS_CREATE_REQUEST,
    DOCUMENTS_CREATE_RESET,
    DOCUMENTS_CREATE_SUCCESS,
    DOCUMENTS_DELETE_FAIL,
    DOCUMENTS_DELETE_REQUEST,
    DOCUMENTS_DELETE_SUCCESS,
    DOCUMENTS_DETAILS_FAIL,
    DOCUMENTS_DETAILS_REQUEST,
    DOCUMENTS_DETAILS_SUCCESS,
    DOCUMENTS_LIST_FAIL,
    DOCUMENTS_LIST_REQUEST,
    DOCUMENTS_LIST_SUCCESS,
    DOCUMENTS_UPDATE_FAIL,
    DOCUMENTS_UPDATE_REQUEST,
    DOCUMENTS_UPDATE_RESET,
    DOCUMENTS_UPDATE_SUCCESS
} from "../constants/documentsConstants";

export const documentsListReducers = (state = {documents: []}, action) => {
    switch (action.type) {
        case DOCUMENTS_LIST_REQUEST:
            return {loading: true, documents: []}
        case DOCUMENTS_LIST_SUCCESS:
            return {loading: false, documents: action.payload}
        case DOCUMENTS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const documentsDetailsReducers = (state = {documents: {}}, action) => {
    switch (action.type) {
        case DOCUMENTS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case DOCUMENTS_DETAILS_SUCCESS:
            return {loading: false, documents: action.payload}
        case DOCUMENTS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const documentsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENTS_CREATE_REQUEST:
            return {loading: true}
        case DOCUMENTS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case DOCUMENTS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DOCUMENTS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const documentsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case DOCUMENTS_DELETE_REQUEST:
            return {loading: true}
        case DOCUMENTS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DOCUMENTS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const documentsUpdateReducers = (state = {documents: {}}, action) => {
    switch (action.type) {
        case DOCUMENTS_UPDATE_REQUEST:
            return {loading: true}
        case DOCUMENTS_UPDATE_SUCCESS:
            return {loading: false, success: true, documents: action.payload}
        case DOCUMENTS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case DOCUMENTS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
