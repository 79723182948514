import React from 'react'
import '../assets/css/profileButton.css'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';

const ProfileButton = () => {
    return (
        <button className="profile_button">
            <PersonRoundedIcon className="profile_icon"/>Jefferson Lellouche</button>
    )
}

export default ProfileButton
