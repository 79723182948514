import axios from 'axios'

const instance = axios.create({
    //baseURL: 'https://localhost:8443',
    //baseURL: 'http://localhost:3001',
    //baseURL: 'https://accelerio.herokuapp.com',
    baseURL: "https://api.accelerio.com"
})

export default instance
