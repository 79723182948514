import React from 'react'

const ArrayValueUI = (props) => {
    return (
        <div>
            {props.data.map((value, index) => {
                return (<strong key={value + "_" + index} style={{
                    background: "#CEFD34",
                    marginLeft: "10px",
                    padding: "5px",
                    borderRadius: "10px",
                    color: "black"
                }}>{value} </strong>)
            })}
        </div>
    )
}

export default ArrayValueUI
