import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {listOrganisations} from "../../../../actions/organisationsActions";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {createHomologations} from "../../../../actions/homologationsActions";
import {useNavigate} from "react-router-dom";
import { HOMOLOGATIONS_CREATE_RESET } from '../../../../constants/homologationsConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewHomologations = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    const [homoloName, setHomoloName] = useState("")
    const [homoloOrganisation, setHomoloOrganisation] = useState("")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    let organisationsList = useSelector(state => state.organisationsList)
    const {loading, error, organisations} = organisationsList

    let homologationsCreate = useSelector(state => state.homologationsCreate)
    const {success: successCreate, error: errorCreate} = homologationsCreate

    const AddHandler = () => {
        let data = {
            name: homoloName,
            organization_id: homoloOrganisation
        }
        if (data.name !== "")
            dispatch(createHomologations(data))
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/homologations')
                dispatch({type: HOMOLOGATIONS_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    useEffect(() => {
        dispatch(listOrganisations())
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'homologation a bien été ajouté !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Homologations"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Homologations</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={homoloName}
                                   onChange={e => setHomoloName(e.target.value)}
                            />
                            <p className="label-2">Organisation</p>
                            <select className="state" value={homoloOrganisation}
                                    onChange={e => setHomoloOrganisation(e.target.value)}>
                                <option className="sub" value="">-- Choisissez une Organisation --</option>
                                {organisations.map(orga => {
                                    return <option key={orga.id} value={orga.id}>{orga.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewHomologations
