export const PILOTS_LIST_REQUEST = "PILOTS_LIST_REQUEST"
export const PILOTS_LIST_SUCCESS = "PILOTS_LIST_SUCCESS"
export const PILOTS_LIST_FAIL = "PILOTS_LIST_FAIL"

export const PILOTS_DETAILS_REQUEST = "PILOTS_DETAILS_REQUEST"
export const PILOTS_DETAILS_SUCCESS = "PILOTS_DETAILS_SUCCESS"
export const PILOTS_DETAILS_FAIL = "PILOTS_DETAILS_FAIL"

export const PILOTS_CREATE_REQUEST = "PILOTS_CREATE_REQUEST"
export const PILOTS_CREATE_SUCCESS = "PILOTS_CREATE_SUCCESS"
export const PILOTS_CREATE_FAIL = "PILOTS_CREATE_FAIL"
export const PILOTS_CREATE_RESET = "PILOTS_CREATE_RESET"

export const PILOTS_DELETE_REQUEST = "PILOTS_DELETE_REQUEST"
export const PILOTS_DELETE_SUCCESS = "PILOTS_DELETE_SUCCESS"
export const PILOTS_DELETE_FAIL = "PILOTS_DELETE_FAIL"

export const PILOTS_UPDATE_REQUEST = "PILOTS_UPDATE_REQUEST"
export const PILOTS_UPDATE_SUCCESS = "PILOTS_UPDATE_SUCCESS"
export const PILOTS_UPDATE_FAIL = "PILOTS_UPDATE_FAIL"
export const PILOTS_UPDATE_RESET = "PILOTS_UPDATE_RESET"
