import {
    VEHICULES_TYPES_CREATE_FAIL,
    VEHICULES_TYPES_CREATE_REQUEST,
    VEHICULES_TYPES_CREATE_SUCCESS,
    VEHICULES_TYPES_CREATE_RESET,
    VEHICULES_TYPES_DELETE_FAIL,
    VEHICULES_TYPES_DELETE_REQUEST,
    VEHICULES_TYPES_DELETE_SUCCESS,
    VEHICULES_TYPES_DETAILS_FAIL,
    VEHICULES_TYPES_DETAILS_REQUEST,
    VEHICULES_TYPES_DETAILS_SUCCESS,
    VEHICULES_TYPES_LIST_FAIL,
    VEHICULES_TYPES_LIST_REQUEST,
    VEHICULES_TYPES_LIST_SUCCESS,
    VEHICULES_TYPES_UPDATE_FAIL,
    VEHICULES_TYPES_UPDATE_REQUEST,
    VEHICULES_TYPES_UPDATE_SUCCESS,
    VEHICULES_TYPES_UPDATE_RESET
} from "../constants/vehiculeTypeConstants";

export const vehiculeTypeListReducers = (state = {vehiculeTypes: []}, action) => {
    switch (action.type) {
        case VEHICULES_TYPES_LIST_REQUEST:
            return {loading: true, vehiculeTypes: []}
        case VEHICULES_TYPES_LIST_SUCCESS:
            return {loading: false, vehiculeTypes: action.payload}
        case VEHICULES_TYPES_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const vehiculeTypeDetailsReducers = (state = {vehiculeTypes: {}}, action) => {
    switch (action.type) {
        case VEHICULES_TYPES_DETAILS_REQUEST:
            return {loading: true, ...state}
        case VEHICULES_TYPES_DETAILS_SUCCESS:
            return {loading: false, vehiculeTypes: action.payload}
        case VEHICULES_TYPES_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const vehiculeTypeCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case VEHICULES_TYPES_CREATE_REQUEST:
            return {loading: true}
        case VEHICULES_TYPES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case VEHICULES_TYPES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case VEHICULES_TYPES_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const vehiculeTypeDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case VEHICULES_TYPES_DELETE_REQUEST:
            return {loading: true}
        case VEHICULES_TYPES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case VEHICULES_TYPES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const vehiculeTypeUpdateReducers = (state = {vehiculeTypes: {}}, action) => {
    switch (action.type) {
        case VEHICULES_TYPES_UPDATE_REQUEST:
            return {loading: true}
        case VEHICULES_TYPES_UPDATE_SUCCESS:
            return {loading: false, success: true, vehiculeTypes: action.payload}
        case VEHICULES_TYPES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case VEHICULES_TYPES_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
