import axios from "../axios"

import {
    LICENCES_CREATE_FAIL,
    LICENCES_CREATE_REQUEST, 
    LICENCES_CREATE_SUCCESS,
    LICENCES_DELETE_FAIL,
    LICENCES_DELETE_REQUEST,
    LICENCES_DELETE_SUCCESS,
    LICENCES_DETAILS_FAIL,
    LICENCES_DETAILS_REQUEST,
    LICENCES_DETAILS_SUCCESS,
    LICENCES_LIST_FAIL,
    LICENCES_LIST_REQUEST,
    LICENCES_LIST_SUCCESS,
    LICENCES_UPDATE_FAIL,
    LICENCES_UPDATE_REQUEST,
    LICENCES_UPDATE_SUCCESS,
} from "../constants/licencesConstants";

export const listLicences = () => async (dispatch) => {
    try {
        dispatch({type: LICENCES_LIST_REQUEST})
        const {data} = await axios.get('/Licenses')
        dispatch({
            type: LICENCES_LIST_SUCCESS,
            payload: data.allLicenses,
        })
    } catch (error) {
        dispatch({
            type: LICENCES_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getLicences = (id) => async (dispatch) => {
    try {
        dispatch({type: LICENCES_DETAILS_REQUEST})
        const {data} = await axios.get(`/Licenses/${id}`)
        dispatch({
            type: LICENCES_DETAILS_SUCCESS,
            payload: data[0],
        })
    } catch (error) {
        dispatch({
            type: LICENCES_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteLicences = (id) => async (dispatch) => {
    try {
        dispatch({
            type: LICENCES_DELETE_REQUEST,
        })
        await axios.delete(`/Licenses/${id}`)

        dispatch({
            type: LICENCES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LICENCES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createLicences = (createdLicences) => async (dispatch) => {
    try {
        dispatch({
            type: LICENCES_CREATE_REQUEST,
        })
        await axios.post(`/Licenses`, createdLicences)

        dispatch({
            type: LICENCES_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LICENCES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateLicences = (updatedLicences, id) => async (dispatch) => {
    try {
        dispatch({
            type: LICENCES_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/Licenses/${id}`, updatedLicences)

        dispatch({
            type: LICENCES_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: LICENCES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
