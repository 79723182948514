import "./App.css";
import "./assets/css/style.css";
import {Route, Routes, useLocation} from "react-router-dom";
import Error404 from "./pages/404";
import Circuits from "./pages/admin/bdd/Circuits";
import AddCircuit from "./pages/admin/bdd/new/addCircuit";
import Organisations from "./pages/admin/bdd/Organisations";
import Sidebar from "./components/navigation/Sidebar";
import ContactCommercial from "./pages/admin/bdd/ContactCommercial";
import Bdd from "./pages/admin/bdd/Bdd";
import Disciplines from "./pages/admin/bdd/Disciplines";
import NewDisciplines from "./pages/admin/bdd/new/addDisciplines";
import Licences from "./pages/admin/bdd/Licences";
import NewLicences from "./pages/admin/bdd/new/addLicences";
import Homologations from "./pages/admin/bdd/Homologations";
import NewHomologations from "./pages/admin/bdd/new/addHomologations";
import Facilities from "./pages/admin/bdd/Facilities";
import NewFacilities from "./pages/admin/bdd/new/addFacilities";
import Championnats from "./pages/admin/bdd/Championnats";
import NewChampionnats from "./pages/admin/bdd/new/addChampionnats";
import ActivityTypes from "./pages/admin/bdd/ActivityTypes";
import NewActivityTypes from "./pages/admin/bdd/new/addActivityTypes";
import RaceCategory from "./pages/admin/bdd/RaceCategory";
import NewRaceCategory from "./pages/admin/bdd/new/addRaceCategory";
import VehicleType from "./pages/admin/bdd/vehicleType";
import Calendrier from "./pages/admin/bdd/Calendar";
import Segments from "./pages/admin/bdd/Segments";
import NewSegments from "./pages/admin/bdd/new/addSegments";
import EditSegments from "./pages/admin/bdd/edit/editSegments";
import EditChampionnat from "./pages/admin/bdd/edit/editChampionnat";
import EditFacilities from "./pages/admin/bdd/edit/editFacilities";
import EditHomologations from "./pages/admin/bdd/edit/editHomologations";
import EditDisciplines from "./pages/admin/bdd/edit/editDisciplines";
import EditLicences from "./pages/admin/bdd/edit/editLicences";
import EditContactCommercial from "./pages/admin/bdd/edit/editContactCommercial";
import NewSimpleActivityTypes from "./pages/admin/bdd/new/addSimpleActivityTypes";
import EditSimpleActivityTypes from "./pages/admin/bdd/edit/editSimpleActivityTypes";
import EditActivityTypes from "./pages/admin/bdd/edit/editActivityTypes";
import EditOrganizations from "./pages/admin/bdd/edit/editOrganizations";
import Pilots from "./pages/admin/bdd/Pilots";
import EditCircuits from "./pages/admin/bdd/edit/editCircuits";
import Offer from "./pages/admin/bdd/Offer";
import NewOffer from "./pages/admin/bdd/new/addOffer";
import EditOffer from "./pages/admin/bdd/edit/editOffer";
import React, {useEffect} from "react";
import Levels from "./pages/admin/bdd/Levels";
import NewLevels from "./pages/admin/bdd/new/addLevels";
import EditLevels from "./pages/admin/bdd/edit/editLevels";
import Documents from "./pages/admin/bdd/Documents";
import NewDocuments from "./pages/admin/bdd/new/addDocuments";
import EditDocuments from "./pages/admin/bdd/edit/editDocuments";
import Dictionnaire from "./pages/admin/bdd/Dictionnaire";
import NewDefinition from "./pages/admin/bdd/new/addDefinition";
import EditDefinition from "./pages/admin/bdd/edit/editDefinition";
import Mails from "./pages/admin/bdd/Mails";
import AddOrganisation from "./pages/admin/bdd/new/addOrganisation";
import AddContactCommercial from "./pages/admin/bdd/new/addContactCommercial";
import EditRaceCategory from "./pages/admin/bdd/edit/editRaceCategory";
import AddVehicleType from "./pages/admin/bdd/new/addVehicleType";
import EditVehicleType from "./pages/admin/bdd/edit/editVehicleType";
import AuthLayout from "./layout/AuthLayout";
import {ToastContainer} from "react-toastify";

const App = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scroll({
            top: -1000,
            behavior: "smooth",
        });
        console.log(pathname)
    }, [pathname]);

    return (
        <AuthLayout>
            <Sidebar>
                <Routes>
                    <Route path="/">
                        <Route index element={<Bdd/>}/>
                        <Route path="circuits">
                            <Route index element={<Circuits/>}/>
                            <Route path="new" element={<AddCircuit/>}/>
                            <Route path="edit/:id" element={<EditCircuits/>}/>
                        </Route>
                        <Route path="organisations">
                            <Route index element={<Organisations/>}/>
                            <Route path="new" element={<AddOrganisation/>}/>
                            <Route path="edit/:id" element={<EditOrganizations/>}/>
                        </Route>
                        <Route path="contact-commercial">
                            <Route index element={<ContactCommercial/>}/>
                            <Route path="new" element={<AddContactCommercial/>}/>
                            <Route path="edit/:id" element={<EditContactCommercial/>}/>
                        </Route>
                        <Route path="licences">
                            <Route index element={<Licences/>}/>
                            <Route path="new" element={<NewLicences/>}/>
                            <Route path="edit/:id" element={<EditLicences/>}/>
                        </Route>
                        <Route path="homologations">
                            <Route index element={<Homologations/>}/>
                            <Route path="new" element={<NewHomologations/>}/>
                            <Route path="edit/:id" element={<EditHomologations/>}/>
                        </Route>
                        <Route path="facilities">
                            <Route index element={<Facilities/>}/>
                            <Route path="new" element={<NewFacilities/>}/>
                            <Route path="edit/:id" element={<EditFacilities/>}/>
                        </Route>
                        <Route path="championnats">
                            <Route index element={<Championnats/>}/>
                            <Route path="new" element={<NewChampionnats/>}/>
                            <Route path="edit/:id" element={<EditChampionnat/>}/>
                        </Route>
                        <Route path="activite">
                            <Route index element={<ActivityTypes/>}/>
                            <Route path="new" element={<NewActivityTypes/>}/>
                            <Route path="edit/:id" element={<EditActivityTypes/>}/>
                        </Route>
                        <Route path="activite">
                            <Route index element={<ActivityTypes/>}/>
                            <Route path="new" element={<NewActivityTypes/>}/>
                            <Route path="edit/:id" element={<EditActivityTypes/>}/>
                        </Route>
                        <Route path="activite-simple">
                            <Route path="new" element={<NewSimpleActivityTypes/>}/>
                            <Route path="edit/:id" element={<EditSimpleActivityTypes/>}/>
                        </Route>
                        <Route path="disciplines">
                            <Route index element={<Disciplines/>}/>
                            <Route path="new" element={<NewDisciplines/>}/>
                            <Route path="edit/:id" element={<EditDisciplines/>}/>
                        </Route>
                        <Route path="race-category">
                            <Route index element={<RaceCategory/>}/>
                            <Route path="new" element={<NewRaceCategory/>}/>
                            <Route path="edit/:id" element={<EditRaceCategory/>}/>
                        </Route>
                        <Route path="vehicules-types">
                            <Route index element={<VehicleType/>}/>
                            <Route path="new" element={<AddVehicleType/>}/>
                            <Route path="edit/:id" element={<EditVehicleType/>}/>
                        </Route>
                        <Route path="segments">
                            <Route index element={<Segments/>}/>
                            <Route path="new" element={<NewSegments/>}/>
                            <Route path="edit/:id" element={<EditSegments/>}/>
                        </Route>
                        <Route path="levels">
                            <Route index element={<Levels/>}/>
                            <Route path="new" element={<NewLevels/>}/>
                            <Route path="edit/:id" element={<EditLevels/>}/>
                        </Route>
                        <Route path="documents">
                            <Route index element={<Documents/>}/>
                            <Route path="new" element={<NewDocuments/>}/>
                            <Route path="edit/:id" element={<EditDocuments/>}/>
                        </Route>
                        <Route path="offres">
                            <Route index element={<Offer/>}/>
                            <Route path="new" element={<NewOffer/>}/>
                            <Route path="edit/:id" element={<EditOffer/>}/>
                        </Route>
                        <Route path="pilotes">
                            <Route index element={<Pilots/>}/>
                        </Route>
                        <Route path="calendar">
                            <Route index element={<Calendrier/>}/>
                        </Route>
                        <Route path="dictionnaire">
                            <Route index element={<Dictionnaire/>}/>
                            <Route path="new" element={<NewDefinition/>}/>
                            <Route path="edit/:id" element={<EditDefinition/>}/>
                        </Route>
                        <Route path="mails">
                            <Route index element={<Mails/>}/>
                        </Route>
                        <Route path="*" element={<Error404/>}/>
                    </Route>
                </Routes>
            </Sidebar>
            <ToastContainer/>
        </AuthLayout>
    );
};

export default App;
