export const CHAMPIONNATS_LIST_REQUEST = "CHAMPIONNATS_LIST_REQUEST"
export const CHAMPIONNATS_LIST_SUCCESS = "CHAMPIONNATS_LIST_SUCCESS"
export const CHAMPIONNATS_LIST_FAIL = "CHAMPIONNATS_LIST_FAIL"

export const CHAMPIONNATS_DETAILS_REQUEST = "CHAMPIONNATS_DETAILS_REQUEST"
export const CHAMPIONNATS_DETAILS_SUCCESS = "CHAMPIONNATS_DETAILS_SUCCESS"
export const CHAMPIONNATS_DETAILS_FAIL = "CHAMPIONNATS_DETAILS_FAIL"

export const CHAMPIONNATS_CREATE_REQUEST = "CHAMPIONNATS_CREATE_REQUEST"
export const CHAMPIONNATS_CREATE_SUCCESS = "CHAMPIONNATS_CREATE_SUCCESS"
export const CHAMPIONNATS_CREATE_FAIL = "CHAMPIONNATS_CREATE_FAIL"
export const CHAMPIONNATS_CREATE_RESET = "CHAMPIONNATS_CREATE_RESET"

export const CHAMPIONNATS_DELETE_REQUEST = "CHAMPIONNATS_DELETE_REQUEST"
export const CHAMPIONNATS_DELETE_SUCCESS = "CHAMPIONNATS_DELETE_SUCCESS"
export const CHAMPIONNATS_DELETE_FAIL = "CHAMPIONNATS_DELETE_FAIL"

export const CHAMPIONNATS_UPDATE_REQUEST = "CHAMPIONNATS_UPDATE_REQUEST"
export const CHAMPIONNATS_UPDATE_SUCCESS = "CHAMPIONNATS_UPDATE_SUCCESS"
export const CHAMPIONNATS_UPDATE_FAIL = "CHAMPIONNATS_UPDATE_FAIL"
export const CHAMPIONNATS_UPDATE_RESET = "CHAMPIONNATS_UPDATE_RESET"
