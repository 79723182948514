import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Textes = (props) => {

    console.log(props)
    return (
        <div className="coordonnees" style={{width: "100%"}}>
            <div className="subCoord">
                <div className="names">
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2 className="first-subTitles">Textes</h2>
                    </div>
                    <br />

                    <p className="label-2">Véhicules Admis</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textVehicle}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextVehicle(data)
                            } }
                        />
                    </div>

                    <p className="label-2">Les documents à présenter</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textDocument}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextDocument(data)
                            } }
                        />
                    </div>

                    <p className="label-2">Tenues et protections obligatoires</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textProtect}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextProtect(data)
                            } }
                        />
                    </div>

                    <p className="label-2">FAQ</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textFAQ}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextFAQ(data)
                            } }
                        />
                    </div>
                    <p className="label-2">Partenaires</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textPartner}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextPartner(data)
                            } }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Textes
