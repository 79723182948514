import {
    PILOTS_CREATE_FAIL,
    PILOTS_CREATE_REQUEST,
    PILOTS_CREATE_SUCCESS,
    PILOTS_CREATE_RESET,
    PILOTS_DELETE_FAIL,
    PILOTS_DELETE_REQUEST,
    PILOTS_DELETE_SUCCESS,
    PILOTS_DETAILS_FAIL,
    PILOTS_DETAILS_REQUEST,
    PILOTS_DETAILS_SUCCESS,
    PILOTS_LIST_FAIL,
    PILOTS_LIST_REQUEST,
    PILOTS_LIST_SUCCESS,
    PILOTS_UPDATE_FAIL,
    PILOTS_UPDATE_REQUEST,
    PILOTS_UPDATE_SUCCESS,
    PILOTS_UPDATE_RESET
} from "../constants/pilotsConstants";

export const pilotsListReducers = (state = {pilots: []}, action) => {
    switch (action.type) {
        case PILOTS_LIST_REQUEST:
            return {loading: true, pilots: []}
        case PILOTS_LIST_SUCCESS:
            return {loading: false, pilots: action.payload}
        case PILOTS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const pilotsDetailsReducers = (state = {pilots: {}}, action) => {
    switch (action.type) {
        case PILOTS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case PILOTS_DETAILS_SUCCESS:
            return {loading: false, pilots: action.payload}
        case PILOTS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const pilotsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case PILOTS_CREATE_REQUEST:
            return {loading: true}
        case PILOTS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case PILOTS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case PILOTS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const pilotsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case PILOTS_DELETE_REQUEST:
            return {loading: true}
        case PILOTS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case PILOTS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const pilotsUpdateReducers = (state = {pilots: {}}, action) => {
    switch (action.type) {
        case PILOTS_UPDATE_REQUEST:
            return {loading: true}
        case PILOTS_UPDATE_SUCCESS:
            return {loading: false, success: true, pilots: action.payload}
        case PILOTS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case PILOTS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
