import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import TheatersIcon from "@material-ui/icons/Theaters";
import LandscapeIcon from "@material-ui/icons/Landscape";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
const Presentation = (props) => {

    return (
        <div className="presentations">
            <div className="subPres">
                <div className="specs">
                    <p className="first-subTitles">Spécifications techniques</p>
                    <p className="label">Sol / Revêtement</p>
                    <div className="name">
                        <TheatersIcon className="icon"/>
                        <select className="state" value={props.sol} onChange={e => props.setSol(e.target.value)}>
                            <option className="sub" value="">-- Choisissez un sol --</option>
                            <option value="Tout-terrain">Tout-terrain</option>
                            <option value="Asphalte">Asphalte</option>
                            <option value="Glace">Glace</option>
                        </select>
                    </div>
                    <p className="label">Espace</p>
                    <div className="name">
                        <LandscapeIcon className="icon"/>
                        <select className="state" value={props.espace} onChange={e => props.setEspace(e.target.value)}>
                            <option className="sub" value="">-- Choisissez un espace --</option>
                            <option value="Intérieur">Intérieur</option>
                            <option value="Extérieur">Extérieur</option>
                        </select>
                    </div>
                    <p className="label">Longueur (en mètres)</p>
                    <div className="name">
                        <TheatersIcon className="false-icon"/>
                        <input className="input" placeholder="Longueur"
                               type="number"
                               value={props.longueur}
                               onChange={e => props.setLongueur(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Largeur (en mètres)</p>
                    <div className="name">
                        <TheatersIcon className="false-icon"/>
                        <input className="input" placeholder="Largeur"
                               value={props.largeur}
                               type={"number"}
                               onChange={e => props.setLargeur(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Plus longue ligne droite (en mètres)</p>
                    <div className="name">
                        <TheatersIcon className="false-icon"/>
                        <input className="input" placeholder="Plus longue ligne droite"
                               value={props.longest_straight}
                               type={"number"}
                               onChange={e => props.setLongest(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Limite de décibel (en dB) (0 => Pas de limite)</p>
                    <div className="name">
                        <VolumeUpIcon className="false-icon"/>
                        <input className="input" placeholder="Limite de décibel"
                               value={props.noise}
                               type={"number"}
                               onChange={e => props.setNoise(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
                <div className="pres">
                    <p className="first-subTitles">Présentation</p>
                    <div className="name">
                        <PersonIcon className="icon"/>
                        <textarea className="input" placeholder="Présentation"
                                  value={props.pres_text} maxLength="5000" rows="20"
                                  onChange={e => props.setPres_text(e.target.value)}
                                  style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
            <div className="subPres">
                <div className="images">
                    <p className="first-subTitles">Images</p>
                    <p className="label">Tracé</p>
                    <div className="name">
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.saveFile(e, "Trace")}
                               style={{margin: "auto"}}/>
                        <img alt={"ActivityAccelerioThumbnail"}
                             src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${props.trace}`} width={"100px"}/>
                    </div>
                    <p className="label">Image 1</p>
                    <div className="name">
                        <input className="input" placeholder="Image 1"
                               type="file"
                               onChange={e => props.saveFile(e, "Img1")}
                               style={{margin: "auto"}}/>
                        <img alt={"ActivityAccelerioThumbnail"}
                             src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${props.img1}`} width={"100px"}/>
                    </div>
                    <p className="label">Image 2</p>
                    <div className="name">
                        <input className="input" placeholder="Image 2"
                               type="file"
                               onChange={e => props.saveFile(e, "Img2")}
                               style={{margin: "auto"}}/>
                        <img alt={"ActivityAccelerioThumbnail"}
                             src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${props.img2}`} width={"100px"}/>
                    </div>
                    <p className="label">Image 3</p>
                    <div className="name">
                        <input className="input" placeholder="Image 3"
                               type="file"
                               onChange={e => props.saveFile(e, "Img3")}
                               style={{margin: "auto"}}/>
                        <img alt={"ActivityAccelerioThumbnail"}
                             src={`https://res.cloudinary.com/accelerio/image/upload/f_auto,q_auto/${props.img2}`} width={"100px"}/>
                    </div>
                </div>
                <div className="video">
                    <p className="first-subTitles">Vidéos</p>
                    <p className="label">Vidéo 1</p>
                    <div className="name">
                        <input className="input" placeholder="URL Video 1"
                               onChange={e => props.setVideo1(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Vidéo 2</p>
                    <div className="name">
                        <input className="input" placeholder="URL Video 2"
                               onChange={e => props.setVideo2(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Presentation
