import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {CountryDropdown} from 'react-country-region-selector';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Adresses = (props) => {

    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Adresse de facturation</p>
                    <p className="label">Adresse</p>
                    <div className="name">
                        <LocationOnIcon className="name-icon"/>
                        <input className="input" placeholder="Adresse"
                               value={props.orgaAdress}
                               onChange={e => props.setOrgaAdress(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Complément d'adresse</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Complément d'adresse"
                               value={props.orgaComplement}
                               onChange={e => props.setOrgaComplement(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Code Postal</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Code Postal"
                               value={props.orgaCode}
                               onChange={e => props.setOrgaCode(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Ville</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Ville"
                               value={props.orgaVille}
                               onChange={e => props.setOrgaVille(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Pays</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <CountryDropdown className="state-country"
                                         value={props.orgaPays}
                                         onChange={(e) => props.setOrgaPays(e)}/>
                    </div>

                </div>
                <div className="status">
                    <p className="first-subTitles">Adresse de livraison</p>
                    <p className="label">Adresse</p>
                    <div className="name">
                        <LocationOnIcon className="name-icon"/>
                        <input className="input" placeholder="Adresse"
                               value={props.orgaAdressLiv}
                               onChange={e => props.setOrgaAdressLiv(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Complément d'adresse</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Complément d'adresse"
                               value={props.orgaComplementLiv}
                               onChange={e => props.setOrgaComplementLiv(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Code Postal</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Code Postal"
                               value={props.orgaCodeLiv}
                               onChange={e => props.setOrgaCodeLiv(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Ville</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <input className="input" placeholder="Ville"
                               value={props.orgaVilleLiv}
                               onChange={e => props.setOrgaVilleLiv(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Pays</p>
                    <div className="name">
                        <LocationOnIcon className="false-icon"/>
                        <CountryDropdown className="state-country"
                                         value={props.orgaPaysLiv}
                                         onChange={(e) => props.setOrgaPaysLiv(e)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adresses