import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ContactsIcon from '@material-ui/icons/Contacts';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import {FacebookOutlined} from "@mui/icons-material";
import InstagramIcon from "@material-ui/icons/Instagram";

const OrgaCoordonnees = (props) => {

    const statusArray = ["Association", "Entreprise", "Auto-entrepreneur"]
    const compteArray = ["Non créé", "En cours", "Certifié"]
    const typeArray = ["Club / Team", "Ecole de pilotage", "Garage / Préparateur / Concession",
        "Constructeur / Equipementier", "Promoteur (Evénement / Compétition)", "Média",
        "Fédération", "Assurance / Administration", "Shop / Ditributeur / Magasin", "Autre"]

    return (
        <div style={{display: "flex", flexWrap: "wrap"}}>
            <div className="names">
                <p className="label">Nom de l'organisation</p>
                <div className="name">
                    <PersonIcon className="name-icon"/>
                    <input className="input" placeholder="Nom de l'organisation"
                            value={props.orgaName}
                            onChange={e => props.setOrgaName(e.target.value)}
                            style={{margin: "auto"}}/>
                </div>
                <p className="label">Statut</p>
                <div className="name">
                    <AccountBalanceIcon className="name-icon"/>
                    <select className="state"
                            value={props.orgaStatus}
                            label={"Status"}
                            onChange={e => props.setOrgaStatus(e.target.value)}
                    >
                        {statusArray.map(c => {
                            return (<option key={c} value={c}>{c}</option>)
                        })}
                    </select>
                </div>
                <p className="label">Compte</p>
                <div className="name">
                    <AccountBalanceWalletIcon className="name-icon"/>
                    <select className="state"
                            value={props.orgaCompte}
                            label={"Compte"}
                            onChange={e => props.setOrgaCompte(e.target.value)}
                    >
                        {compteArray.map(c => {
                            return (<option key={c} value={c}>{c}</option>)
                        })}
                    </select>
                </div>
                <p className="label">Adresse mail</p>
                <div className="name">
                    <MailOutlineIcon className="name-icon"/>
                    <input className="input" placeholder="Adresse mail"
                            value={props.orgaMail}
                            onChange={e => props.setOrgaMail(e.target.value)}
                            style={{margin: "auto"}}/>
                </div>
                <p className="label">Numéro de téléphone</p>
                <div className="name">
                    <CallIcon className="name-icon"/>
                    <input className="input" placeholder="Numéro de téléphone"
                            value={props.orgaPhone}
                            onChange={e => props.setOrgaPhone(e.target.value)}
                            style={{margin: "auto"}}/>
                </div>
                <p className="label">Description</p>
                <div className="name">
                    <DescriptionIcon className="name-icon"/>
                    <textarea className="input" placeholder="Description"
                                value={props.orgaDesc} maxLength="500" rows="10"
                                onChange={e => props.setOrgaDesc(e.target.value)}
                                style={{margin: "auto"}}/>
                </div>
            </div>
            <div className="coordonnes">
                <div className="subCoord">
                    <div className="names">
                        <p className="label">Type d'organisation</p>  
                        <div className="name">
                            <AccountBalanceIcon className="name-icon"/>
                            <select className="state"
                                    value={props.orgaType}
                                    label={"Type d'organisation"}
                                    onChange={e => props.setOrgaType(e.target.value)}
                            >
                                {typeArray.map(c => {
                                    return (<option key={c} value={c}>{c}</option>)
                                })}
                            </select>
                        </div>
                        <p className="label">Contacts</p>
                        {props.formContactValues.map((element, index) => (
                            <div className="name" key={`${element}_${index}`}>
                                <ContactsIcon className="name-icon"/>
                                <select className="state" value={element.contactCommercialId}
                                        onChange={e => props.handleContactChange(index, e)}>
                                    <option className="sub" value="">-- Choisissez un Contact --</option>
                                    {props.Contact_Commercial.map(cc => {
                                        return <option key={cc.id} value={cc.id}>{cc.firstname} {cc.lastname}</option>
                                    })}
                                </select>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => props.removeContactFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button" onClick={() => props.addContactFormFields()}>Add</button>
                        <p className="label">Administrateur</p>
                        <div className="name">
                            <AccountCircleIcon className="name-icon"/>
                            <select className="state" value={props.orgaUser}
                                    onChange={e => props.setOrgaUser(e.target.value)}>
                                <option className="sub" value="">-- Utilisateur --</option>
                                {props.pilots.map(pilot => {
                                    return <option key={pilot.id}
                                                value={pilot.id}>{pilot.firstname} {pilot.lastname}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="subCoord">
                    <div className="names">
                        <p className="label">Facebook</p>
                        <div className="name">
                            <FacebookOutlined className="name-icon"/>
                            <input className="input" placeholder="Facebook"
                                    value={props.facebook}
                                    onChange={e => props.setFacebook(e.target.value)}
                                    style={{margin: "auto"}}/>
                        </div>
                        <p className="label">Instagram</p>
                        <div className="name">
                            <InstagramIcon className="name-icon"/>
                            <input className="input" placeholder="Instagram"
                                    value={props.instagram}
                                    onChange={e => props.setInstagram(e.target.value)}
                                    style={{margin: "auto"}}/>
                        </div>
                        <p className="label">Linkedin</p>
                        <div className="name">
                            <CallIcon className="name-icon"/>
                            <input className="input" placeholder="Linkedin"
                                    value={props.linkedin}
                                    onChange={e => props.setLinkedin(e.target.value)}
                                    style={{margin: "auto"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgaCoordonnees