import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listOrganisations} from "../../../actions/organisationsActions";
import ArrayValueUI from "../../../components/ui/arrayValue";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Organisations = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let organisationsList = useSelector(state => state.organisationsList)
    const {loading, error, organisations} = organisationsList

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" onClick={() =>  history(`edit/${cell.rowData[0]}`)}>{val}</a>
                    )
                },
            }
        },
        {
            label: "Mail",
            name: "mail",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Téléphone",
            name: "phone",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Contact",
            name: "contacts",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas de contact</p>
                    }
                },
            }
        },
        {
            label: "Administrateur",
            name: "pilots",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Compte",
            name: "certification",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Type",
            name: "type",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Segments",
            name: "segments",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas de segments</p>
                    }
                },
            }
        },
    ]

    const options = {
        filter: true,
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listOrganisations())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Organisations</h2>
                <button className="new_button" onClick={() => history('/organisations/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={organisations}
                            columns={columns}
                            options={options}
                        />
                    )}
            </div>
        </ThemeProvider>
    )
}

export default Organisations
