import React, {useState} from 'react'
import '../../../assets/css/backOfficeBdd.css';
import DatePicker from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import Grid from '@mui/material/Grid';
import TimePicker from '@mui/lab/TimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import '../../../assets/css/calendar.css'
import theme from "../../../assets/css/MuiTimePickerDesign";
import {ThemeProvider} from '@mui/material/styles';
import InputIcon from "react-multi-date-picker/components/input_icon"

const Calendrier = (props) => {

    const [value, setValue] = useState([parseInt(props.value)] || []);

    const [dates, setDates] = useState([parseInt(props.dates)] || []);
    const [firstTimes, setFirstTimes] = useState([]);
    const [secondTimes, setSecondTimes] = useState([]);



    return (
        <div className="Calendar">
            <div className="Datepicker">
                <DatePicker style={{backgroundColor: "white"}}
                            render={<InputIcon/>}
                            value={value}
                            onChange={(data) => {
                                console.log(data)
                                data.forEach((element) => {
                                    let found = false;
                                    for (let i = 0; i < dates.length; i++)
                                        if (element.format("DD/MM/YYYY") === dates[i])
                                            found = true;
                                    if (!found) {
                                        dates.push(element.format("DD/MM/YYYY"));
                                        firstTimes.push(null);
                                        secondTimes.push(null);
                                    }
                                });
                                dates.forEach((date, i) => {
                                    let found = false;
                                    for (let j = 0; j < data.length; j++)
                                        if (data[j].format("DD/MM/YYYY") === date)
                                            found = true;
                                    if (!found) {
                                        dates.splice(i, 1);
                                        firstTimes.splice(i, 1);
                                        secondTimes.splice(i, 1);
                                    }
                                })
                                setValue(data);
                                setDates(dates);
                                props.handleVariants(dates)
                                setFirstTimes(firstTimes);
                                setSecondTimes(secondTimes);
                            }}
                            multiple
                            plugins={[
                                <DatePanel/>,
                            ]}
                />
            </div>
            <Grid container spacing={1}>
                {dates.map((date, i) =>
                    <Grid container item spacing={1}>
                        <p>{date}</p>
                        <ThemeProvider theme={theme}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <TimePicker
                                    ampm={false}
                                    minutesStep="5"
                                    label="Début"
                                    value={firstTimes[i]}
                                    onChange={(newValue) => {
                                        const temp = [];
                                        for (let j = 0; j < firstTimes.length; j++) {
                                            if (j === i)
                                                temp.push(newValue);
                                            else
                                                temp.push(firstTimes[j]);
                                        }
                                        setFirstTimes(temp);
                                    }}
                                    renderInput={(params) => <TextField {...params}/>}
                                />
                                <TimePicker
                                    ampm={false}
                                    minutesStep="5"
                                    label="Fin"
                                    value={secondTimes[i]}
                                    onChange={(newValue) => {
                                        const temp = [];
                                        for (let j = 0; j < secondTimes.length; j++) {
                                            if (j === i)
                                                temp.push(newValue);
                                            else
                                                temp.push(secondTimes[j]);
                                        }
                                        setSecondTimes(temp);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </ThemeProvider>
                    </Grid>
                )}
            </Grid>
        </div>
    )
}

export default Calendrier
