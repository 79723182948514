import axios from "../axios"
import {toast} from "react-toastify";


export const listLanguage = async () => {
    try {

        const {data} = await toast.promise(
            axios.get('/api/languages'),
            {
                pending: 'Langue en cours de chargement',
                success: 'Toutes les langues ont été recupérés 👌',
                error: 'Nous ne parvenons pas à récupérer les informations 🤯'
            }
        );
        return data
    } catch (error) {
        return error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
    }
}