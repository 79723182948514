import axios from "../axios"

import {
    ORGANISATIONS_CREATE_FAIL,
    ORGANISATIONS_CREATE_REQUEST,
    ORGANISATIONS_CREATE_SUCCESS,
    ORGANISATIONS_DELETE_FAIL,
    ORGANISATIONS_DELETE_REQUEST,
    ORGANISATIONS_DELETE_SUCCESS,
    ORGANISATIONS_DETAILS_FAIL,
    ORGANISATIONS_DETAILS_REQUEST,
    ORGANISATIONS_DETAILS_SUCCESS,
    ORGANISATIONS_LIST_FAIL,
    ORGANISATIONS_LIST_REQUEST,
    ORGANISATIONS_LIST_SUCCESS,
    ORGANISATIONS_UPDATE_FAIL,
    ORGANISATIONS_UPDATE_REQUEST,
    ORGANISATIONS_UPDATE_SUCCESS
} from "../constants/organisationsConstants";

export const listOrganisations = () => async (dispatch) => {
    try {
        dispatch({type: ORGANISATIONS_LIST_REQUEST})
        const {data} = await axios.get('/organization')
        dispatch({
            type: ORGANISATIONS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ORGANISATIONS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getOrganisations = (id) => async (dispatch) => {
    try {
        dispatch({type: ORGANISATIONS_DETAILS_REQUEST})
        const {data} = await axios.get(`/organization/${id}`)
        dispatch({
            type: ORGANISATIONS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ORGANISATIONS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteOrganisations = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANISATIONS_DELETE_REQUEST,
        })
        await axios.delete(`/organization/${id}`)

        dispatch({
            type: ORGANISATIONS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ORGANISATIONS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createOrganisations = (createdOrganisations) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANISATIONS_CREATE_REQUEST,
        })
        await axios.post(`/organization`, createdOrganisations)

        dispatch({
            type: ORGANISATIONS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ORGANISATIONS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateOrganisations = (updatedOrganisations, id) => async (dispatch) => {
    try {
        dispatch({
            type: ORGANISATIONS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/organization/${id}`, updatedOrganisations)

        dispatch({
            type: ORGANISATIONS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ORGANISATIONS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
