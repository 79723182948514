import {
    CHAMPIONNATS_CREATE_FAIL,
    CHAMPIONNATS_CREATE_REQUEST,
    CHAMPIONNATS_CREATE_RESET,
    CHAMPIONNATS_CREATE_SUCCESS,
    CHAMPIONNATS_DELETE_FAIL,
    CHAMPIONNATS_DELETE_REQUEST,
    CHAMPIONNATS_DELETE_SUCCESS,
    CHAMPIONNATS_DETAILS_FAIL,
    CHAMPIONNATS_DETAILS_REQUEST,
    CHAMPIONNATS_DETAILS_SUCCESS,
    CHAMPIONNATS_LIST_FAIL,
    CHAMPIONNATS_LIST_REQUEST,
    CHAMPIONNATS_LIST_SUCCESS,
    CHAMPIONNATS_UPDATE_FAIL,
    CHAMPIONNATS_UPDATE_REQUEST,
    CHAMPIONNATS_UPDATE_RESET,
    CHAMPIONNATS_UPDATE_SUCCESS
} from "../constants/championnatsConstants";

export const championnatsListReducers = (state = {Championnats: []}, action) => {
    switch (action.type) {
        case CHAMPIONNATS_LIST_REQUEST:
            return {loading: true, Championnats: []}
        case CHAMPIONNATS_LIST_SUCCESS:
            return {loading: false, Championnats: action.payload}
        case CHAMPIONNATS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const championnatsDetailsReducers = (state = {Championnats: {}}, action) => {
    switch (action.type) {
        case CHAMPIONNATS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case CHAMPIONNATS_DETAILS_SUCCESS:
            return {loading: false, Championnats: action.payload}
        case CHAMPIONNATS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const championnatsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case CHAMPIONNATS_CREATE_REQUEST:
            return {loading: true}
        case CHAMPIONNATS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case CHAMPIONNATS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case CHAMPIONNATS_CREATE_RESET:
            return{}
        default:
            return state
    }
}

export const championnatsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case CHAMPIONNATS_DELETE_REQUEST:
            return {loading: true}
        case CHAMPIONNATS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case CHAMPIONNATS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const championnatsUpdateReducers = (state = {Championnats: {}}, action) => {
    switch (action.type) {
        case CHAMPIONNATS_UPDATE_REQUEST:
            return {loading: true}
        case CHAMPIONNATS_UPDATE_SUCCESS:
            return {loading: false, success: true, Championnats: action.payload}
        case CHAMPIONNATS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
            case CHAMPIONNATS_UPDATE_RESET:
                return{}
        default:
            return state
    }
}
