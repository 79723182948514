import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";

const AuthLayout = (props) => {

    const [enterLogin, setEnterLogin] = useState(localStorage.getItem('login'))
    const [enterPassword, setEnterPassword] = useState(localStorage.getItem('password'))
    const [good, setGood] = useState(false)

    let goodLogin = "accelerio@accelerio.com"
    let goodPassword = "bhNX7hfnqnYhRLH6"

    useEffect(() => {
        if (localStorage.getItem('login') && localStorage.getItem('password')) {
            setGood(true)
        }
    })
    const testConnect = () => {
        if (enterLogin === goodLogin && enterPassword === goodPassword) {
            localStorage.setItem('login', goodLogin)
            localStorage.setItem('password', goodPassword)
            setGood(true)
            toast.success('Connected')
        } else {
            setGood(false)
            toast.error("Wrong password")
        }
    }

    if (good) {
        return (
            <div>
                {props.children}
            </div>
        )
    } else {
        return (
            <div>
                <input value={enterLogin} placeholder="Login" type={"email"}
                       onChange={(e) => setEnterLogin(e.target.value)}/>
                <input value={enterPassword} placeholder="Password" type={"password"}
                       onChange={(e) => setEnterPassword(e.target.value)}/>
                <button onClick={testConnect}>Se connecter</button>
            </div>
        )

    }
}

export default AuthLayout
