export const RACE_CATEGORY_LIST_REQUEST = "RACE_CATEGORY_LIST_REQUEST"
export const RACE_CATEGORY_LIST_SUCCESS = "RACE_CATEGORY_LIST_SUCCESS"
export const RACE_CATEGORY_LIST_FAIL = "RACE_CATEGORY_LIST_FAIL"

export const RACE_CATEGORY_DETAILS_REQUEST = "RACE_CATEGORY_DETAILS_REQUEST"
export const RACE_CATEGORY_DETAILS_SUCCESS = "RACE_CATEGORY_DETAILS_SUCCESS"
export const RACE_CATEGORY_DETAILS_FAIL = "RACE_CATEGORY_DETAILS_FAIL"

export const RACE_CATEGORY_CREATE_REQUEST = "RACE_CATEGORY_CREATE_REQUEST"
export const RACE_CATEGORY_CREATE_SUCCESS = "RACE_CATEGORY_CREATE_SUCCESS"
export const RACE_CATEGORY_CREATE_FAIL = "RACE_CATEGORY_CREATE_FAIL"
export const RACE_CATEGORY_CREATE_RESET = "RACE_CATEGORY_CREATE_RESET"

export const RACE_CATEGORY_DELETE_REQUEST = "RACE_CATEGORY_DELETE_REQUEST"
export const RACE_CATEGORY_DELETE_SUCCESS = "RACE_CATEGORY_DELETE_SUCCESS"
export const RACE_CATEGORY_DELETE_FAIL = "RACE_CATEGORY_DELETE_FAIL"

export const RACE_CATEGORY_UPDATE_REQUEST = "RACE_CATEGORY_UPDATE_REQUEST"
export const RACE_CATEGORY_UPDATE_SUCCESS = "RACE_CATEGORY_UPDATE_SUCCESS"
export const RACE_CATEGORY_UPDATE_FAIL = "RACE_CATEGORY_UPDATE_FAIL"
export const RACE_CATEGORY_UPDATE_RESET = "RACE_CATEGORY_UPDATE_RESET"
