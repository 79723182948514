import {
    LEVELS_CREATE_FAIL,
    LEVELS_CREATE_REQUEST,
    LEVELS_CREATE_SUCCESS,
    LEVELS_DELETE_FAIL, 
    LEVELS_DELETE_REQUEST,
    LEVELS_DELETE_SUCCESS,
    LEVELS_DETAILS_FAIL,
    LEVELS_DETAILS_REQUEST,
    LEVELS_DETAILS_SUCCESS,
    LEVELS_LIST_FAIL,
    LEVELS_LIST_REQUEST,
    LEVELS_LIST_SUCCESS,
    LEVELS_UPDATE_FAIL,
    LEVELS_UPDATE_REQUEST,
    LEVELS_UPDATE_RESET,
    LEVELS_UPDATE_SUCCESS,
    LEVELS_CREATE_RESET
} from "../constants/levelsConstants";

export const levelsListReducers = (state = {levels: []}, action) => {
    switch (action.type) {
        case LEVELS_LIST_REQUEST:
            return {loading: true, levels: []}
        case LEVELS_LIST_SUCCESS:
            return {loading: false, levels: action.payload}
        case LEVELS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const levelsDetailsReducers = (state = {levels: {}}, action) => {
    switch (action.type) {
        case LEVELS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case LEVELS_DETAILS_SUCCESS:
            return {loading: false, levels: action.payload}
        case LEVELS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const levelsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case LEVELS_CREATE_REQUEST:
            return {loading: true}
        case LEVELS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case LEVELS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case LEVELS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const levelsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case LEVELS_DELETE_REQUEST:
            return {loading: true}
        case LEVELS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case LEVELS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const levelsUpdateReducers = (state = {levels: {}}, action) => {
    switch (action.type) {
        case LEVELS_UPDATE_REQUEST:
            return {loading: true}
        case LEVELS_UPDATE_SUCCESS:
            return {loading: false, success: true, levels: action.payload}
        case LEVELS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case LEVELS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
