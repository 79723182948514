export const ORGANISATIONS_LIST_REQUEST = "ORGANISATIONS_LIST_REQUEST"
export const ORGANISATIONS_LIST_SUCCESS = "ORGANISATIONS_LIST_SUCCESS"
export const ORGANISATIONS_LIST_FAIL = "ORGANISATIONS_LIST_FAIL"

export const ORGANISATIONS_DETAILS_REQUEST = "ORGANISATIONS_DETAILS_REQUEST"
export const ORGANISATIONS_DETAILS_SUCCESS = "ORGANISATIONS_DETAILS_SUCCESS"
export const ORGANISATIONS_DETAILS_FAIL = "ORGANISATIONS_DETAILS_FAIL"

export const ORGANISATIONS_CREATE_REQUEST = "ORGANISATIONS_CREATE_REQUEST"
export const ORGANISATIONS_CREATE_SUCCESS = "ORGANISATIONS_CREATE_SUCCESS"
export const ORGANISATIONS_CREATE_FAIL = "ORGANISATIONS_CREATE_FAIL"
export const ORGANISATIONS_CREATE_RESET = "ORGANISATIONS_CREATE_RESET"

export const ORGANISATIONS_DELETE_REQUEST = "ORGANISATIONS_DELETE_REQUEST"
export const ORGANISATIONS_DELETE_SUCCESS = "ORGANISATIONS_DELETE_SUCCESS"
export const ORGANISATIONS_DELETE_FAIL = "ORGANISATIONS_DELETE_FAIL"

export const ORGANISATIONS_UPDATE_REQUEST = "ORGANISATIONS_UPDATE_REQUEST"
export const ORGANISATIONS_UPDATE_SUCCESS = "ORGANISATIONS_UPDATE_SUCCESS"
export const ORGANISATIONS_UPDATE_FAIL = "ORGANISATIONS_UPDATE_FAIL"
export const ORGANISATIONS_UPDATE_RESET = "ORGANISATIONS_UPDATE_RESET"
