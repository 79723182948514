import axios from "../axios"
import {
    ACTIVITY_TYPES_PHOTOS_CREATE_FAIL,
    ACTIVITY_TYPES_PHOTOS_CREATE_REQUEST,
    ACTIVITY_TYPES_PHOTOS_CREATE_SUCCESS,
    ACTIVITY_TYPES_PHOTOS_DELETE_FAIL,
    ACTIVITY_TYPES_PHOTOS_DELETE_REQUEST,
    ACTIVITY_TYPES_PHOTOS_DELETE_SUCCESS,
    ACTIVITY_TYPES_PHOTOS_DETAILS_FAIL,
    ACTIVITY_TYPES_PHOTOS_DETAILS_REQUEST,
    ACTIVITY_TYPES_PHOTOS_DETAILS_SUCCESS,
    ACTIVITY_TYPES_PHOTOS_LIST_FAIL,
    ACTIVITY_TYPES_PHOTOS_LIST_REQUEST,
    ACTIVITY_TYPES_PHOTOS_LIST_SUCCESS,
    ACTIVITY_TYPES_PHOTOS_UPDATE_FAIL,
    ACTIVITY_TYPES_PHOTOS_UPDATE_REQUEST,
    ACTIVITY_TYPES_PHOTOS_UPDATE_SUCCESS
} from "../constants/activityPhotosTypesConstants";
import {toast} from "react-toastify";



export const listActivityPhotosTypes = () => async (dispatch) => {
    try {
        dispatch({type: ACTIVITY_TYPES_PHOTOS_LIST_REQUEST})

        const {data} = await toast.promise(
            axios.get('/activityphotostypes'),
            {
                pending: 'Acitivté en cours de chargement',
                success: 'Toutes les activités ont été recupérés 👌',
                error: 'Nous ne parvenons pas à récupérer les informations 🤯'
            }
        );
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getActivityPhotosTypes = (id) => async (dispatch) => {
    try {
        dispatch({type: ACTIVITY_TYPES_PHOTOS_DETAILS_REQUEST})
        const {data} = await axios.get(`/activityphotostypes/${id}`)
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteActivityPhotosTypes = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_DELETE_REQUEST,
        })
        await axios.delete(`/activityphotostypes/${id}`)

        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createActivityPhotosTypes = (createdActivityTypes) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_CREATE_REQUEST,
        })
        await axios.post(`/activityphotostypes`, createdActivityTypes)

        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateActivityPhotosTypes = (updatedActivityTypes, id) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/activityphotostypes/${id}`, updatedActivityTypes)

        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_PHOTOS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
