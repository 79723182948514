import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {deleteDisciplines, getDisciplines, updateDisciplines} from "../../../../actions/disciplinesActions";
import axios from "../../../../axios";
import {DISCIPLINES_UPDATE_RESET} from '../../../../constants/disciplinesConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditDisciplines = (props) => {
    const {id: disciplinesId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let disciplinesDetails = useSelector(state => state.disciplinesDetails)
    const {loading, error, Disciplines} = disciplinesDetails

    let disciplinesUpdate = useSelector(state => state.disciplinesUpdate)
    const {error: errorUpdate, success: successUpdate} = disciplinesUpdate

    let disciplinesDelete = useSelector(state => state.disciplinesDelete)
    const {success: successDelete} = disciplinesDelete

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [oldThumbnailName, setOldThumbnailName] = useState("")
    const [profile_picture, setProfile] = useState("")
    const [oldProfilePictureName, setOldProfileName] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    const saveFile = async (e, type) => {
        switch (type) {
            case "Profile":
                setProfile(e.target.files[0]);
                break
            case "Thumbnail":
                setThumbnail(e.target.files[0]);
                break
            default:
                break
        }
    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        const formDataThumbnail = new FormData();
        let publicIdPP = oldProfilePictureName
        let publicIdTB = oldThumbnailName

        try {
            if (profile_picture) {
                formDataProfile.append("file", profile_picture);
                formDataProfile.append("folder", "disciplines/profile");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdPP = res.data.public_id
            }

            if (thumbnail) {
                formDataThumbnail.append("file", thumbnail);
                formDataThumbnail.append("folder", "disciplines/thumbnail");
                formDataThumbnail.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataThumbnail)
                publicIdTB = res2.data.public_id
            }

            return [publicIdPP, publicIdTB]
        } catch (ex) {
            console.log(ex);
        }

    };

    const EditHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: name,
                description: description,
                thumbnail: res[1],
                profile_picture: res[0],
            }
            dispatch(updateDisciplines(data, disciplinesId))
        })
    }

    const deleteHandler = () => {
        dispatch(deleteDisciplines(disciplinesId))
    }

    useEffect(() => {
        if (successDelete) {
            history('/disciplines')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getDisciplines(disciplinesId))
            dispatch({type: DISCIPLINES_UPDATE_RESET})
            setTimeout(() => {
                history('/disciplines')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (disciplinesId !== Disciplines[0]?.id.toString()) {
            dispatch(getDisciplines(disciplinesId))
        } else {
            setName(Disciplines[0].name)
            setDescription(Disciplines[0].description)
            setOldThumbnailName(Disciplines[0].thumbnail)
            setOldProfileName(Disciplines[0].profile_picture)
        }
    }, [dispatch, history, disciplinesId, Disciplines, successUpdate, errorUpdate, successDelete])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'équipement a bien été modifié !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Disciplines</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom de la dscipline</p>
                            <input className="input" placeholder="Nom de la discipline"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                            />
                            <p className="label-2">Miniature</p>
                            <input className="input" placeholder="Cover picture"
                                   type="file"
                                   onChange={async e => {
                                       await saveFile(e, "Thumbnail")
                                   }}
                            />
                            <p className="label-2">Photos de profil</p>
                            <input className="input" placeholder="Profile picture"
                                   type="file"
                                   onChange={async e => {
                                       await saveFile(e, "Profile")
                                   }}
                            />
                            <p className="label-2">Description</p>
                            <textarea className="input" placeholder="Description"
                                      value={description}
                                      onChange={e => setDescription(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditDisciplines
