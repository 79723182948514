import React, {Fragment} from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Licences = (props) => {

    return (
        <div className="coordonnees">
            <div className="subPres">
                <div className="names">
                    <p className="first-subTitles">Licences</p>
                    <p className="label-2">Est-il nécessaire d’avoir une licence pour participer à l’activité ?</p>
                    <button className={`am-button-offer ${props.needLicence === 1 ? "actif" : ""}`}
                            onClick={() => props.setNeedLicence(1)}>Oui
                    </button>
                    <button className={`am-button-offer ${props.needLicence === 0 ? "actif" : ""}`}
                            onClick={() => props.setNeedLicence(0)}>Non
                    </button>

                    <br/><br/>
                    {props.needLicence === 1 && <Fragment>
                        <p className="label-2">Licences autorisées</p>
                        <div>
                            {props.Licences?.map((licences, index) => {
                                if (props.allLicences.includes(licences.id)) {
                                    return (
                                        <button className="am-button actif" onClick={
                                            () => {
                                                props.removeArray(licences.id, props.allLicences, props.setAllLicences)
                                            }}>
                                            {licences.name}
                                        </button>)
                                } else if (!props.allLicences.includes(licences.id)) {
                                    return (
                                        <button className="am-button"
                                                onClick={() => props.setAllLicences([...props.allLicences, licences.id])}>
                                            {licences.name}
                                        </button>)
                                }
                                return null
                            })}
                        </div>
                    </Fragment>}

                </div>
            </div>
        </div>
    )
}

export default Licences
