export const DOCUMENTS_LIST_REQUEST = "DOCUMENTS_LIST_REQUEST"
export const DOCUMENTS_LIST_SUCCESS = "DOCUMENTS_LIST_SUCCESS"
export const DOCUMENTS_LIST_FAIL = "DOCUMENTS_LIST_FAIL"

export const DOCUMENTS_DETAILS_REQUEST = "DOCUMENTS_DETAILS_REQUEST"
export const DOCUMENTS_DETAILS_SUCCESS = "DOCUMENTS_DETAILS_SUCCESS"
export const DOCUMENTS_DETAILS_FAIL = "DOCUMENTS_DETAILS_FAIL"

export const DOCUMENTS_CREATE_REQUEST = "DOCUMENTS_CREATE_REQUEST"
export const DOCUMENTS_CREATE_SUCCESS = "DOCUMENTS_CREATE_SUCCESS"
export const DOCUMENTS_CREATE_FAIL = "DOCUMENTS_CREATE_FAIL"
export const DOCUMENTS_CREATE_RESET = "DOCUMENTS_CREATE_RESET"

export const DOCUMENTS_DELETE_REQUEST = "DOCUMENTS_DELETE_REQUEST"
export const DOCUMENTS_DELETE_SUCCESS = "DOCUMENTS_DELETE_SUCCESS"
export const DOCUMENTS_DELETE_FAIL = "DOCUMENTS_DELETE_FAIL"

export const DOCUMENTS_UPDATE_REQUEST = "DOCUMENTS_UPDATE_REQUEST"
export const DOCUMENTS_UPDATE_SUCCESS = "DOCUMENTS_UPDATE_SUCCESS"
export const DOCUMENTS_UPDATE_FAIL = "DOCUMENTS_UPDATE_FAIL"
export const DOCUMENTS_UPDATE_RESET = "DOCUMENTS_UPDATE_RESET"
