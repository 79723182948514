import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
    deleteFacilities,
    getFacilities,
    updateFacilities
} from "../../../../actions/facilitiesActions";
import axios from "../../../../axios";
import {FACILITIES_UPDATE_RESET} from '../../../../constants/facilitiesConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditFacilities = (props) => {
    const {id: facilitiesId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let facilitiesDetails = useSelector(state => state.facilitiesDetails)
    const {loading, error, Facilities} = facilitiesDetails

    let facilitiesUpdate = useSelector(state => state.facilitiesUpdate)
    const {error: errorUpdate, success: successUpdate} = facilitiesUpdate

    let facilitiesDelete = useSelector(state => state.facilitiesDelete)
    const {success: successDelete} = facilitiesDelete

    const categoryArray = ["Confort", "Ambiance", "Restauration", "Parking", "Course", "Sécurité", "Entretien"]

    const [facilitiesName, setFacilitiesName] = useState("")
    const [facilitiesCategory, setFacilitiesCategory] = useState("")
    const [facilitiesPicture, setFacilitiesPicture] = useState()
    const [oldFacilitiesPictureName, setOldFacilitiesPictureName] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    const saveFile = (e) => {
        setFacilitiesPicture(e.target.files[0]);
    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        let publicIdPP = oldFacilitiesPictureName

        try {
            if (facilitiesPicture) {
                formDataProfile.append("file", facilitiesPicture);
                formDataProfile.append("folder", "facilities");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdPP = res.data.public_id
            }

            return [publicIdPP]
        } catch (ex) {
            console.log(ex);
        }

    };

    const EditHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: facilitiesName,
                icon: res[0],
                category: facilitiesCategory,
            }
            dispatch(updateFacilities(data, facilitiesId))
        })

    }

    const deleteHandler = () => {
        dispatch(deleteFacilities(facilitiesId))
    }


    useEffect(() => {
        if (successDelete) {
            history('/facilities')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getFacilities(facilitiesId))
            dispatch({type: FACILITIES_UPDATE_RESET})
            setTimeout(() => {
                history('/facilities')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (facilitiesId !== Facilities[0]?.id.toString()) {
            dispatch(getFacilities(facilitiesId))
        } else {
            setFacilitiesName(Facilities[0].name)
            setOldFacilitiesPictureName(Facilities[0].icon)
            setFacilitiesCategory(Facilities[0].category)
        }
    }, [dispatch, history, facilitiesId, Facilities, successUpdate, errorUpdate, successDelete])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'équipement a bien été modifié !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Facilities</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={facilitiesName}
                                   onChange={e => setFacilitiesName(e.target.value)}
                            />
                            <p className="label-2">Image de profil</p>
                            <input className="input" type="file" placeholder="Profile Picture"
                                   value={facilitiesPicture}
                                   onChange={e => saveFile(e)}
                            />
                            <p className="label-2">Catégorie</p>
                            <select className="input" placeholder="Category"
                                    value={facilitiesCategory}
                                    onChange={e => setFacilitiesCategory(e.target.value)}
                            >
                                {categoryArray.map(c => {
                                    if (c === facilitiesCategory) {
                                        return (<option key={c} value={c} selected>{c}</option>)
                                    } else {
                                        return (<option key={c} value={c}>{c}</option>)
                                    }

                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditFacilities
