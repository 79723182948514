export const SEGMENTS_LIST_REQUEST = "SEGMENTS_LIST_REQUEST"
export const SEGMENTS_LIST_SUCCESS = "SEGMENTS_LIST_SUCCESS"
export const SEGMENTS_LIST_FAIL = "SEGMENTS_LIST_FAIL"

export const SEGMENTS_DETAILS_REQUEST = "SEGMENTS_DETAILS_REQUEST"
export const SEGMENTS_DETAILS_SUCCESS = "SEGMENTS_DETAILS_SUCCESS"
export const SEGMENTS_DETAILS_FAIL = "SEGMENTS_DETAILS_FAIL"

export const SEGMENTS_CREATE_REQUEST = "SEGMENTS_CREATE_REQUEST"
export const SEGMENTS_CREATE_SUCCESS = "SEGMENTS_CREATE_SUCCESS"
export const SEGMENTS_CREATE_FAIL = "SEGMENTS_CREATE_FAIL"
export const SEGMENTS_CREATE_RESET = "SEGMENTS_CREATE_RESET"

export const SEGMENTS_DELETE_REQUEST = "SEGMENTS_DELETE_REQUEST"
export const SEGMENTS_DELETE_SUCCESS = "SEGMENTS_DELETE_SUCCESS"
export const SEGMENTS_DELETE_FAIL = "SEGMENTS_DELETE_FAIL"

export const SEGMENTS_UPDATE_REQUEST = "SEGMENTS_UPDATE_REQUEST"
export const SEGMENTS_UPDATE_SUCCESS = "SEGMENTS_UPDATE_SUCCESS"
export const SEGMENTS_UPDATE_FAIL = "SEGMENTS_UPDATE_FAIL"
export const SEGMENTS_UPDATE_RESET = "SEGMENTS_UPDATE_RESET"
