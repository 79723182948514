import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Medias = (props) => {

    console.log(props.descImagePrez)
    return (
        <div className="coordonnees">
            <div className="subPres">
                <div className="names">
                    <p className="first-subTitles">Image miniatures de présentation</p>
                    <p className="label-2">Image</p>
                    <div className="name">
                        <input className="input" placeholder="Image"
                               type="file"
                               onChange={e => props.setDescImagePrez(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>

                </div>
                <div className="names">
                    <p className="first-subTitles">Vidéos</p>
                    <p className="label-2">Vidéo 1</p>
                    <div className="name">
                        <input className="input" placeholder="URL Video 1"
                               onChange={e => props.setDescVideo1(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Vidéo 2</p>
                    <div className="name">
                        <input className="input" placeholder="URL Video 2"
                               onChange={e => props.setDescVideo2(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
                <div className="names">
                    <p className="first-subTitles">Images Header</p>
                    <p className="label-2">Tracé</p>
                    <div className="name">
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.setDescImage1(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 1</p>
                    <div className="name">
                        <input className="input" placeholder="Image 1"
                               type="file"
                               onChange={e => props.setDescImage2(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 2</p>
                    <div className="name">
                        <input className="input" placeholder="Image 2"
                               type="file"
                               onChange={e => props.setDescImage3(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 3</p>
                    <div className="name">
                        <input className="input" placeholder="Image 3"
                               type="file"
                               onChange={e => props.setDescImage4(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
                <div className="names">
                    <p className="first-subTitles">Images Gallerie</p>
                    <p className="label-2">Image 1</p>
                    <div className="name">
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.setDescGalImage1(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 2</p>
                    <div className="name">
                        <input className="input" placeholder="Image 1"
                               type="file"
                               onChange={e => props.setDescGalImage2(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 3</p>
                    <div className="name">
                        <input className="input" placeholder="Image 2"
                               type="file"
                               onChange={e => props.setDescGalImage3(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 4</p>
                    <div className="name">
                        <input className="input" placeholder="Image 3"
                               type="file"
                               onChange={e => props.setDescGalImage4(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 5</p>
                    <div className="name">
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.setDescGalImage5(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 6</p>
                    <div className="name">
                        <input className="input" placeholder="Image 1"
                               type="file"
                               onChange={e => props.setDescGalImage6(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 7</p>
                    <div className="name">
                        <input className="input" placeholder="Image 2"
                               type="file"
                               onChange={e => props.setDescGalImage7(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 8</p>
                    <div className="name">
                        <input className="input" placeholder="Image 3"
                               type="file"
                               onChange={e => props.setDescGalImage8(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 9</p>
                    <div className="name">
                        <input className="input" placeholder="Tracé PNG"
                               type="file"
                               onChange={e => props.setDescGalImage9(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 10</p>
                    <div className="name">
                        <input className="input" placeholder="Image 1"
                               type="file"
                               onChange={e => props.setDescGalImage10(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 11</p>
                    <div className="name">
                        <input className="input" placeholder="Image 2"
                               type="file"
                               onChange={e => props.setDescGalImage11(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label-2">Image 12</p>
                    <div className="name">
                        <input className="input" placeholder="Image 3"
                               type="file"
                               onChange={e => props.setDescGalImage12(e.target.files[0])}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Medias
