import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import '../../../../assets/css/style.css'
import axios from "../../../../axios";
import {useDispatch, useSelector} from "react-redux";
import {deleteOffers, getOffers, updateOffers} from "../../../../actions/offersActions";
import {listCircuits} from "../../../../actions/circuitsActions";
import {listLicences} from "../../../../actions/licencesActions";
import {listVehicleTypes} from "../../../../actions/vehiculeTypeAction";
import {listLevels} from "../../../../actions/levelsActions";
import {listDocuments} from "../../../../actions/documentsActions";
import {useNavigate, useParams} from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from "@mui/material/Snackbar";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DescriptionIcon from '@mui/icons-material/Description';
import Deroulement from '../../../../components/offers/Deroulement'
import Description from '../../../../components/offers/Description';
import Documents from '../../../../components/offers/Documents';
import Licences from '../../../../components/offers/Licences';
import Medias from '../../../../components/offers/Medias';
import Vehicules from '../../../../components/offers/Vehicules';
import {OFFERS_UPDATE_RESET} from '../../../../constants/offersConstants';
import MemberCard from "../../../../components/offers/MemberCard";
import CategoryOffers from "../../../../components/offers/Categorie";
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";
import {listPilots} from "../../../../actions/pilotsActions";
import {listOrganisations} from "../../../../actions/organisationsActions";
import Textes from "../../../../components/offers/Textes";
import {listActivityTypes} from "../../../../actions/activityTypesActions";
import {listLanguage} from "../../../../actions/languageActions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditOffer = (props) => {
    const {id: offersId} = useParams()

    const [tabs, setTabs] = useState(1)

    const history = useNavigate()
    const dispatch = useDispatch()

    const variant = {id: null, title: "", price: "", minPrice: "", stock: 0, cardId: "", startDate: "", endDate: "", maxQuantity: 1, needVehiclePresentation: false}

    const category = {
        id: null,
        title: "",
        description: "",
        image: "",
        option: false,
        variants: [variant]
    }

    let offersDetails = useSelector(state => state.offersDetails)
    const {loading, error, offers} = offersDetails

    let offersDelete = useSelector(state => state.offersDelete)
    const {success: successDelete} = offersDelete

    let offersUpdate = useSelector(state => state.offersUpdate)
    const {success: successUpdate} = offersUpdate

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let vehiculeTypesList = useSelector(state => state.vehiculeTypesList)
    const {vehiculeTypes} = vehiculeTypesList

    let licencesList = useSelector(state => state.licencesList)
    const {Licences: list_Licences} = licencesList

    let levelsList = useSelector(state => state.levelsList)
    const {levels: list_Levels} = levelsList

    let circuitsList = useSelector(state => state.circuitsList)
    const {circuits} = circuitsList

    let documentsList = useSelector(state => state.documentsList)
    const {documents} = documentsList

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {ActivityTypes: activityTypesSimple} = activityTypesList

    const [formVehiculeTypesAcceptedValues, setformVehiculeTypesAcceptedValues] = useState([{vehiculeTypesId: ""}])
    const [formVehiculeTypesRefusedValues, setformVehiculeTypesRefusedValues] = useState([{vehiculeTypesId: ""}])

    const [formTypeActivityValues, setFormTypeActivityValues] = useState([{id: null}])
    const [formLangValues, setFormLangValues] = useState([{id: null}])
    const [listLang, setListLang] = useState([])

    const [formCards, setFormCards] = useState([{title: "", price: "", calendar: ""}])

    const [formCategory, setFormCategory] = useState([category])

    const [circuit, setCircuit] = useState("")

    const [descPrecisions, setDescPrecisions] = useState("")
    const [descTarifsPrecisions, setDescTarifsPrecisions] = useState("")
    const [descCardsPrecisions, setDescCardsPrecisions] = useState("")
    const [programme, setProgramme] = useState("")

    const [descTitle, setDescTitle] = useState("")
    const [descModalites, setDescModalites] = useState("")

    const [descImagePrez, setDescImagePrez] = useState(null)

    const [descImage1, setDescImage1] = useState(null)
    const [descImage2, setDescImage2] = useState(null)
    const [descImage3, setDescImage3] = useState(null)
    const [descImage4, setDescImage4] = useState(null)

    const [descGalImage1, setDescGalImage1] = useState(null)
    const [descGalImage2, setDescGalImage2] = useState(null)
    const [descGalImage3, setDescGalImage3] = useState(null)
    const [descGalImage4, setDescGalImage4] = useState(null)
    const [descGalImage5, setDescGalImage5] = useState(null)
    const [descGalImage6, setDescGalImage6] = useState(null)
    const [descGalImage7, setDescGalImage7] = useState(null)
    const [descGalImage8, setDescGalImage8] = useState(null)
    const [descGalImage9, setDescGalImage9] = useState(null)
    const [descGalImage10, setDescGalImage10] = useState(null)
    const [descGalImage11, setDescGalImage11] = useState(null)
    const [descGalImage12, setDescGalImage12] = useState(null)

    const [descOldImagePrez, setOldImagePrez] = useState(null)

    const [descOldImage1, setOldDescImage1] = useState(null)
    const [descOldImage2, setOldDescImage2] = useState(null)
    const [descOldImage3, setOldDescImage3] = useState(null)
    const [descOldImage4, setOldDescImage4] = useState(null)

    const [descOldGalImage1, setDescOldGalImage1] = useState(null)
    const [descOldGalImage2, setDescOldGalImage2] = useState(null)
    const [descOldGalImage3, setDescOldGalImage3] = useState(null)
    const [descOldGalImage4, setDescOldGalImage4] = useState(null)
    const [descOldGalImage5, setDescOldGalImage5] = useState(null)
    const [descOldGalImage6, setDescOldGalImage6] = useState(null)
    const [descOldGalImage7, setDescOldGalImage7] = useState(null)
    const [descOldGalImage8, setDescOldGalImage8] = useState(null)
    const [descOldGalImage9, setDescOldGalImage9] = useState(null)
    const [descOldGalImage10, setDescOldGalImage10] = useState(null)
    const [descOldGalImage11, setDescOldGalImage11] = useState(null)
    const [descOldGalImage12, setDescOldGalImage12] = useState(null)

    const [allMediasIds, setAllMediasIds] = useState()

    const [descVideo1, setDescVideo1] = useState("")
    const [descVideo2, setDescVideo2] = useState("")

    const [descImage1Name, setDescImage1Name] = useState("")
    const [descImage2Name, setDescImage2Name] = useState("")
    const [descImage3Name, setDescImage3Name] = useState("")
    const [descImage4Name, setDescImage4Name] = useState("")

    const [allLicences, setAllLicences] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [allLevels, setAllLevels] = useState([])

    const [slug, setSlug] = useState("")

    const [commission, setCommission] = useState(6)
    const [billeterie, setBilleterie] = useState(0)

    const [organisateur, setOrganisateur] = useState(null)

    const [needLicence, setNeedLicence] = useState(null)

    const [open, setOpen] = useState(false)

    const [textVehicle, setTextVehicle] = useState("")
    const [textDocument, setTextDocument] = useState("")
    const [textProtect, setTextProtect] = useState("")
    const [textFAQ, setTextFAQ] = useState("")
    const [textPartner, setTextPartner] = useState("")
    const [idDesc, setIdDesc] = useState(null)

    const [published, setPublished] = useState(0)
    const [booking, setBooking] = useState(0)

    const uploadFile = async () => {
        let image = [descImage1,
            descImage2,
            descImage3,
            descImage4,
            descGalImage1,
            descGalImage2,
            descGalImage3,
            descGalImage4,
            descGalImage5,
            descGalImage6,
            descGalImage7,
            descGalImage8,
            descGalImage9,
            descGalImage10,
            descGalImage11,
            descGalImage12,
            descImagePrez
        ]

        let publicIdList = [descOldImage1,
            descOldImage2,
            descOldImage3,
            descOldImage4,
            descOldGalImage1,
            descOldGalImage2,
            descOldGalImage3,
            descOldGalImage4,
            descOldGalImage5,
            descOldGalImage6,
            descOldGalImage7,
            descOldGalImage8,
            descOldGalImage9,
            descOldGalImage10,
            descOldGalImage11,
            descOldGalImage12,
            descOldImagePrez
        ]



        try {

            await Promise.all(image.map(async (elt, index) => {
                if (elt) {
                    let formDataImage = new FormData();
                    formDataImage.append("file", elt);
                    formDataImage.append("folder", "offers/images");
                    formDataImage.append("upload_preset", "zd2h6ivm")
                    let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataImage)
                    publicIdList[index] = res.data.public_id
                }
            }))

            return publicIdList

        } catch (e) {
            console.log(e)
        }

    };

    const EditHandler = async () => {
        uploadFile().then(res => {
            let imageHeader = res[res.length - 1]
            let imageList = res.slice(0, res.length - 1)
            console.log(imageHeader)
            let data = {
                circuitId: circuit,
                billeterie:billeterie,
                slug: slug,
                commission: commission,
                title: descTitle,
                modalite: descModalites,
                imagePrez: imageHeader,
                images: imageList,
                videos: [descVideo1, descVideo2],
                needLicence: needLicence,
                MediaIds: allMediasIds,
                programme: programme,
                vehiclesPrecision: descPrecisions,
                cardsPrecision: descCardsPrecisions,

                activityType: formTypeActivityValues,
                language: formLangValues,

                licencesIds: allLicences,
                documentsIds: allDocuments,
                levelsIds: allLevels,

                category: formCategory,

                VehicleTypeAcceptIds: formVehiculeTypesAcceptedValues,
                VehicleTypeRejectIds: formVehiculeTypesRefusedValues,

                organizationId: organisateur,
                cards: formCards,

                online: published,
                payment: booking,
                textVehicle,
                textDocument,
                textProtect,
                textFAQ,
                textPartner,
                idDesc,
                oldOffer: offers[0]
            }
            dispatch(updateOffers(offersId, data))
        })

    }

    const deleteHandler = () => {
        dispatch(deleteOffers(offersId))
    }

    const handleClose = () => {
        setOpen(false)
    }

    const removeFromArray = (id, array, setArray) => {
        let index = array.indexOf(id)
        let newArray = [...array]
        if (index > -1) {
            newArray.splice(index, 1)
        }
        setArray(newArray)
    }

    useEffect(() => {
        if (successDelete) {
            history('/offres')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getOffers(offersId))
            setTimeout(() => {
                setOpen(false)
                history('/offres')
                dispatch({type: OFFERS_UPDATE_RESET})
            }, 1000)
        }
    }, [successUpdate, successDelete, history])

    useEffect(() => {
        if (offersId !== offers[0]?.id.toString()) {
            dispatch(getOffers(offersId))
        } else {
            let offer = offers[0]
            setCircuit(offer.circuitId || "")
            setPublished(offer.online || 0)
            setBooking(offer.payment || 0)
            setBilleterie(offer.billeterie || 0)
            setOldImagePrez(offer.imagePrez || null)
            setTextVehicle(offer.textVehicle)
            setTextProtect(offer.textProtect)
            setTextDocument(offer.textDocument)
            setTextFAQ(offer.textFAQ)
            setTextPartner(offer.textPartner)
            setIdDesc(offer.idDesc)
            setDescPrecisions(offer.precisions_vehicles)
            setDescTarifsPrecisions(offer.precisions_tarifs)
            setDescCardsPrecisions(offer.precisions_card)
            setSlug(offer.slug)
            setProgramme(offer.programme)
            setDescTitle(offer.title)
            setDescModalites(offer.details)
            setOrganisateur(offer.RealOrgId)
            setDescImage1(offer.descImage1)
            setDescImage2(offer.descImage2)
            setDescImage3(offer.descImage3)
            setDescImage4(offer.descImage4)
            setDescVideo1(offer.descVideo1)
            setDescVideo2(offer.descVideo2)
            setAllMediasIds(offer.MediaIds)
            setCommission(offer.commission)
            setNeedLicence(offer.licences)

            let licencesSplit = offer.LicencesIds?.split("/")
            let documentsSplit = offer.DocumentsIds?.split("/")
            let levelsSplit = offer.LevelsIds?.split("/")
            let vehicleAcceptedSplit = offer.VehicleTypeAcceptIds?.split("/")
            let vehicleRefusedSplit = offer.VehicleTypeRejectIds?.split("/")

            let newLicenceFormValues = []
            licencesSplit?.forEach((licenceId) => {
                newLicenceFormValues.push(parseInt(licenceId))
            })
            setAllLicences(newLicenceFormValues)

            let newDocumentFormValues = []
            documentsSplit?.forEach((documentsId) => {
                newDocumentFormValues.push(parseInt(documentsId))
            })
            setAllDocuments(newDocumentFormValues)

            let newLevelFormValues = []
            levelsSplit?.forEach((levelId) => {
                newLevelFormValues.push(parseInt(levelId))
            })
            setAllLevels(newLevelFormValues)

            let newCardsForm = [[{title: "", price: "", calendar: ""}]]
            offer.cards.forEach((card, index) => {
                if (index === 0) {
                    newCardsForm.pop()
                }
                newCardsForm.push({title: card.name, price: card.price, calendar: ""})
            })
            setFormCards(newCardsForm)

            let newVehicleAcceptedFormValues = []
            newVehicleAcceptedFormValues.push({vehiculeTypesId: ""})
            vehicleAcceptedSplit?.forEach((VehiculeAcceptId, index) => {
                if (index === 0) {
                    newVehicleAcceptedFormValues.pop()
                }
                newVehicleAcceptedFormValues.push({vehiculeTypesId: VehiculeAcceptId})
            })
            setformVehiculeTypesAcceptedValues(newVehicleAcceptedFormValues)

            let newVehicleRefusedFormValues = []
            newVehicleRefusedFormValues.push({vehiculeTypesId: ""})
            vehicleRefusedSplit?.forEach((VehiculeRejectId, index) => {
                if (index === 0) {
                    newVehicleRefusedFormValues.pop()
                }
                newVehicleRefusedFormValues.push({vehiculeTypesId: VehiculeRejectId})
            })
            setformVehiculeTypesRefusedValues(newVehicleRefusedFormValues)

            let newCategoryForm = [category]
            offer.category.forEach((categoryData, index) => {
                if (index === 0) {
                    newCategoryForm.pop()
                }
                let variantArray = []
                categoryData.variants.forEach((variantData) => {
                    variantArray.push(
                        {
                            id: variantData.variantId,
                            title: variantData.title,
                            price: variantData.price,
                            minPrice: variantData.minPrice,
                            stock: variantData.stock,
                            cardId: variantData.cardId,
                            startDate: variantData.startDate,
                            endDate: variantData.endDate,
                            maxQuantity: variantData.maxQuantity,
                            tva: variantData.tva,
                            needVehiclePresentation: variantData.needVehiclePresentation
                        })
                })
                newCategoryForm.push({
                    id: categoryData.categoryId,
                    title: categoryData.title,
                    description: categoryData.description,
                    image: categoryData.image,
                    option: categoryData.isOption,
                    variants: variantArray
                })
            })
            setFormCategory(newCategoryForm)

            let allType = []
            offer.type.forEach(elt => {
                allType.push({id: elt.activityId})
            })
            setFormTypeActivityValues(allType)

            let allLang = []
            offer.lang.forEach(elt => {
                allLang.push({id: elt.langId})
            })
            setFormLangValues(allLang)
        }
    }, [dispatch, offersId, offers])

    const getLanguages = async () => {
        const languages = await listLanguage()
        setListLang(languages)
    }

    useEffect(() => {
        dispatch(listActivityTypes())
        dispatch(listLicences())
        dispatch(listLevels())
        dispatch(listCircuits())
        dispatch(listVehicleTypes())
        dispatch(listDocuments())
        dispatch(listOrganisations())
        getLanguages()
    }, [dispatch])


    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'offre a bien été modifiée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Offres</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button  className={tabs === 1 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(1)}>
                    <TextSnippetIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Description</p>
                </button>
                <button  className={tabs === 2 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(2)}>
                    <LocationOnIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Localisation</p>
                </button>
                <button  className={tabs === 3 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(3)}>
                    <DriveEtaIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Véhicules</p>
                </button>
                <button  className={tabs === 4 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(4)}>
                    <PermMediaIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Médias</p>
                </button>
                <button  className={tabs === 5 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(5)}>
                    <LocalOfferIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Member Cards</p>
                </button>
                <button  className={tabs === 6 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(6)}>
                    <LocalOfferIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Catégorie</p>
                </button>
                <button  className={tabs === 7 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(7)}>
                    <RecentActorsIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Licences</p>
                </button>
                <button  className={tabs === 8 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(8)}>
                    <DescriptionIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Documents à présenter</p>
                </button>
                <button  className={tabs === 9 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(9)}>
                    <DescriptionIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Textes</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    {tabs === 1 ? <Description
                        published={published}
                        setPublished={setPublished}
                        booking={booking}
                        setBooking={setBooking}
                        descTitle={descTitle} setDescTitle={setDescTitle}
                        descModalites={descModalites} setDescModalites={setDescModalites}
                        Levels={list_Levels}
                        organisations={organisations}
                        slug={slug}
                        listLang={listLang}
                        activityList={activityTypesSimple}
                        formLangValues={formLangValues}
                        setFormLangValues={setFormLangValues}
                        formTypeActivityValues={formTypeActivityValues}
                        setFormTypeActivityValues={setFormTypeActivityValues}
                        setBilleterie={setBilleterie}
                        billeterie={billeterie}
                        commission={commission}
                        setCommission={setCommission}
                        setSlug={setSlug}
                        organisateur={organisateur}
                        setOrganisateur={setOrganisateur}
                        allLevels={allLevels} setAllLevels={setAllLevels}
                        programme={programme} setProgramme={setProgramme}
                        removeArray={removeFromArray}
                    /> : null}
                    {tabs === 2 ? <Deroulement
                        circuit={circuit} setCircuit={setCircuit}
                        circuits={circuits}
                    /> : null}
                    {tabs === 3 ? <Vehicules
                        descPrecisions={descPrecisions} setDescPrecisions={setDescPrecisions}
                        vehiculeTypes={vehiculeTypes}
                        formVehiculeTypesAcceptedValues={formVehiculeTypesAcceptedValues}
                        formVehiculeTypesRefusedValues={formVehiculeTypesRefusedValues}
                        setformVehiculeTypesAcceptedValues={setformVehiculeTypesAcceptedValues}
                        setformVehiculeTypesRefusedValues={setformVehiculeTypesRefusedValues}
                    /> : null}
                    {tabs === 4 ? <Medias
                        descImagePrez={descImagePrez} setDescImagePrez={setDescImagePrez}
                        descImage1={descImage1} setDescImage1={setDescImage1}
                        descImage2={descImage2} setDescImage2={setDescImage2}
                        descImage3={descImage3} setDescImage3={setDescImage3}
                        descImage4={descImage4} setDescImage4={setDescImage4}
                        descVideo1={descVideo1} setDescVideo1={setDescVideo1}
                        descVideo2={descVideo2} setDescVideo2={setDescVideo2}
                        descGalImage1={descGalImage1} setDescGalImage1={setDescGalImage1}
                        descGalImage2={descGalImage2} setDescGalImage2={setDescGalImage2}
                        descGalImage3={descGalImage3} setDescGalImage3={setDescGalImage3}
                        descGalImage4={descGalImage4} setDescGalImage4={setDescGalImage4}
                        descGalImage5={descGalImage5} setDescGalImage5={setDescGalImage5}
                        descGalImage6={descGalImage6} setDescGalImage6={setDescGalImage6}
                        descGalImage7={descGalImage7} setDescGalImage7={setDescGalImage7}
                        descGalImage8={descGalImage8} setDescGalImage8={setDescGalImage8}
                        descGalImage9={descGalImage9} setDescGalImage9={setDescGalImage9}
                        descGalImage10={descGalImage10} setDescGalImage10={setDescGalImage10}
                        descGalImage11={descGalImage11} setDescGalImage11={setDescGalImage11}
                        descGalImage12={descGalImage12} setDescGalImage12={setDescGalImage12}
                    /> : null}
                    {tabs === 5 ? <MemberCard formCards={formCards} setFormCards={setFormCards} descCardsPrecisions={descCardsPrecisions}
                                              setDescCardsPrecisions={setDescCardsPrecisions}/> : null}
                    {tabs === 6 ? <CategoryOffers variant={variant} category={category} formCategory={formCategory} setFormCategory={setFormCategory}
                    /> : null}
                    {tabs === 7 ? <Licences
                        Licences={list_Licences}
                        allLicences={allLicences} setAllLicences={setAllLicences}
                        removeArray={removeFromArray}
                        needLicence={needLicence} setNeedLicence={setNeedLicence}
                    /> : null}

                    {tabs === 8 ? <Documents
                        documents={documents} allDocuments={allDocuments} setAllDocuments={setAllDocuments}
                        removeArray={removeFromArray}
                    /> : null}

                    {tabs === 9 ? <Textes
                        textVehicle={textVehicle}
                        setTextVehicle={setTextVehicle}
                        textDocument={textDocument}
                        setTextDocument={setTextDocument}
                        textProtect={textProtect}
                        setTextProtect={setTextProtect}
                        textFAQ={textFAQ}
                        setTextFAQ={setTextFAQ}
                        textPartner={textPartner}
                        setTextPartner={setTextPartner}
                    /> : null}
                </div>
            </div>
        </div>
    )
}

export default EditOffer
