import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import '../../../../assets/css/style.css'
import axios from "../../../../axios";
import {useDispatch, useSelector} from "react-redux";
import {createOffers} from "../../../../actions/offersActions";
import {listCircuits} from "../../../../actions/circuitsActions";
import {listLicences} from "../../../../actions/licencesActions";
import {listVehicleTypes} from "../../../../actions/vehiculeTypeAction";
import {listLevels} from "../../../../actions/levelsActions";
import {listDocuments} from "../../../../actions/documentsActions";
import {useNavigate} from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import DescriptionIcon from '@mui/icons-material/Description';
import Deroulement from '../../../../components/offers/Deroulement'
import Description from '../../../../components/offers/Description';
import Documents from '../../../../components/offers/Documents';
import Licences from '../../../../components/offers/Licences';
import Medias from '../../../../components/offers/Medias';
import Vehicules from '../../../../components/offers/Vehicules';
import { OFFERS_CREATE_RESET } from '../../../../constants/offersConstants';
import CategoryOffers from "../../../../components/offers/Categorie";
import MemberCard from "../../../../components/offers/MemberCard";
import {listPilots} from "../../../../actions/pilotsActions";
import {listOrganisations} from "../../../../actions/organisationsActions";
import Textes from "../../../../components/offers/Textes";
import {listLanguage} from "../../../../actions/languageActions";
import {listActivityTypes} from "../../../../actions/activityTypesActions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewOffer = (props) => {
    const [tabs, setTabs] = useState(1)

    let history = useNavigate()
    const dispatch = useDispatch()

    const variant = {id: null, title: "", price: "", minPrice: "", stock: 0, cardId: "", startDate: "", endDate: "", maxQuantity: 1, needVehiclePresentation: false}

    const category = {
        id: null,
        title: "",
        description: "",
        image: "",
        option: false,
        variants: [variant]
    }

    let offersCreate = useSelector(state => state.offersCreate)
    const {success: successCreate, error: errorCreate} = offersCreate

    let circuitsList = useSelector(state => state.circuitsList)
    const {circuits} = circuitsList

    let vehiculeTypesList = useSelector(state => state.vehiculeTypesList)
    const {vehiculeTypes} = vehiculeTypesList

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let licencesList = useSelector(state => state.licencesList)
    const {Licences: list_Licences} = licencesList

    let levelsList = useSelector(state => state.levelsList)
    const {levels: list_Levels} = levelsList

    let documentsList = useSelector(state => state.documentsList)
    const {documents} = documentsList

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {ActivityTypes: activityTypesSimple} = activityTypesList

    const [formVehiculeTypesAcceptedValues, setformVehiculeTypesAcceptedValues] = useState([{vehiculeTypesId: ""}])
    const [formVehiculeTypesRefusedValues, setformVehiculeTypesRefusedValues] = useState([{vehiculeTypesId: ""}])

    const [formCategory, setFormCategory] = useState([category])

    const [formCards, setFormCards] = useState([{title: "", price: "", calendar: ""}])

    const [formTypeActivityValues, setFormTypeActivityValues] = useState([{id: null}])
    const [formLangValues, setFormLangValues] = useState([{id: null}])
    const [listLang, setListLang] = useState([])

    const [circuit, setCircuit] = useState("")

    const [descPrecisions, setDescPrecisions] = useState("")
    const [descCardsPrecisions, setDescCardsPrecisions] = useState("")

    const [descTitle, setDescTitle] = useState("")
    const [descModalites, setDescModalites] = useState("Pas d'informations")

    const [descImagePrez, setDescImagePrez] = useState(null)

    const [descImage1, setDescImage1] = useState("")
    const [descImage2, setDescImage2] = useState("")
    const [descImage3, setDescImage3] = useState("")
    const [descImage4, setDescImage4] = useState("")
    const [descVideo1, setDescVideo1] = useState("")
    const [descVideo2, setDescVideo2] = useState("")

    const [descGalImage1, setDescGalImage1] = useState(null)
    const [descGalImage2, setDescGalImage2] = useState(null)
    const [descGalImage3, setDescGalImage3] = useState(null)
    const [descGalImage4, setDescGalImage4] = useState(null)
    const [descGalImage5, setDescGalImage5] = useState(null)
    const [descGalImage6, setDescGalImage6] = useState(null)
    const [descGalImage7, setDescGalImage7] = useState(null)
    const [descGalImage8, setDescGalImage8] = useState(null)
    const [descGalImage9, setDescGalImage9] = useState(null)
    const [descGalImage10, setDescGalImage10] = useState(null)
    const [descGalImage11, setDescGalImage11] = useState(null)
    const [descGalImage12, setDescGalImage12] = useState(null)

    const [organisateur, setOrganisateur] = useState(null)

    const [allLicences, setAllLicences] = useState([])
    const [allDocuments, setAllDocuments] = useState([])
    const [allLevels, setAllLevels] = useState([])

    const [needLicence, setNeedLicence] = useState(null)

    const [slug, setSlug] = useState("")
    const [programme, setProgramme] = useState("Pas d'informations")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [commission, setCommission] = useState(6)
    const [billeterie, setBilleterie] = useState(0)

    const [textVehicle, setTextVehicle] = useState("Pas d'informations")
    const [textDocument, setTextDocument] = useState("Pas d'informations")
    const [textProtect, setTextProtect] = useState("Pas d'informations")
    const [textFAQ, setTextFAQ] = useState("Pas d'informations")
    const [textPartner, setTextPartner] = useState("Pas d'informations")

    const [published, setPublished] = useState(0)
    const [booking, setBooking] = useState(0)

    const uploadFile = async () => {
        let image = [descImage1,
            descImage2,
            descImage3,
            descImage4,
            descGalImage1,
            descGalImage2,
            descGalImage3,
            descGalImage4,
            descGalImage5,
            descGalImage6,
            descGalImage7,
            descGalImage8,
            descGalImage9,
            descGalImage10,
            descGalImage11,
            descGalImage12,
            descImagePrez
        ]

        let publicIdList = [null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null]

        try {

            await Promise.all(image.map(async (elt, index) => {
                if (elt) {
                    let formDataImage = new FormData();
                    formDataImage.append("file", elt);
                    formDataImage.append("folder", "offers/images");
                    formDataImage.append("upload_preset", "zd2h6ivm")
                    let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataImage)
                    publicIdList[index] = res.data.public_id
                }
            }))

            return publicIdList

        } catch (e) {
            console.log(e)
        }

    };


    const AddHandler = async () => {
        uploadFile().then(res => {
            console.log(res)

            let imageHeader = res[res.length - 1]
            let imageList = res.slice(0, res.length - 1)
            let data = {
                circuitId: circuit,
                commission: commission,
                billeterie: billeterie,
                online: published,
                payment: booking,
                imagePrez: imageHeader,
                slug: slug,
                textVehicle: textVehicle,
                textDocument: textDocument,
                textProtect: textProtect,
                textFAQ: textFAQ,
                textPartner: textPartner,

                title: descTitle,
                modalite: descModalites,
                images: imageList,
                videos: [descVideo1, descVideo2],
                needLicence: needLicence,

                vehiclesPrecision: descPrecisions,
                cardsPrecision: descCardsPrecisions,

                licencesIds: allLicences,
                documentsIds: allDocuments,
                levelsIds: allLevels,

                category: formCategory,

                activityType: formTypeActivityValues,
                language: formLangValues,

                programme: programme,
                vehicleTypeAccepted: formVehiculeTypesAcceptedValues,
                vehicleTypeRefused: formVehiculeTypesRefusedValues,

                organizationId: organisateur,
                cards: formCards
            }
            if (data.name !== "")
                dispatch(createOffers(data))
        })

    }


    const handleClose = () => {
        setOpen(false)
    }

    const removeFromArray = (id, array, setArray) => {
        let index = array.indexOf(id)
        let newArray = [...array]
        if (index > -1) {
            newArray.splice(index, 1)
        }
        setArray(newArray)
    }


    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/offres')
                dispatch({type: OFFERS_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])


    const getLanguages = async () => {
        const languages = await listLanguage()
        setListLang(languages)
    }

    useEffect(() => {
        dispatch(listActivityTypes())
        dispatch(listLicences())
        dispatch(listLevels())
        dispatch(listCircuits())
        dispatch(listVehicleTypes())
        dispatch(listDocuments())
        dispatch(listOrganisations())
        getLanguages()
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    L'offre a bien été ajoutée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Offres"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Offre</h2>
                <button className="modify_button" onClick={AddHandler}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}} >Enregistrer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button  className={tabs === 1 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(1)}>
                    <TextSnippetIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Description</p>
                </button>
                <button  className={tabs === 2 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(2)}>
                    <LocationOnIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Localisation</p>
                </button>
                <button  className={tabs === 3 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(3)}>
                    <DriveEtaIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Véhicules</p>
                </button>
                <button  className={tabs === 4 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(4)}>
                    <PermMediaIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Médias</p>
                </button>
                <button  className={tabs === 5 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(5)}>
                    <LocalOfferIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Member Cards</p>
                </button>
                <button  className={tabs === 6 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(6)}>
                    <LocalOfferIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Catégorie</p>
                </button>
                <button  className={tabs === 7 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(7)}>
                    <RecentActorsIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Licences</p>
                </button>
                <button  className={tabs === 8 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(8)}>
                    <DescriptionIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Documents à présenter</p>
                </button>
                <button  className={tabs === 9 ? "buttonTabs showActiveBackground" : "buttonTabs"} onClick={(e) => setTabs(9)}>
                    <DescriptionIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Textes</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    {tabs === 2 ? <Deroulement
                        circuit={circuit} setCircuit={setCircuit}
                        circuits={circuits}
                    /> : null}
                    {tabs === 3 ? <Vehicules
                        descPrecisions={descPrecisions} setDescPrecisions={setDescPrecisions}
                        vehiculeTypes={vehiculeTypes}
                        setformVehiculeTypesAcceptedValues={setformVehiculeTypesAcceptedValues}
                        setformVehiculeTypesRefusedValues={setformVehiculeTypesRefusedValues}
                        formVehiculeTypesAcceptedValues={formVehiculeTypesAcceptedValues}
                        formVehiculeTypesRefusedValues={formVehiculeTypesRefusedValues}
                    /> : null}
                    {tabs === 1 ? <Description
                        published={published}
                        setPublished={setPublished}
                        booking={booking}
                        setBooking={setBooking}
                        slug={slug}
                        setSlug={setSlug}
                        setBilleterie={setBilleterie}
                        billeterie={billeterie}
                        listLang={listLang}
                        activityList={activityTypesSimple}
                        formLangValues={formLangValues}
                        setFormLangValues={setFormLangValues}
                        formTypeActivityValues={formTypeActivityValues}
                        setFormTypeActivityValues={setFormTypeActivityValues}
                        descTitle={descTitle} setDescTitle={setDescTitle}
                        descModalites={descModalites} setDescModalites={setDescModalites}
                        Levels={list_Levels}
                        commission={commission}
                        setCommission={setCommission}
                        organisations={organisations}
                        organisateur={organisateur}
                        setOrganisateur={setOrganisateur}
                        allLevels={allLevels} setAllLevels={setAllLevels}
                        programme={programme} setProgramme={setProgramme}
                        removeArray={removeFromArray}
                    /> : null}
                    {tabs === 4 ? <Medias
                        descImagePrez={descImagePrez} setDescImagePrez={setDescImagePrez}
                        descImage1={descImage1} setDescImage1={setDescImage1}
                        descImage2={descImage2} setDescImage2={setDescImage2}
                        descImage3={descImage3} setDescImage3={setDescImage3}
                        descImage4={descImage4} setDescImage4={setDescImage4}
                        descVideo1={descVideo1} setDescVideo1={setDescVideo1}
                        descVideo2={descVideo2} setDescVideo2={setDescVideo2}
                        descGalImage1={descGalImage1} setDescGalImage1={setDescGalImage1}
                        descGalImage2={descGalImage2} setDescGalImage2={setDescGalImage2}
                        descGalImage3={descGalImage3} setDescGalImage3={setDescGalImage3}
                        descGalImage4={descGalImage4} setDescGalImage4={setDescGalImage4}
                        descGalImage5={descGalImage5} setDescGalImage5={setDescGalImage5}
                        descGalImage6={descGalImage6} setDescGalImage6={setDescGalImage6}
                        descGalImage7={descGalImage7} setDescGalImage7={setDescGalImage7}
                        descGalImage8={descGalImage8} setDescGalImage8={setDescGalImage8}
                        descGalImage9={descGalImage9} setDescGalImage9={setDescGalImage9}
                        descGalImage10={descGalImage10} setDescGalImage10={setDescGalImage10}
                        descGalImage11={descGalImage11} setDescGalImage11={setDescGalImage11}
                        descGalImage12={descGalImage12} setDescGalImage12={setDescGalImage12}
                    /> : null}
                    {tabs === 5 ? <MemberCard formCards={formCards} setFormCards={setFormCards} descCardsPrecisions={descCardsPrecisions}
                    setDescCardsPrecisions={setDescCardsPrecisions}/> : null}
                    {tabs === 6 ? <CategoryOffers variant={variant} category={category} formCategory={formCategory} setFormCategory={setFormCategory}
                    /> : null}
                    {tabs === 7 ? <Licences
                        Licences={list_Licences}
                        allLicences={allLicences} setAllLicences={setAllLicences}
                        removeArray={removeFromArray}
                        needLicence={needLicence} setNeedLicence={setNeedLicence}
                    /> : null}

                    {tabs === 8 ? <Documents
                        documents={documents} allDocuments={allDocuments} setAllDocuments={setAllDocuments}
                        removeArray={removeFromArray}
                    /> : null}

                    {tabs === 9 ? <Textes
                        textVehicle={textVehicle}
                        setTextVehicle={setTextVehicle}
                        textDocument={textDocument}
                        setTextDocument={setTextDocument}
                        textProtect={textProtect}
                        setTextProtect={setTextProtect}
                        textFAQ={textFAQ}
                        setTextFAQ={setTextFAQ}
                        textPartner={textPartner}
                        setTextPartner={setTextPartner}
                    /> : null}

                </div>
            </div>
        </div>
    )
}

export default NewOffer
