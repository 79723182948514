export const HOMOLOGATIONS_LIST_REQUEST = "HOMOLOGATIONS_LIST_REQUEST"
export const HOMOLOGATIONS_LIST_SUCCESS = "HOMOLOGATIONS_LIST_SUCCESS"
export const HOMOLOGATIONS_LIST_FAIL = "HOMOLOGATIONS_LIST_FAIL"

export const HOMOLOGATIONS_DETAILS_REQUEST = "HOMOLOGATIONS_DETAILS_REQUEST"
export const HOMOLOGATIONS_DETAILS_SUCCESS = "HOMOLOGATIONS_DETAILS_SUCCESS"
export const HOMOLOGATIONS_DETAILS_FAIL = "HOMOLOGATIONS_DETAILS_FAIL"

export const HOMOLOGATIONS_CREATE_REQUEST = "HOMOLOGATIONS_CREATE_REQUEST"
export const HOMOLOGATIONS_CREATE_SUCCESS = "HOMOLOGATIONS_CREATE_SUCCESS"
export const HOMOLOGATIONS_CREATE_FAIL = "HOMOLOGATIONS_CREATE_FAIL"
export const HOMOLOGATIONS_CREATE_RESET = "HOMOLOGATIONS_CREATE_RESET"

export const HOMOLOGATIONS_DELETE_REQUEST = "HOMOLOGATIONS_DELETE_REQUEST"
export const HOMOLOGATIONS_DELETE_SUCCESS = "HOMOLOGATIONS_DELETE_SUCCESS"
export const HOMOLOGATIONS_DELETE_FAIL = "HOMOLOGATIONS_DELETE_FAIL"

export const HOMOLOGATIONS_UPDATE_REQUEST = "HOMOLOGATIONS_UPDATE_REQUEST"
export const HOMOLOGATIONS_UPDATE_SUCCESS = "HOMOLOGATIONS_UPDATE_SUCCESS"
export const HOMOLOGATIONS_UPDATE_FAIL = "HOMOLOGATIONS_UPDATE_FAIL"
export const HOMOLOGATIONS_UPDATE_RESET = "HOMOLOGATIONS_UPDATE_RESET"
