import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'

const Amenagements = (props) => {

    return (
        <div className="amenagements">
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div className="public">
                    <p className="first-subTitles">Confort</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities) => {
                            if (facilities.category === "Confort" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Confort" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null

                        })}
                    </div>
                    <p className="subTitles">Ambiance</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities, index) => {
                            if (facilities.category === "Ambiance" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Ambiance" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                    <p className="subTitles">Restauration</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities, index) => {
                            if (facilities.category === "Restauration" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Restauration" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                    <p className="subTitles">Parking</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities, index) => {
                            if (facilities.category === "Parking" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Parking" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                </div>
                <div className="piste">
                    <p className="first-subTitles">Course</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities, index) => {
                            if (facilities.category === "Course" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Course" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                    <p className="subTitles">Sécurité</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities, index) => {
                            if (facilities.category === "Sécurité" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Sécurité" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                    <p className="subTitles">Entretien</p>
                    <div className="subPres">
                        {props.Facilities?.map((facilities) => {
                            if (facilities.category === "Entretien" && props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button actif" onClick={
                                        () => {
                                            props.removeFacilities(facilities.id, props.allFacilities, props.setAllFacilities)
                                        }}>
                                        {facilities.name}
                                    </button>)
                            } else if (facilities.category === "Entretien" && !props.allFacilities.includes(facilities.id)) {
                                return (
                                    <button className="am-button"
                                            onClick={() => props.setAllFacilities([...props.allFacilities, facilities.id])}>
                                        {facilities.name}
                                    </button>)
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Amenagements