import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {
    deleteChampionnats,
    getChampionnats,
    updateChampionnats
} from "../../../../actions/championnatsActions";
import {listOrganisations} from "../../../../actions/organisationsActions";
import {listDisciplines} from "../../../../actions/disciplinesActions";
import axios from "../../../../axios";
import {CHAMPIONNATS_UPDATE_RESET} from '../../../../constants/championnatsConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditChampionnat = (props) => {
    const {id: championnatID} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let championnatsDetails = useSelector(state => state.championnatsDetails)
    const {Championnats} = championnatsDetails

    let championnatsUpdate = useSelector(state => state.championnatsUpdate)
    const {error: errorUpdate, success: successUpdate} = championnatsUpdate

    let championnatsDelete = useSelector(state => state.championnatsDelete)
    const {success: successDelete} = championnatsDelete


    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let disciplinesList = useSelector(state => state.disciplinesList)
    const {Disciplines} = disciplinesList


    const [name, setName] = useState("")
    const [champProfilePicture, setChampProfilePicture] = useState("")
    const [oldChampProfilePictureName, setOldChampProfilePictureName] = useState("")
    const [organizationSelected, setOrganizationSelected] = useState("")
    const [federationSelected, setFederationSelected] = useState("")
    const [disciplineSelected, setDisciplineSelected] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    const saveFile = async (e) => {
        setChampProfilePicture(e.target.files[0]);

    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        let publicIdPP = oldChampProfilePictureName

        try {
            if (champProfilePicture) {
                formDataProfile.append("file", champProfilePicture);
                formDataProfile.append("folder", "championship");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdPP = res.data.public_id
            }

            return [publicIdPP]
        } catch (ex) {
            console.log(ex);
        }

    };


    const EditHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: name,
                profile_picture: res[0],
                disciplines_id: disciplineSelected,
                organization_id: organizationSelected,
                federation_id: federationSelected,
            }
            dispatch(updateChampionnats(data, championnatID))
        })
    }

    const deleteHandler = () => {
        dispatch(deleteChampionnats(championnatID))
    }

    useEffect(() => {
        dispatch(listOrganisations())
        dispatch(listDisciplines())
    }, [dispatch])

    useEffect(() => {
        if (successDelete) {
            history('/championnats')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getChampionnats(championnatID))
            dispatch({type: CHAMPIONNATS_UPDATE_RESET})
            setTimeout(() => {
                history('/championnats')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (championnatID !== Championnats[0]?.id.toString()) {
            dispatch(getChampionnats(championnatID))
        } else {
            setName(Championnats[0].name)
            setOldChampProfilePictureName(Championnats[0].profile_picture)
            setFederationSelected(Championnats[0].federation_id)
            setDisciplineSelected(Championnats[0].disciplines_id)
            setOrganizationSelected(Championnats[0].organization_id)
        }
    }, [dispatch, championnatID, Championnats, successUpdate, errorUpdate, successDelete, history])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le championnat a bien été modifié !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Championnats</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                            />
                            <p className="label-2">Image de profil</p>
                            <input className="input" placeholder="Profile Picture"
                                   type="file"
                                   onChange={e => saveFile(e)}
                            />
                            <div>
                                <p className="label-2">Organisation</p>
                                <select className="state" value={organizationSelected}
                                        onChange={e => setOrganizationSelected(e.target.value)}>
                                    <option value="">-- Organisation --</option>
                                    {organisations.map(orga => {
                                        return <option key={orga.id}
                                                       value={orga.id}>{orga.name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <p className="label-2">Discipline</p>
                                <select className="state" value={disciplineSelected}
                                        onChange={e => setDisciplineSelected(e.target.value)}>
                                    <option value="">-- Disciplines --</option>
                                    {Disciplines.map(d => {
                                        return <option key={d.id} value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <p className="label-2">Fédération</p>
                                <select className="state" value={federationSelected}
                                        onChange={e => setFederationSelected(e.target.value)}>
                                    <option value="">-- Fédération --</option>
                                    {organisations.map(orga => {
                                        if (orga.type === 'Fédération') {
                                            return <option key={orga.id}
                                                           value={orga.id}>{orga.name}</option>
                                        }
                                        return null
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditChampionnat
