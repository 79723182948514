import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import {CountryDropdown} from 'react-country-region-selector';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import AppleIcon from "@material-ui/icons/Apple";

const Adresse = (props) => {

    return (
        <div className="adresses">
            <div className="subAdress">
                <div className="lieu">
                    <p className="label">Adresse</p>
                    <div className="name">
                        <RoomIcon className="name-icon"/>
                        <input className="input" placeholder="Adresse"
                               value={props.address}
                               onChange={e => props.setAddress(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Complément d'adresse</p>
                    <div className="name">
                        <RoomIcon className="false-icon"/>
                        <input className="input" placeholder="Complément d'adresse"
                               value={props.complement}
                               onChange={e => props.setComplement(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Code Postal</p>
                    <div className="name">
                        <RoomIcon className="false-icon"/>
                        <input className="input" placeholder="Code Postal"
                               value={props.code_post}
                               onChange={e => props.setCode_post(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Ville</p>
                    <div className="name">
                        <RoomIcon className="false-icon"/>
                        <input className="input" placeholder="Ville"
                               value={props.ville}
                               onChange={e => props.setVille(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Pays</p>
                    <div className="name">
                        <RoomIcon className="false-icon"/>
                        <CountryDropdown className="state-country"
                                         value={props.pays}
                                         onChange={(e) => props.setPays(e)}/>
                    </div>
                </div>
                <div className="gps">
                    <p className="label">Coordonnées GPS</p>
                    <div className="name">
                        <RoomIcon className="name-icon"/>
                        <input className="input" placeholder="Coordonnées GPS"
                               value={props.gps}
                               onChange={e => props.setGps(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">URL Google Map</p>
                    <div className="name">
                        <GpsFixedIcon className="name-icon"/>
                        <input className="input" placeholder="URL Google Map"
                               value={props.url_gmap}
                               onChange={e => props.setUrl_gmap(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">URL Waze</p>
                    <div className="name">
                        <PersonIcon className="name-icon"/>
                        <input className="input" placeholder="URL Waze"
                               value={props.url_waze}
                               onChange={e => props.setUrl_waze(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">URL Plans</p>
                    <div className="name">
                        <AppleIcon className="name-icon"/>
                        <input className="input" placeholder="URL Plans (Apple)"
                               value={props.url_plans}
                               onChange={e => props.setUrl_plans(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Adresse