import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {MuiThemeProvider} from "@material-ui/core";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listCircuits} from "../../../actions/circuitsActions";
import ArrayValueUI from "../../../components/ui/arrayValue";
import { Outlet } from "react-router-dom";
import {ThemeProvider} from "@mui/styles";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Circuits = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let circuitsList = useSelector(state => state.circuitsList)
    const {loading, error, circuits} = circuitsList

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`circuits/edit/${cell.rowData[0]}`} >{val}</a>
                    )
                },
            }
        },
        {
            label: "Propriétaire",
            name: "organization",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Espace",
            name: "space",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Sol",
            name: "floor",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Homologations",
            name: "homologations",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val){
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas d'homologations</p>
                    }
                },
            }
        },
        {
            label: "Code Postal",
            name: "zipcode",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Département",
            name: "department",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Région",
            name: "region",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Pays",
            name: "country",
            options: {
                filter: true,
                sort: true,
            }
        },{
            label: "Etat",
            name: "etat",
            options: {
                filter: true,
                sort: true,
            }
        },{
            label: "Status",
            name: "status",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Licences",
            name: "licences",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val){
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas de Licences</p>
                    }
                },
            }
        },
        {
            label: "Segments",
            name: "segments",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val){
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas de segments</p>
                    }
                },
            }
        },
        {
            label: "Véhicules",
            name: "vehicle",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val){
                        let valSplit = val.split("/");
                        return <ArrayValueUI data={valSplit}/>
                    } else {
                        return <p>Pas de véhicule</p>
                    }
                },
            }
        },
    ]

    const options = {
        filter: true,
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listCircuits())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Circuits</h2>
                <button className="new_button" onClick={() => history('/circuits/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ? <p style={{color: "white"}}>{error}</p> : (
                    <MUIDataTable
                        data={circuits}
                        columns={columns}
                        options={options}
                    />
                )}
            </div>
            <Outlet />
        </ThemeProvider>
    )
}

export default Circuits
