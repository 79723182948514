import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import MuiAlert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {InfoRounded} from "@material-ui/icons";
import {listOrganisations} from "../../../../actions/organisationsActions";
import {createLicences} from "../../../../actions/licencesActions";
import Snackbar from "@mui/material/Snackbar";
import {useNavigate} from "react-router-dom";
import {listActivityTypes} from "../../../../actions/activityTypesActions";
import { LICENCES_CREATE_RESET } from '../../../../constants/licencesConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewLicences = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let licencesCreate = useSelector(state => state.licencesCreate)
    const {success: successCreate, error: errorCreate} = licencesCreate

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    const [licenceName, setLicenceName] = useState("")
    const [licenceCode, setLicenceCode] = useState("")
    const [licenceOrg, setLicenceOrg] = useState("")
    const [licencePrice, setLicencePrice] = useState("")
    const [licenceDuree, setLicenceDuree] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formActivityValues, setFormActivityValues] = useState([{activityId: ""}])

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {ActivityTypes} = activityTypesList

    let handleActivityChange = (i, e) => {
        let newFormValues = [...formActivityValues];
        newFormValues[i]["activityId"] = e.target.value;
        setFormActivityValues(newFormValues);
    }

    let addActivityFormFields = () => {
        setFormActivityValues([...formActivityValues, {activityId: ""}])
    }

    let removeActivityFormFields = (i) => {
        let newFormValues = [...formActivityValues];
        newFormValues.splice(i, 1);
        setFormActivityValues(newFormValues)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const AddHandler = () => {
        let data = {
            code: licenceCode,
            name: licenceName,
            duree: licenceDuree,
            prix: licencePrice,
            organizationId: licenceOrg,
            activityTypesIds: formActivityValues
        }
        if (data.name !== "")
            dispatch(createLicences(data))
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/licences')
                dispatch({type: LICENCES_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    useEffect(() => {
        dispatch(listActivityTypes())
        dispatch(listOrganisations())
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    La licence a bien été ajoutée !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Licences"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Licences</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div className="circuitMenus">
                <div className="subInfos">
                    <div className="infos">
                        <p className="label-2">Nom de la licence</p>
                        <input className="input" placeholder="Nom de la licence"
                               value={licenceName}
                               onChange={e => setLicenceName(e.target.value)}
                        />
                        <p className="label-2">Code</p>
                        <input className="input" placeholder="Code"
                               value={licenceCode}
                               onChange={e => setLicenceCode(e.target.value)}
                        />
                        <p className="label-2">Activités</p>
                        {formActivityValues.map((element, index) => (
                            <div className="name" key={`${element}_${index}`}>
                                <select className="state" value={element.activityId}
                                        onChange={e => handleActivityChange(index, e)}>
                                    <option className="sub" value="">-- Choisissez une activité --</option>
                                    {ActivityTypes.map(at => {
                                        return <option key={at.id} value={at.id}>{at.name}</option>
                                    })}
                                </select>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeActivityFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button" onClick={() => addActivityFormFields()}>Add</button>
                        <p className="label-2">Organisations</p>
                        <select className="state"
                                value={licenceOrg}
                                label={"Organisations"}
                                onChange={e => setLicenceOrg(e.target.value)}
                        >
                            <option className="sub" value="">-- Choisissez une Organisation --</option>
                            {organisations.map(orga => {
                                return <option key={orga.id} value={orga.id}>{orga.name}</option>
                            })}
                        </select>
                        <p className="label-2">Prix</p>
                        <input className="input" placeholder="Prix"
                               value={licencePrice}
                               onChange={e => setLicencePrice(e.target.value)}
                        />
                        <p className="label-2">Durée</p>
                        <textarea className="input" placeholder="Durée"
                                  value={licenceDuree}
                                  onChange={e => setLicenceDuree(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewLicences
