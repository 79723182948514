import axios from "../axios"

import {
    LEVELS_CREATE_FAIL,
    LEVELS_CREATE_REQUEST,
    LEVELS_CREATE_SUCCESS,
    LEVELS_DELETE_FAIL,
    LEVELS_DELETE_REQUEST,
    LEVELS_DELETE_SUCCESS,
    LEVELS_DETAILS_FAIL,
    LEVELS_DETAILS_REQUEST,
    LEVELS_DETAILS_SUCCESS,
    LEVELS_LIST_FAIL,
    LEVELS_LIST_REQUEST,
    LEVELS_LIST_SUCCESS,
    LEVELS_UPDATE_FAIL,
    LEVELS_UPDATE_REQUEST,
    LEVELS_UPDATE_SUCCESS
} from "../constants/levelsConstants";

export const listLevels = () => async (dispatch) => {
    try {
        dispatch({type: LEVELS_LIST_REQUEST})
        const {data} = await axios.get('/api/levels')
        dispatch({
            type: LEVELS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LEVELS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getLevels = (id) => async (dispatch) => {
    try {
        dispatch({type: LEVELS_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/levels/${id}`)
        dispatch({
            type: LEVELS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: LEVELS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteLevels = (id) => async (dispatch) => {
    try {
        dispatch({
            type: LEVELS_DELETE_REQUEST,
        })
        await axios.delete(`/api/levels/${id}`)

        dispatch({
            type: LEVELS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LEVELS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createLevels = (createdLevels) => async (dispatch) => {
    try {
        dispatch({
            type: LEVELS_CREATE_REQUEST,
        })
        await axios.post(`/api/levels`, createdLevels)

        dispatch({
            type: LEVELS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: LEVELS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateLevels = (updatedLevels, id) => async (dispatch) => {
    try {
        dispatch({
            type: LEVELS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/api/levels/${id}`, updatedLevels)

        dispatch({
            type: LEVELS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: LEVELS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
