import {createTheme, responsiveFontSizes} from "@material-ui/core";

let getMuiTheme = createTheme({
    overrides: {
        MuiInputBase: {
            input: {
                color: "white"
            }
        },
        MUIDataTableFilter: {
            root: {
                backgroundColor: "rgba(2, 35, 75, 1)"
            },
            title: {
                color: "white"
            }
        },
        MuiMenu: {
            list: {
                background: "rgba(2, 35, 75, 1)"
            }
        },
        MuiMenuItem: {
            root: {
                color: "white",
            }
        },

        MuiInput: {
            underline: {
                borderBottom: "0",
                '&:before': {
                    borderBottom: "1px solid white"
                },
                '&:hover': {
                    borderBottom: "1px solid white"
                }
            },


        },
        MUIDataTableSearch: {
            searchText: {
                background: "transparent"
            },
            searchIcon: {
                color: "white"
            }
        },
        MuiTableBody: {
            root: {}
        },
        MuiTableSortLabel: {
            root: {
                color: "white"
            },
            icon: {
                color: "white !important"
            }
        },
        MuiToolbar: {
            root: {
                backgroundColor: "transparent",
            }
        },
        MUIDataTableHeadCell: {
            sortActive: {
                color: "white"
            },
            fixedHeader: {
                '&:nth-of-type(last)': {
                    color: "white",
                    borderRight: "1px solid lightgrey"                  
                },
                
                borderLeft: 0,
                borderRight: 0,
                borderTop: "1px solid lightgrey",
                backgroundColor: "transparent",
            }
        },
        MuiButton: {
            root: {
                color: "white",
            },
            textPrimary: {
                color: "#CEFD34"
            }
        },
        MUIDataTableViewCol: {
            label: {color: "white"},
            title: {
                color: "white"
            },
            root: {
                backgroundColor: "#023357",
            }
        },
        MuiTableHead: {
            root: {}
        },
        MuiFormControl: {
            root: {
                backgroundColor: "transparent",
            }
        },
        MuiFormLabel: {
            root: {
                color: "white"
            }
        },
        MuiPaper: {
            elevation4: {
                boxShadow: "0",
            },
            root: {
                backgroundColor: "transparent",
            }
        },
        MuiIconButton: {
            label: {
                color: "white",
            }
        },
        MuiTablePagination: {
            toolbar: {
                backgroundColor: "transparent",
                color: "white"
            }
        },
        MuiSelect: {
            icon: {
                color: "white !important"
            }
        },
        MuiTableCell: {
            head: {
                color: "white",
                borderRight: "1px solid lightgrey",
            },
            body: {
                color: "white",
                borderBottom: "0",
                borderLeft: "1px solid lightgrey",
                borderRight: "1px solid lightgrey"                  
            },
            footer: {
                borderBottom: "1px solid lightgrey",
                borderTop: "1px solid lightgrey",
                marginBottom: "20px"
            }
        },
        MuiTableRow: {
            root: {
                '&:nth-of-type(odd)': {
                    color: "white",
                    backgroundColor: "rgba(255, 255, 255, 0.08);",
                    borderBottom: "0",
                    borderLeft: "1px solid lightgrey",
                    borderRight: "1px solid lightgrey"                  
                }              
            }
        }
    }
})

getMuiTheme = responsiveFontSizes(getMuiTheme);

export {
    getMuiTheme
}
