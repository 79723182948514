import axios from "../axios"

import {
    RACE_CATEGORY_CREATE_FAIL,
    RACE_CATEGORY_CREATE_REQUEST, RACE_CATEGORY_CREATE_SUCCESS,
    RACE_CATEGORY_DELETE_FAIL,
    RACE_CATEGORY_DELETE_REQUEST,
    RACE_CATEGORY_DELETE_SUCCESS,
    RACE_CATEGORY_DETAILS_FAIL,
    RACE_CATEGORY_DETAILS_REQUEST,
    RACE_CATEGORY_DETAILS_SUCCESS,
    RACE_CATEGORY_LIST_FAIL,
    RACE_CATEGORY_LIST_REQUEST,
    RACE_CATEGORY_LIST_SUCCESS,
    RACE_CATEGORY_UPDATE_FAIL,
    RACE_CATEGORY_UPDATE_REQUEST,
    RACE_CATEGORY_UPDATE_SUCCESS
} from "../constants/raceCategoryConstants";

export const listRaceCategory = () => async (dispatch) => {
    try {
        dispatch({type: RACE_CATEGORY_LIST_REQUEST})
        const {data} = await axios.get('/api/raceCategories')
        dispatch({
            type: RACE_CATEGORY_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: RACE_CATEGORY_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getRaceCategory = (id) => async (dispatch) => {
    try {
        dispatch({type: RACE_CATEGORY_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/raceCategories/${id}`)
        dispatch({
            type: RACE_CATEGORY_DETAILS_SUCCESS,
            payload: data[0],
        })
    } catch (error) {
        dispatch({
            type: RACE_CATEGORY_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteRaceCategory = (id) => async (dispatch) => {
    try {
        dispatch({
            type: RACE_CATEGORY_DELETE_REQUEST,
        })
        await axios.delete(`/api/raceCategories/${id}`)

        dispatch({
            type: RACE_CATEGORY_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: RACE_CATEGORY_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createRaceCategory = (createdRaceCategory) => async (dispatch) => {
    try {
        dispatch({
            type: RACE_CATEGORY_CREATE_REQUEST,
        })
        await axios.post(`/api/raceCategories`, createdRaceCategory)

        dispatch({
            type: RACE_CATEGORY_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: RACE_CATEGORY_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateRaceCategory = (updatedRaceCategory, id) => async (dispatch) => {
    try {
        dispatch({
            type: RACE_CATEGORY_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/api/raceCategories/${id}`, updatedRaceCategory)

        dispatch({
            type: RACE_CATEGORY_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: RACE_CATEGORY_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
