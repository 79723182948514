export const CONTACT_COMMERCIAL_LIST_REQUEST = "CONTACT_COMMERCIAL_LIST_REQUEST"
export const CONTACT_COMMERCIAL_LIST_SUCCESS = "CONTACT_COMMERCIAL_LIST_SUCCESS"
export const CONTACT_COMMERCIAL_LIST_FAIL = "CONTACT_COMMERCIAL_LIST_FAIL"

export const CONTACT_COMMERCIAL_DETAILS_REQUEST = "CONTACT_COMMERCIAL_DETAILS_REQUEST"
export const CONTACT_COMMERCIAL_DETAILS_SUCCESS = "CONTACT_COMMERCIAL_DETAILS_SUCCESS"
export const CONTACT_COMMERCIAL_DETAILS_FAIL = "CONTACT_COMMERCIAL_DETAILS_FAIL"

export const CONTACT_COMMERCIAL_CREATE_REQUEST = "CONTACT_COMMERCIAL_CREATE_REQUEST"
export const CONTACT_COMMERCIAL_CREATE_SUCCESS = "CONTACT_COMMERCIAL_CREATE_SUCCESS"
export const CONTACT_COMMERCIAL_CREATE_FAIL = "CONTACT_COMMERCIAL_CREATE_FAIL"
export const CONTACT_COMMERCIAL_CREATE_RESET = "CONTACT_COMMERCIAL_CREATE_RESET"

export const CONTACT_COMMERCIAL_DELETE_REQUEST = "CONTACT_COMMERCIAL_DELETE_REQUEST"
export const CONTACT_COMMERCIAL_DELETE_SUCCESS = "CONTACT_COMMERCIAL_DELETE_SUCCESS"
export const CONTACT_COMMERCIAL_DELETE_FAIL = "CONTACT_COMMERCIAL_DELETE_FAIL"

export const CONTACT_COMMERCIAL_UPDATE_REQUEST = "CONTACT_COMMERCIAL_UPDATE_REQUEST"
export const CONTACT_COMMERCIAL_UPDATE_SUCCESS = "CONTACT_COMMERCIAL_UPDATE_SUCCESS"
export const CONTACT_COMMERCIAL_UPDATE_FAIL = "CONTACT_COMMERCIAL_UPDATE_FAIL"
export const CONTACT_COMMERCIAL_UPDATE_RESET = "CONTACT_COMMERCIAL_UPDATE_RESET"
