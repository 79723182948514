import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {createVehicleType} from "../../../../actions/vehiculeTypeAction";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {listDisciplines} from "../../../../actions/disciplinesActions";
import {useNavigate} from "react-router-dom";
import {listSegments} from "../../../../actions/segmentsActions";
import axios from "../../../../axios";
import {VEHICULES_TYPES_CREATE_RESET} from '../../../../constants/vehiculeTypeConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewVehicleType = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let vehiculeTypesCreate = useSelector(state => state.vehiculeTypesCreate)
    const {success: successCreate, error: errorCreate} = vehiculeTypesCreate

    let disciplinesList = useSelector(state => state.disciplinesList)
    const {Disciplines} = disciplinesList

    let segmentsList = useSelector(state => state.segmentsList)
    const {Segments} = segmentsList

    const [name, setName] = useState("")
    const [header, setHeader] = useState(null)
    const [thumbnail, setThumbnail] = useState(null)
    const [headerName, setHeaderName] = useState("")
    const [thumbnailName, setThumbnailName] = useState("")
    const [category, setCategory] = useState("")
    const [monoplace, setMonoplace] = useState(0)

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formValues, setFormValues] = useState([])

    useEffect(() => {
        dispatch(listDisciplines())
        dispatch(listSegments())
    }, [dispatch])

    const saveFile = (e, type) => {
        switch (type) {
            case "Header":
                setHeader(e.target.files[0]);
                setHeaderName(e.target.files[0].name);
                break
            case "Thumbnail":
                setThumbnail(e.target.files[0]);
                setThumbnailName(e.target.files[0].name);
                break
            default:
                break
        }

    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        formDataProfile.append("file", header);
        formDataProfile.append("folder", "vehicleType/profile");
        formDataProfile.append("upload_preset", "zd2h6ivm")

        const formDataThumbnail = new FormData();
        formDataThumbnail.append("file", thumbnail);
        formDataThumbnail.append("folder", "vehicleType/thumbnail");
        formDataThumbnail.append("upload_preset", "zd2h6ivm")

        try {
            let res;
            let res2;
            if(header) {
                res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
            }
            if (thumbnail) {
                res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataThumbnail)

            }
            return [res.data.public_id || null, res2.data.public_id || null]
        } catch (ex) {
            console.log(ex);
        }
    };


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]["disciplineId"] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, {disciplineId: ""}])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const AddHandler = async () => {
        uploadFile().then(res => {
            let data;
            if (res !== undefined) {
                data = {
                    name: name,
                    header: res[0],
                    thumbnail: res[1],
                    category: category,
                    monoplace: monoplace,
                    disciplinesIds: formValues
                }
            } else {
                data = {
                    name: name,
                    header: "",
                    thumbnail: "",
                    category: category,
                    monoplace: monoplace,
                    disciplinesIds: formValues
                }
            }

            dispatch(createVehicleType(data))
        })
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/vehicules-types')
                dispatch({type: VEHICULES_TYPES_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le type de véhicule a bien été ajouté !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Vehicules-Types"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Vehicules Types</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div className="circuitMenus">
                <div className="subCoord">
                    <div className="names">
                        <p className="label-2">Nom du type de véhicule</p>
                        <input className="input" placeholder="Nom du type de véhicules"
                               value={name}
                               onChange={e => setName(e.target.value)}
                        />
                        <p className="label-2">Miniature</p>
                        <input className="input" placeholder="Thumbnail picture"
                               type="file"
                               onChange={e => saveFile(e, "Thumbnail")}
                        />
                        <p className="label-2">Bannière</p>
                        <input className="input" placeholder="Header picture"
                               type="file"
                               onChange={e => saveFile(e, "Header")}
                        />
                        <p className="label-2">Catégorie</p>
                        <div className="name">
                            <select className="state"
                                    value={category}
                                    label={"Catégorie"}
                                    onChange={e => setCategory(e.target.value)}
                            >
                                {Segments.map(seg => {
                                    return (<option key={seg.id} value={seg.name}>{seg.name}</option>)
                                })}
                            </select>
                        </div>
                        <div
                            style={{display: "flex", border: "1px solid white", borderRadius: "10px", padding: "15px"}}>
                            <input style={{margin: "auto"}} type="checkbox" id="option" name="option" checked={monoplace} onClick={e => setMonoplace(monoplace ? 0 : 1)}/>
                            <label style={{marginLeft: "20px"}} htmlFor="option">C'est une monoplace</label>
                        </div>
                        <p className="label-2">Disciplines</p>
                        {formValues.map((element, index) => (
                            <div className="name">
                                <select className="state" value={element.disciplineId}
                                        onChange={e => handleChange(index, e)}>
                                    <option className="sub" value="">-- Choisissez une Discipline
                                        --
                                    </option>
                                    {Disciplines.map(discipline => {
                                        return <option key={discipline.id}
                                                       value={discipline.id}>{discipline.name}</option>
                                    })}
                                </select>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button"
                                onClick={() => addFormFields()}>Ajouter une discipline
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewVehicleType
