import {
    LICENCES_CREATE_FAIL,
    LICENCES_CREATE_REQUEST,
    LICENCES_CREATE_SUCCESS,
    LICENCES_CREATE_RESET,
    LICENCES_DELETE_FAIL,
    LICENCES_DELETE_REQUEST,
    LICENCES_DELETE_SUCCESS,
    LICENCES_DETAILS_FAIL,
    LICENCES_DETAILS_REQUEST,
    LICENCES_DETAILS_SUCCESS,
    LICENCES_LIST_FAIL,
    LICENCES_LIST_REQUEST,
    LICENCES_LIST_SUCCESS,
    LICENCES_UPDATE_FAIL,
    LICENCES_UPDATE_REQUEST,
    LICENCES_UPDATE_SUCCESS,
    LICENCES_UPDATE_RESET
} from "../constants/licencesConstants";

export const licencesListReducers = (state = {Licences: []}, action) => {
    switch (action.type) {
        case LICENCES_LIST_REQUEST:
            return {loading: true, Licences: []}
        case LICENCES_LIST_SUCCESS:
            return {loading: false, Licences: action.payload}
        case LICENCES_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const licencesDetailsReducers = (state = {Licences: {}}, action) => {
    switch (action.type) {
        case LICENCES_DETAILS_REQUEST:
            return {loading: true, ...state}
        case LICENCES_DETAILS_SUCCESS:
            return {loading: false, Licences: action.payload}
        case LICENCES_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const licencesCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case LICENCES_CREATE_REQUEST:
            return {loading: true}
        case LICENCES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case LICENCES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case LICENCES_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const licencesDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case LICENCES_DELETE_REQUEST:
            return {loading: true}
        case LICENCES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case LICENCES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const licencesUpdateReducers = (state = {Licences: {}}, action) => {
    switch (action.type) {
        case LICENCES_UPDATE_REQUEST:
            return {loading: true}
        case LICENCES_UPDATE_SUCCESS:
            return {loading: false, success: true, Licences: action.payload}
        case LICENCES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case LICENCES_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
