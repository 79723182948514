import React, {useEffect, useState} from 'react'
import ProfileButton from "../../../components/profileButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import MUIDataTable from "mui-datatables";
import {useDispatch, useSelector} from "react-redux";
import {listVehicleTypes} from "../../../actions/vehiculeTypeAction";
import {useNavigate} from "react-router-dom";
import ArrayValueUI from "../../../components/ui/arrayValue";
import {Image} from "cloudinary-react";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";
import axios from "../../../axios";

const VehicleType = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [vehicleTypes, setVehicleTypes] = useState([])

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Nom",
            name: "name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link"
                           href={`vehicules-types/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        },
        {
            label: "Thumbnail",
            name: "thumbnail",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<Image cloudName={"accelerio"} public_id={val} width="150px"/>)
                },
            }
        },
        {
            label: "Header",
            name: "header",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<Image cloudName={"accelerio"} src={val} width="50px"/>)
                },
            }
        },
        {
            label: "Categories",
            name: "category",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            label: "Monoplace",
            name: "monoplace",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val) => {
                    if (val) {
                        return <p>Oui</p>
                    } else {
                        return <p>Non</p>
                    }
                }
            }
        },
        {
            label: "Disciplines",
            name: "disciplines",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    console.log(val)
                    if (val[0] !== null) {
                        return <ArrayValueUI data={val}/>
                    } else {
                        return <p>Pas de disciplines</p>
                    }
                },
            }
        }

    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    const fetchData = async () => {
        try {
            const {data} = await axios.get("/api/vehicleTypes")
            setVehicleTypes(data)
        } catch (e) {
            setError(e.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    console.log(vehicleTypes)
    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Type de véhicules (MAJ en cours, possible bug)</h2>
                <button className="new_button"
                        onClick={() => history('/vehicules-types/new')}>
                    <AddRoundedIcon style={{marginTop: "auto", marginBottom: "auto"}}/>
                    <p style={{margin: "auto", marginLeft: "5px"}}>Nouveau</p>
                </button>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={vehicleTypes}
                            columns={columns}
                            options={options}
                        />
                    )}

            </div>
        </ThemeProvider>
    )
}

export default VehicleType
