import React from 'react'
import PersonIcon from '@material-ui/icons/Person';
import '../../assets/css/style.css'
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import LanguageIcon from "@material-ui/icons/Language";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const Coordonnees = (props) => {

    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="label">Nom de cicruit</p>
                    <div className="name">
                        <PersonIcon className="name-icon"/>
                        <input className="input" placeholder="Nom du circuit"
                               value={props.name}
                               onChange={e => props.setName(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Surnom de circuit</p>
                    <div className="name">
                        <PersonIcon className="name-icon"/>
                        <input className="input" placeholder="Surnom du circuit"
                               value={props.surname}
                               onChange={e => props.setSurname(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Slug</p>
                    <div className="name">
                        <PersonIcon className="name-icon"/>
                        <input className="input" placeholder="Slug"
                               value={props.slug}
                               onChange={e => props.setSlug(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
                <div className="status">
                    <p className="label-2">Statut</p>
                    <div className="name">
                        <select className="state" value={props.status} onChange={e => props.setStatus(e.target.value)}>
                            <option className="sub" value="">-- Choisissez un status --</option>
                            <option value="Pas de status">Pas de status</option>
                            <option value="Ouvert">Ouvert</option>
                            <option value="Fermé définitivement">Fermé définitivement</option>
                        </select>
                    </div>
                    <p className="label-2">Fiche d'état</p>
                    <div className="name">
                        <select className="state" value={props.etat} onChange={e => props.setEtat(e.target.value)}>
                            <option value="">-- Choisissez un état --</option>
                            <option value="Pas d'état">Pas d'état</option>
                            <option value="En cours">En cours</option>
                            <option value="A jour">A jour</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="subCoord">
                <div className="about">
                    <p className="label">Numéro de téléphone</p>
                    <div className="name">
                        <PhoneIcon className="icon"/>
                        <input className="input" placeholder="Numéro de téléphone"
                               value={props.phone}
                               onChange={e => props.setPhone(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Adresse e-mail</p>
                    <div className="name">
                        <MailOutlineIcon className="icon"/>
                        <input className="input" placeholder="Adresse e-mail"
                               value={props.mail}
                               onChange={e => props.setMail(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
                <div className="about">
                    <p className="label">Facebook</p>
                    <div className="name">
                        <FacebookIcon className="icon"/>
                        <input className="input" placeholder="Facebook"
                               value={props.facebook}
                               onChange={e => props.setFacebook(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Site web</p>
                    <div className="name">
                        <LanguageIcon className="icon"/>
                        <input className="input" placeholder="Site web"
                               value={props.website}
                               onChange={e => props.setWebsite(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                    <p className="label">Instagram</p>
                    <div className="name">
                        <InstagramIcon className="icon"/>
                        <input className="input" placeholder="Instagram"
                               value={props.instagram}
                               onChange={e => props.setInstagram(e.target.value)}
                               style={{margin: "auto"}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Coordonnees
