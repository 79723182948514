import {
    SEGMENTS_CREATE_FAIL,
    SEGMENTS_CREATE_REQUEST,
    SEGMENTS_CREATE_SUCCESS,
    SEGMENTS_CREATE_RESET,
    SEGMENTS_DELETE_FAIL,
    SEGMENTS_DELETE_REQUEST,
    SEGMENTS_DELETE_SUCCESS,
    SEGMENTS_DETAILS_FAIL,
    SEGMENTS_DETAILS_REQUEST,
    SEGMENTS_DETAILS_SUCCESS,
    SEGMENTS_LIST_FAIL,
    SEGMENTS_LIST_REQUEST,
    SEGMENTS_LIST_SUCCESS,
    SEGMENTS_UPDATE_FAIL,
    SEGMENTS_UPDATE_REQUEST,
    SEGMENTS_UPDATE_SUCCESS,
    SEGMENTS_UPDATE_RESET
} from "../constants/segmentsConstants";

export const segmentsListReducers = (state = {Segments: []}, action) => {
    switch (action.type) {
        case SEGMENTS_LIST_REQUEST:
            return {loading: true, Segments: []}
        case SEGMENTS_LIST_SUCCESS:
            return {loading: false, Segments: action.payload}
        case SEGMENTS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const segmentsDetailsReducers = (state = {Segments: {}}, action) => {
    switch (action.type) {
        case SEGMENTS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case SEGMENTS_DETAILS_SUCCESS:
            return {loading: false, Segments: action.payload}
        case SEGMENTS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const segmentsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case SEGMENTS_CREATE_REQUEST:
            return {loading: true}
        case SEGMENTS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case SEGMENTS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case SEGMENTS_CREATE_RESET:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const segmentsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case SEGMENTS_DELETE_REQUEST:
            return {loading: true}
        case SEGMENTS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case SEGMENTS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const segmentsUpdateReducers = (state = {Segments: {}}, action) => {
    switch (action.type) {
        case SEGMENTS_UPDATE_REQUEST:
            return {loading: true}
        case SEGMENTS_UPDATE_SUCCESS:
            return {loading: false, success: true, Segments: action.payload}
        case SEGMENTS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case SEGMENTS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
