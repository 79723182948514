import {
    ORGANISATIONS_CREATE_FAIL,
    ORGANISATIONS_CREATE_REQUEST,
    ORGANISATIONS_CREATE_SUCCESS,
    ORGANISATIONS_CREATE_RESET,
    ORGANISATIONS_DELETE_FAIL,
    ORGANISATIONS_DELETE_REQUEST,
    ORGANISATIONS_DELETE_SUCCESS,
    ORGANISATIONS_DETAILS_FAIL,
    ORGANISATIONS_DETAILS_REQUEST,
    ORGANISATIONS_DETAILS_SUCCESS,
    ORGANISATIONS_LIST_FAIL,
    ORGANISATIONS_LIST_REQUEST,
    ORGANISATIONS_LIST_SUCCESS,
    ORGANISATIONS_UPDATE_FAIL,
    ORGANISATIONS_UPDATE_REQUEST,
    ORGANISATIONS_UPDATE_SUCCESS,
    ORGANISATIONS_UPDATE_RESET
} from "../constants/organisationsConstants";

export const organisationsListReducers = (state = {organisations: []}, action) => {
    switch (action.type) {
        case ORGANISATIONS_LIST_REQUEST:
            return {loading: true, organisations: []}
        case ORGANISATIONS_LIST_SUCCESS:
            return {loading: false, organisations: action.payload}
        case ORGANISATIONS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const organisationsDetailsReducers = (state = {organisations: {}}, action) => {
    switch (action.type) {
        case ORGANISATIONS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case ORGANISATIONS_DETAILS_SUCCESS:
            return {loading: false, organisations: action.payload}
        case ORGANISATIONS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const organisationsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case ORGANISATIONS_CREATE_REQUEST:
            return {loading: true}
        case ORGANISATIONS_CREATE_SUCCESS:
            return {loading: false, success: true}
        case ORGANISATIONS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case ORGANISATIONS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const organisationsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case ORGANISATIONS_DELETE_REQUEST:
            return {loading: true}
        case ORGANISATIONS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case ORGANISATIONS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const organisationsUpdateReducers = (state = {organisations: {}}, action) => {
    switch (action.type) {
        case ORGANISATIONS_UPDATE_REQUEST:
            return {loading: true}
        case ORGANISATIONS_UPDATE_SUCCESS:
            return {loading: false, success: true, organisations: action.payload}
        case ORGANISATIONS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case ORGANISATIONS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
