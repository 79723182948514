import axios from "../axios"

import {
    VEHICULES_TYPES_CREATE_FAIL,
    VEHICULES_TYPES_CREATE_REQUEST, VEHICULES_TYPES_CREATE_SUCCESS,
    VEHICULES_TYPES_DELETE_FAIL,
    VEHICULES_TYPES_DELETE_REQUEST,
    VEHICULES_TYPES_DELETE_SUCCESS,
    VEHICULES_TYPES_DETAILS_FAIL,
    VEHICULES_TYPES_DETAILS_REQUEST,
    VEHICULES_TYPES_DETAILS_SUCCESS,
    VEHICULES_TYPES_LIST_FAIL,
    VEHICULES_TYPES_LIST_REQUEST,
    VEHICULES_TYPES_LIST_SUCCESS,
    VEHICULES_TYPES_UPDATE_FAIL,
    VEHICULES_TYPES_UPDATE_REQUEST,
    VEHICULES_TYPES_UPDATE_SUCCESS
} from "../constants/vehiculeTypeConstants";
import {toast} from "react-toastify";

export const listVehicleTypes = () => async (dispatch) => {
    try {
        dispatch({type: VEHICULES_TYPES_LIST_REQUEST})

        const {data} = await toast.promise(
            axios.get('/api/vehicleTypes'),
            {
                pending: 'Liste des véhicules en cours de chargement',
                success: 'Liste des véhicules OK 👌',
                error: 'Liste des véhicules KO 🤯'
            }
        );
        dispatch({
            type: VEHICULES_TYPES_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: VEHICULES_TYPES_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getVehicleTypes = (id) => async (dispatch) => {
    try {
        dispatch({type: VEHICULES_TYPES_DETAILS_REQUEST})
        const {data} = await toast.promise(
            axios.get(`/api/vehicleTypes/${id}`),
            {
                pending: 'Promise is pending',
                success: 'Promise resolved 👌',
                error: 'Promise rejected 🤯'
            }
        );
        dispatch({
            type: VEHICULES_TYPES_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: VEHICULES_TYPES_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteVehicleType = (id) => async (dispatch) => {
    try {
        dispatch({
            type: VEHICULES_TYPES_DELETE_REQUEST,
        })
        await axios.delete(`/api/vehicleTypes/${id}`)

        dispatch({
            type: VEHICULES_TYPES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: VEHICULES_TYPES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createVehicleType = (createdVehicleType) => async (dispatch) => {
    try {
        dispatch({
            type: VEHICULES_TYPES_CREATE_REQUEST,
        })
        await axios.post(`/api/vehicleTypes`, createdVehicleType)

        dispatch({
            type: VEHICULES_TYPES_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: VEHICULES_TYPES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateVehicleType = (updatedVehicleType, id) => async (dispatch) => {
    try {
        dispatch({
            type: VEHICULES_TYPES_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/api/vehicleTypes/${id}`, updatedVehicleType)

        dispatch({
            type: VEHICULES_TYPES_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: VEHICULES_TYPES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
