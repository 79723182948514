import {
    HOMOLOGATIONS_CREATE_FAIL,
    HOMOLOGATIONS_CREATE_REQUEST,
    HOMOLOGATIONS_CREATE_SUCCESS,
    HOMOLOGATIONS_CREATE_RESET,
    HOMOLOGATIONS_DELETE_FAIL,
    HOMOLOGATIONS_DELETE_REQUEST,
    HOMOLOGATIONS_DELETE_SUCCESS,
    HOMOLOGATIONS_DETAILS_FAIL,
    HOMOLOGATIONS_DETAILS_REQUEST,
    HOMOLOGATIONS_DETAILS_SUCCESS,
    HOMOLOGATIONS_LIST_FAIL,
    HOMOLOGATIONS_LIST_REQUEST,
    HOMOLOGATIONS_LIST_SUCCESS,
    HOMOLOGATIONS_UPDATE_FAIL,
    HOMOLOGATIONS_UPDATE_REQUEST,
    HOMOLOGATIONS_UPDATE_SUCCESS,
    HOMOLOGATIONS_UPDATE_RESET
} from "../constants/homologationsConstants";

export const homologationsListReducers = (state = {Homologations: []}, action) => {
    switch (action.type) {
        case HOMOLOGATIONS_LIST_REQUEST:
            return {loading: true, Homologations: []}
        case HOMOLOGATIONS_LIST_SUCCESS:
            return {loading: false, Homologations: action.payload}
        case HOMOLOGATIONS_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const homologationsDetailsReducers = (state = {Homologations: {}}, action) => {
    switch (action.type) {
        case HOMOLOGATIONS_DETAILS_REQUEST:
            return {loading: true, ...state}
        case HOMOLOGATIONS_DETAILS_SUCCESS:
            return {loading: false, Homologations: action.payload}
        case HOMOLOGATIONS_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const homologationsCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case HOMOLOGATIONS_CREATE_REQUEST:
            return {loading: true}
        case HOMOLOGATIONS_CREATE_SUCCESS:
            return {loading: false, success: true, homologationsID: action.payload}
        case HOMOLOGATIONS_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case HOMOLOGATIONS_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const homologationsDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case HOMOLOGATIONS_DELETE_REQUEST:
            return {loading: true}
        case HOMOLOGATIONS_DELETE_SUCCESS:
            return {loading: false, success: true}
        case HOMOLOGATIONS_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const homologationsUpdateReducers = (state = {Homologations: {}}, action) => {
    switch (action.type) {
        case HOMOLOGATIONS_UPDATE_REQUEST:
            return {loading: true}
        case HOMOLOGATIONS_UPDATE_SUCCESS:
            return {loading: false, success: true, Homologations: action.payload}
        case HOMOLOGATIONS_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case HOMOLOGATIONS_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
