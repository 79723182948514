export const CIRCUITS_LIST_REQUEST = "CIRCUITS_LIST_REQUEST"
export const CIRCUITS_LIST_SUCCESS = "CIRCUITS_LIST_SUCCESS"
export const CIRCUITS_LIST_FAIL = "CIRCUITS_LIST_FAIL"

export const CIRCUITS_DETAILS_REQUEST = "CIRCUITS_DETAILS_REQUEST"
export const CIRCUITS_DETAILS_SUCCESS = "CIRCUITS_DETAILS_SUCCESS"
export const CIRCUITS_DETAILS_FAIL = "CIRCUITS_DETAILS_FAIL"

export const CIRCUITS_CREATE_REQUEST = "CIRCUITS_CREATE_REQUEST"
export const CIRCUITS_CREATE_SUCCESS = "CIRCUITS_CREATE_SUCCESS"
export const CIRCUITS_CREATE_FAIL = "CIRCUITS_CREATE_FAIL"
export const CIRCUITS_CREATE_RESET = "CIRCUITS_CREATE_RESET"

export const CIRCUITS_DELETE_REQUEST = "CIRCUITS_DELETE_REQUEST"
export const CIRCUITS_DELETE_SUCCESS = "CIRCUITS_DELETE_SUCCESS"
export const CIRCUITS_DELETE_FAIL = "CIRCUITS_DELETE_FAIL"

export const CIRCUITS_UPDATE_REQUEST = "CIRCUITS_UPDATE_REQUEST"
export const CIRCUITS_UPDATE_SUCCESS = "CIRCUITS_UPDATE_SUCCESS"
export const CIRCUITS_UPDATE_FAIL = "CIRCUITS_UPDATE_FAIL"
export const CIRCUITS_UPDATE_RESET = "CIRCUITS_UPDATE_RESET"
