import axios from "../axios"

import {
    HOMOLOGATIONS_CREATE_FAIL,
    HOMOLOGATIONS_CREATE_REQUEST,
    HOMOLOGATIONS_CREATE_SUCCESS,
    HOMOLOGATIONS_DELETE_FAIL,
    HOMOLOGATIONS_DELETE_REQUEST,
    HOMOLOGATIONS_DELETE_SUCCESS,
    HOMOLOGATIONS_DETAILS_FAIL,
    HOMOLOGATIONS_DETAILS_REQUEST,
    HOMOLOGATIONS_DETAILS_SUCCESS,
    HOMOLOGATIONS_LIST_FAIL,
    HOMOLOGATIONS_LIST_REQUEST,
    HOMOLOGATIONS_LIST_SUCCESS,
    HOMOLOGATIONS_UPDATE_FAIL,
    HOMOLOGATIONS_UPDATE_REQUEST,
    HOMOLOGATIONS_UPDATE_SUCCESS
} from "../constants/homologationsConstants";

export const listHomologations = () => async (dispatch) => {
    try {
        dispatch({type: HOMOLOGATIONS_LIST_REQUEST})
        const {data} = await axios.get('/certifications')
        dispatch({
            type: HOMOLOGATIONS_LIST_SUCCESS,
            payload: data.allCertifications,
        })
    } catch (error) {
        dispatch({
            type: HOMOLOGATIONS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getHomologations = (id) => async (dispatch) => {
    try {
        dispatch({type: HOMOLOGATIONS_DETAILS_REQUEST})
        const {data} = await axios.get(`/certifications/${id}`)
        dispatch({
            type: HOMOLOGATIONS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: HOMOLOGATIONS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteHomologations = (id) => async (dispatch) => {
    try {
        dispatch({
            type: HOMOLOGATIONS_DELETE_REQUEST,
        })
        await axios.delete(`/certifications/${id}`)

        dispatch({
            type: HOMOLOGATIONS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: HOMOLOGATIONS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createHomologations = (createdHomologations) => async (dispatch) => {
    try {
        dispatch({
            type: HOMOLOGATIONS_CREATE_REQUEST,
        })
        const {data} = await axios.post(`/certifications`, createdHomologations)
        dispatch({
            type: HOMOLOGATIONS_CREATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: HOMOLOGATIONS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateHomologations = (updatedHomologations, id) => async (dispatch) => {
    try {
        dispatch({
            type: HOMOLOGATIONS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/certifications/${id}`, updatedHomologations)

        dispatch({
            type: HOMOLOGATIONS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: HOMOLOGATIONS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
