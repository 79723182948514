import React, {useEffect} from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import MUIDataTable from "mui-datatables";
import {useNavigate} from 'react-router-dom';
import {ThemeProvider} from "@mui/styles";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch, useSelector} from "react-redux";
import {listPilots} from "../../../actions/pilotsActions";
import {API_URL} from "../../../config";
import BreadcrumbWill from "../../../components/navigation/breadcrumbWill";

const Pilots = (props) => {
    const dispatch = useDispatch()

    let pilotsList = useSelector(state => state.pilotsList)
    const {loading, error, pilots} = pilotsList

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                display: false
            }
        },
        {
            label: "Prénom",
            name: "firstname",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (val, cell) => {
                    return (
                        <a className="link" href={`pilots/edit/${cell.rowData[0]}`}>{val}</a>
                    )
                },
            }
        }, {
            label: "Nom",
            name: "lastname",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "Photo de profil",
            name: "profile_picture",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (val) => {
                    return (<img width="50px" src={`${API_URL}/${val}`} alt={"picturesProfile"}/>)
                },
            }
        },
        {
            label: "Email",
            name: "mail",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "Téléphone",
            name: "phone",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "Adresse",
            name: "address",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "ZipCode",
            name: "zip_code",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "Ville",
            name: "city",
            options: {
                filter: false,
                sort: false,
            }
        }, {
            label: "Pays",
            name: "country",
            options: {
                filter: false,
                sort: false,
            }
        }
    ]

    const options = {
        print: false,
        selectableRows: false,
    };

    useEffect(() => {
        dispatch(listPilots())
    }, [dispatch])

    return (
        <ThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Pilotes</h2>
            </div>
            <div className="table">
                {loading ? <p style={{color: "white"}}>Loading...</p> : error ?
                    <p style={{color: "white"}}>{error}</p> : (
                        <MUIDataTable
                            data={pilots}
                            columns={columns}
                            options={options}
                        />
                    )}
            </div>
        </ThemeProvider>
    )
}

export default Pilots
