import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import SelectComponents from "../ui/SelectComponents";

const Deroulement = (props) => {

    console.log(props.circuits)
    return (
        <div className="coordonnees">
            <div className="subCoord">
                <div className="names">
                    <p className="first-subTitles">Où se déroule l'activité ?</p>
                    <br/>
                    <p className="label-2">Circuit</p>
                    <div className="name">
                        <SelectComponents value={props.circuit} setValue={props.setCircuit}
                                          listToFiltered={props.circuits}
                                          placeholder={"Choisissez votre circuit"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Deroulement
