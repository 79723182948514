import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RoomIcon from '@material-ui/icons/Room';
import HomeIcon from '@material-ui/icons/Home';
import BuildIcon from '@material-ui/icons/Build';
import MuiAlert from "@mui/material/Alert";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {deleteCircuits, getCircuits, updateCircuits} from "../../../../actions/circuitsActions";
import {listOrganisations} from "../../../../actions/organisationsActions";
import Snackbar from "@mui/material/Snackbar";
import '../../../../assets/css/newBackOfficeBdd.css';
import '../../../../assets/css/style.css'
import {listSegments} from "../../../../actions/segmentsActions";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {listHomologations} from "../../../../actions/homologationsActions";
import {listLicences} from "../../../../actions/licencesActions";
import {listFacilities} from "../../../../actions/facilitiesActions";
import {listVehicleTypes} from "../../../../actions/vehiculeTypeAction";
import axios from "../../../../axios";
import Adresse from '../../../../components/circuits/Adresse';
import Amenagements from '../../../../components/circuits/Amenagements';
import Coordonnees from '../../../../components/circuits/Coordonnees';
import Horaires from '../../../../components/circuits/Horaires';
import Licences from '../../../../components/circuits/Licences';
import Presentation from '../../../../components/circuits/Presentation';
import Vehicules from '../../../../components/circuits/Vehicules';
import {CIRCUITS_UPDATE_RESET} from '../../../../constants/circuitsConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";
import TextesPop from "../../../../components/circuits/TextesPop";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditCircuits = (props) => {
    const {id: circuitsId} = useParams()

    const history = useNavigate()
    const dispatch = useDispatch()

    const [tabs, setTabs] = useState(1)

    let circuitsDetails = useSelector(state => state.circuitsDetails)
    const {circuits} = circuitsDetails

    let circuitsDelete = useSelector(state => state.circuitsDelete)
    const {success: successDelete} = circuitsDelete

    let circuitsUpdate = useSelector(state => state.circuitsUpdate)
    const {success: successUpdate} = circuitsUpdate

    let segmentsList = useSelector(state => state.segmentsList)
    const {Segments} = segmentsList

    let facilitiesList = useSelector(state => state.facilitiesList)
    const {Facilities} = facilitiesList

    let licencesList = useSelector(state => state.licencesList)
    const {Licences: list_Licences} = licencesList

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let homologationsList = useSelector(state => state.homologationsList)
    const {Homologations} = homologationsList

    let vehiculeTypesList = useSelector(state => state.vehiculeTypesList)
    const {vehiculeTypes} = vehiculeTypesList

    const [allFacilities, setAllFacilities] = useState([])
    const [allLicences, setAllLicences] = useState([])
    const [allHomologations, setAllHomologations] = useState([])
    const [allVehicleTypes, setAllVehicleTypes] = useState([])

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const removeFromArray = (id, array, setArray) => {
        let index = array.indexOf(id)
        let newArray = [...array]
        if (index > -1) {
            newArray.splice(index, 1)
        }
        setArray(newArray)
    }


    const [slug, setSlug] = useState("")
    const [address, setAddress] = useState("")
    const [complement, setComplement] = useState("")
    const [code_post, setCode_post] = useState("")
    const [ville, setVille] = useState("")
    const [pays, setPays] = useState("")
    const [gps, setGps] = useState("") // Adresse
    const [url_gmap, setUrl_gmap] = useState("")
    const [url_waze, setUrl_waze] = useState("")
    const [url_plans, setUrl_plans] = useState("")

    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [organisation, setOrganisation] = useState("")
    const [status, setStatus] = useState("")
    const [etat, setEtat] = useState("")
    const [phone, setPhone] = useState("")
    const [mail, setMail] = useState("")
    const [facebook, setFacebook] = useState("")
    const [website, setWebsite] = useState("")
    const [instagram, setInstagram] = useState("")

    const [sol, setSol] = useState("")
    const [espace, setEspace] = useState("")
    const [longueur, setLongueur] = useState("")
    const [largeur, setLargeur] = useState("")
    const [longest_straight, setLongest] = useState("")
    const [noise, setNoise] = useState(0)
    const [pres_text, setPres_text] = useState("")
    const [trace, setTrace] = useState()
    const [img1, setImg1] = useState()
    const [img2, setImg2] = useState()
    const [img3, setImg3] = useState()


    const [oldTraceName, setOldTraceName] = useState("")
    const [oldImg1Name, setOldImg1Name] = useState("")
    const [oldImg2Name, setOldImg2Name] = useState("")
    const [oldImg3Name, setOldImg3Name] = useState("")

    const [video1, setVideo1] = useState("")
    const [video2, setVideo2] = useState("")

    const [formSegmentValues, setFormSegmentValues] = useState([])

    let handleSegmentChange = (i, e) => {
        let newFormValues = [...formSegmentValues];
        newFormValues[i]["segmentId"] = e.target.value;
        setFormSegmentValues(newFormValues);
    }

    let addSegmentFormFields = () => {
        setFormSegmentValues([...formSegmentValues, {segmentId: ""}])
    }

    let removeSegmentFormFields = (i) => {
        let newFormValues = [...formSegmentValues];
        newFormValues.splice(i, 1);
        setFormSegmentValues(newFormValues)
    }

    const [addressId, setAddressId] = useState()
    const [circuitsSpecsId, setCircuitsSpecsId] = useState()

    const [textCome, setTextCome] = useState("")
    const [textHousing, setTextHousing] = useState("")
    const [textFood, setTextFood] = useState("")
    const [textOil, setTextOil] = useState("")
    const [idDesc, setIdDesc] = useState(null)


    const saveFile = async (e, type) => {
        switch (type) {
            case "Trace":
                setTrace(e.target.files[0]);
                break
            case "Img1":
                setImg1(e.target.files[0]);
                break
            case "Img2":
                setImg2(e.target.files[0]);
                break
            case "Img3":
                setImg3(e.target.files[0]);
                break
            default:
                break
        }

    };

    const uploadFile = async () => {
        const formDataTrack = new FormData();
        const formDataImg1 = new FormData();
        const formDataImg2 = new FormData();
        const formDataImg3 = new FormData();

        let publicIdTrack = oldTraceName
        let publicIdImg1 = oldImg1Name
        let publicIdImg2 = oldImg2Name
        let publicIdImg3 = oldImg3Name

        try {
            if (trace) {
                formDataTrack.append("file", trace);
                formDataTrack.append("folder", "circuits/tracks");
                formDataTrack.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataTrack)
                publicIdTrack = res.data.public_id
            }

            if (img1) {
                formDataImg1.append("file", img1);
                formDataImg1.append("folder", "circuits/images");
                formDataImg1.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataImg1)
                publicIdImg1 = res.data.public_id
            }

            if (img2) {
                formDataImg2.append("file", img2);
                formDataImg2.append("folder", "circuits/images");
                formDataImg2.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataImg2)
                publicIdImg2 = res.data.public_id
            }

            if (img3) {
                formDataImg3.append("file", img3);
                formDataImg3.append("folder", "circuits/images");
                formDataImg3.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataImg3)
                publicIdImg3 = res.data.public_id
            }

            return [publicIdTrack, publicIdImg1, publicIdImg2, publicIdImg3]

        } catch (ex) {
            console.log(ex);
        }

    };

    const EditHandler = async () => {

        uploadFile().then(res => {
            let data = {

                slug: slug,
                addressId: addressId,
                circuitsSpecsId: circuitsSpecsId,

                facilitiesIds: allFacilities,
                licencesIds: allLicences,
                homologationIds: allHomologations,

                vehicleTypeIds: allVehicleTypes,
                segmentIds: formSegmentValues,

                address: {
                    line: address,
                    line2: complement,
                    zipcode: code_post,
                    city: ville,
                    country: pays,
                },

                organisationId: organisation,

                name: name,
                surname: surname,
                coordinate: gps,
                google_map: url_gmap,
                waze: url_waze,
                plans: url_plans,
                status: status,
                mail: mail,
                etat: etat,
                phone: phone,
                track: res[0],
                facebook: facebook,
                website: website,
                instagram: instagram,

                floor: sol,
                space: espace,
                longueur: longueur,
                largeur: largeur,
                noise: noise,
                straight_line: longest_straight,
                presentation: pres_text,
                image: [res[1], res[2], res[3]],
                video: [video1, video2],
                textCome,
                textFood,
                textOil,
                textHousing,
                idDesc
            }

            dispatch(updateCircuits(data, circuitsId))
        })

    }

    const deleteHandler = () => {
        dispatch(deleteCircuits(circuitsId))
    }

    useEffect(() => {
        if (successDelete) {
            history('/circuits')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getCircuits(circuitsId))
            dispatch({type: CIRCUITS_UPDATE_RESET})
            setTimeout(() => {
                history('/circuits')
            }, 1000)
        }
    }, [successUpdate, successDelete, history])

    useEffect(() => {
        if (circuitsId !== circuits[0]?.id.toString()) {
            dispatch(getCircuits(circuitsId))
        } else {
            let circuit = circuits[0]

            setTextCome(circuit.textCome)
            setTextFood(circuit.textFood)
            setTextOil(circuit.textOil)
            setTextHousing(circuit.textHousing)
            setIdDesc(circuit.idDesc)

            setAddress(circuit.line)
            setComplement(circuit.line2)
            setCode_post(circuit.zipcode)
            setVille(circuit.city)
            setPays(circuit.country)
            setGps(circuit.coordinate)
            setUrl_plans(circuit.plans)
            setUrl_waze(circuit.waze)
            setUrl_gmap(circuit.google_maps)
            setSlug(circuit.slug)
            setName(circuit.name)
            setSurname(circuit.surname)
            setOrganisation(circuit.organisation)
            setStatus(circuit.status)
            setEtat(circuit.etat)
            setPhone(circuit.phone)
            setFacebook(circuit.facebook)
            setWebsite(circuit.website)
            setInstagram(circuit.instagram)
            setSol(circuit.floor)
            setEspace(circuit.space)
            setLongueur(circuit.longueur)
            setLargeur(circuit.largeur)
            setLongest(circuit.straight_line)
            setPres_text(circuit.presentation)
            setOldTraceName(circuit.tracks)
            setOldImg1Name(circuit.image1)
            setOldImg2Name(circuit.image2)
            setOldImg3Name(circuit.image3)
            setVideo1(circuit.video1)
            setVideo2(circuit.video2)
            setMail(circuit.mail)
            setNoise(circuit.noise)

            setAddressId(circuit.addressId)
            setCircuitsSpecsId(circuit.circuitsSpecsId)

            let facilitiesSplit = circuit.facilitiesIds?.split("/")
            let licencesSplit = circuit.licencesIds?.split("/")
            let homologationsSplit = circuit.certificationIds?.split("/")
            let vehicleTypeSplit = circuit.vehicleTypeIds?.split("/")
            let segmentSplit = circuit.segmentIds?.split("/")

            let newSegmentFormValues = []
            newSegmentFormValues.push({segmentId: ""})
            segmentSplit?.forEach((segmentId, index) => {
                if (index === 0) {
                    newSegmentFormValues.pop()
                }
                newSegmentFormValues.push({segmentId: segmentId})
            })
            setFormSegmentValues(newSegmentFormValues)

            let newFacilitiesFormValues = []
            facilitiesSplit?.forEach((facilitiesId) => {
                newFacilitiesFormValues.push(parseInt(facilitiesId))

            })
            setAllFacilities(newFacilitiesFormValues)


            let newLicenceFormValues = []
            licencesSplit?.forEach((licenceId) => {
                newLicenceFormValues.push(parseInt(licenceId))
            })
            setAllLicences(newLicenceFormValues)

            let newCertificationFormValues = []
            homologationsSplit?.forEach((certificationId) => {
                newCertificationFormValues.push(parseInt(certificationId))
            })
            setAllHomologations(newCertificationFormValues)

            let newVehicleTypeFormValues = []
            vehicleTypeSplit?.forEach((vehicleTypeId) => {
                newVehicleTypeFormValues.push(parseInt(vehicleTypeId))
            })
            setAllVehicleTypes(newVehicleTypeFormValues)
        }
    }, [dispatch, circuitsId, circuits])


    useEffect(() => {
        dispatch(listSegments())
        dispatch(listHomologations())
        dispatch(listLicences())
        dispatch(listFacilities())
        dispatch(listOrganisations())
        dispatch(listVehicleTypes())
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le circuit a bien été modifié !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Circuits</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className={tabs === 1 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(1)}>
                    <AccountCircleIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Coordonnées</p>
                </button>
                <button className={tabs === 2 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(2)}>
                    <RoomIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Adresse</p>
                </button>
                <button className={tabs === 3 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(3)}>
                    <HomeIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Présentation</p>
                </button>
                <button className={tabs === 4 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(4)}>
                    <BuildIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Aménagements</p>
                </button>
                <button className={tabs === 5 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(5)}>
                    <CheckBoxIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Licences et Homologations</p>
                </button>
                <button className={tabs === 6 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(6)}>
                    <DirectionsCarIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Véhicules admis</p>
                </button>
                <button className={tabs === 7 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(7)}>
                    <DateRangeIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Horaires</p>
                </button>
                <button className={tabs === 8 ? "buttonTabs showActiveBackground" : "buttonTabs"}
                        onClick={(e) => setTabs(8)}>
                    <DateRangeIcon className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Textes</p>
                </button>
            </div>
            <div className="circuitMenus">
                {tabs === 1 ? <Coordonnees
                    slug={slug} setSlug={setSlug}
                    name={name} setName={setName}
                    surname={surname} setSurname={setSurname}
                    organisation={organisation} setOrganisation={setOrganisation}
                    organisations={organisations}
                    mail={mail} setMail={setMail}
                    status={status} setStatus={setStatus}
                    etat={etat} setEtat={setEtat}
                    phone={phone} setPhone={setPhone}
                    facebook={facebook} setFacebook={setFacebook}
                    website={website} setWebsite={setWebsite}
                    instagram={instagram} setInstagram={setInstagram}
                /> : null}
                {tabs === 2 ? <Adresse
                    address={address} setAddress={setAddress}
                    complement={complement} setComplement={setComplement}
                    code_post={code_post} setCode_post={setCode_post}
                    ville={ville} setVille={setVille}
                    pays={pays} setPays={setPays}
                    gps={gps} setGps={setGps}
                    url_gmap={url_gmap} setUrl_gmap={setUrl_gmap}
                    url_waze={url_waze} setUrl_waze={setUrl_waze}
                    url_plans={url_plans} setUrl_plans={setUrl_plans}
                /> : null}
                {tabs === 3 ? <Presentation
                    sol={sol} setSol={setSol}
                    espace={espace} setEspace={setEspace}
                    longueur={longueur} setLongueur={setLongueur}
                    noise={noise} setNoise={setNoise}
                    largeur={largeur} setLargeur={setLargeur}
                    longest_straight={longest_straight} setLongest={setLongest}
                    pres_text={pres_text} setPres_text={setPres_text}
                    saveFile={saveFile}
                    trace={trace} setTrace={setTrace}
                    img1={img1} setImg1={setImg1}
                    img2={img2} setImg2={setImg2}
                    img3={img3} setImg3={setImg3}
                    video1={video1} setVideo1={setVideo1}
                    video2={video2} setVideo2={setVideo2}
                /> : null}
                {tabs === 4 ? <Amenagements
                    removeFacilities={removeFromArray} Facilities={Facilities}
                    allFacilities={allFacilities} setAllFacilities={setAllFacilities}/> : null}
                {tabs === 5 ? <Licences
                    Homologations={Homologations} Licences={list_Licences}
                    allHomologations={allHomologations}
                    removeArray={removeFromArray}
                    setAllHomologations={setAllHomologations} allLicences={allLicences}
                    setAllLicences={setAllLicences}/> : null}
                {tabs === 6 ? <Vehicules
                    vehiculeTypes={vehiculeTypes} allVehicleTypes={allVehicleTypes}
                    setAllVehicleTypes={setAllVehicleTypes} removeArray={removeFromArray}
                    formSegmentValues={formSegmentValues}
                    handleSegmentChange={handleSegmentChange}
                    addSegmentFormFields={addSegmentFormFields}
                    removeSegmentFormFields={removeSegmentFormFields}
                    Segments={Segments}/> : null}
                {tabs === 7 ? <Horaires/> : null}
                {tabs === 8 ? <TextesPop
                    textCome={textCome}
                    setTextCome={setTextCome}
                    textFood={textFood}
                    setTextFood={setTextFood}
                    textOil={textOil}
                    setTextOil={setTextOil}
                    textHousing={textHousing}
                    setTextHousing={setTextHousing}
                /> : null}
            </div>
        </div>
    )
}

export default EditCircuits
