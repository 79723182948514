import axios from "../axios"

import {
    ACTIVITY_TYPES_CREATE_FAIL,
    ACTIVITY_TYPES_CREATE_REQUEST, ACTIVITY_TYPES_CREATE_SUCCESS,
    ACTIVITY_TYPES_DELETE_FAIL,
    ACTIVITY_TYPES_DELETE_REQUEST,
    ACTIVITY_TYPES_DELETE_SUCCESS,
    ACTIVITY_TYPES_DETAILS_FAIL,
    ACTIVITY_TYPES_DETAILS_REQUEST,
    ACTIVITY_TYPES_DETAILS_SUCCESS,
    ACTIVITY_TYPES_LIST_FAIL,
    ACTIVITY_TYPES_LIST_REQUEST,
    ACTIVITY_TYPES_LIST_SUCCESS,
    ACTIVITY_TYPES_UPDATE_FAIL,
    ACTIVITY_TYPES_UPDATE_REQUEST,
    ACTIVITY_TYPES_UPDATE_SUCCESS
} from "../constants/activityTypesConstants";

export const listActivityTypes = () => async (dispatch) => {
    try {
        dispatch({type: ACTIVITY_TYPES_LIST_REQUEST})
        const {data} = await axios.get('/activity_type')
        dispatch({
            type: ACTIVITY_TYPES_LIST_SUCCESS,
            payload: data.AllActivityTypes,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getActivityTypes = (id) => async (dispatch) => {
    try {
        dispatch({type: ACTIVITY_TYPES_DETAILS_REQUEST})
        const {data} = await axios.get(`/activity_type/${id}`)
        dispatch({
            type: ACTIVITY_TYPES_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteActivityTypes = (id) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_DELETE_REQUEST,
        })
        await axios.delete(`/activity_type/${id}`)

        dispatch({
            type: ACTIVITY_TYPES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createActivityTypes = (createdActivityTypes) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_CREATE_REQUEST,
        })
        await axios.post(`/activity_type`, createdActivityTypes)

        dispatch({
            type: ACTIVITY_TYPES_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateActivityTypes = (updatedActivityTypes, id) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIVITY_TYPES_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/activity_type/${id}`, updatedActivityTypes)

        dispatch({
            type: ACTIVITY_TYPES_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: ACTIVITY_TYPES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
