import axios from "../axios"

import {
    CHAMPIONNATS_CREATE_FAIL,
    CHAMPIONNATS_CREATE_REQUEST, CHAMPIONNATS_CREATE_SUCCESS,
    CHAMPIONNATS_DELETE_FAIL,
    CHAMPIONNATS_DELETE_REQUEST,
    CHAMPIONNATS_DELETE_SUCCESS,
    CHAMPIONNATS_DETAILS_FAIL,
    CHAMPIONNATS_DETAILS_REQUEST,
    CHAMPIONNATS_DETAILS_SUCCESS,
    CHAMPIONNATS_LIST_FAIL,
    CHAMPIONNATS_LIST_REQUEST,
    CHAMPIONNATS_LIST_SUCCESS,
    CHAMPIONNATS_UPDATE_FAIL,
    CHAMPIONNATS_UPDATE_REQUEST,
    CHAMPIONNATS_UPDATE_SUCCESS
} from "../constants/championnatsConstants";

export const listChampionnats = () => async (dispatch) => {
    try {
        dispatch({type: CHAMPIONNATS_LIST_REQUEST})
        const {data} = await axios.get('/championship')
        dispatch({
            type: CHAMPIONNATS_LIST_SUCCESS,
            payload: data.all_Championship,
        })
    } catch (error) {
        dispatch({
            type: CHAMPIONNATS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getChampionnats = (id) => async (dispatch) => {
    try {
        dispatch({type: CHAMPIONNATS_DETAILS_REQUEST})
        const {data} = await axios.get(`/championship/${id}`)
        dispatch({
            type: CHAMPIONNATS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: CHAMPIONNATS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteChampionnats = (id) => async (dispatch) => {
    try {
        dispatch({
            type: CHAMPIONNATS_DELETE_REQUEST,
        })
        await axios.delete(`/championship/${id}`)

        dispatch({
            type: CHAMPIONNATS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CHAMPIONNATS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createChampionnats = (createdChampionnats) => async (dispatch) => {
    try {
        dispatch({
            type: CHAMPIONNATS_CREATE_REQUEST,
        })
        await axios.post(`/championship`, createdChampionnats)

        dispatch({
            type: CHAMPIONNATS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: CHAMPIONNATS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateChampionnats = (updatedChampionnats, id) => async (dispatch) => {
    try {
        dispatch({
            type: CHAMPIONNATS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/championship/${id}`, updatedChampionnats)

        dispatch({
            type: CHAMPIONNATS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: CHAMPIONNATS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
