import React, {useEffect, useState} from 'react';
import "./SelectComponents.css"

const SelectComponents = props => {


    const [valueFilter, setValueFilter] = useState("")
    const [listFiltered, setListFiltered] = useState([])

    useEffect(() => {
        props.listToFiltered.forEach(data => {
            if (data.id === parseInt(props.value)) {
                setValueFilter(data.name)
            }
        })
    }, [props.value, props.listToFiltered])

    const updateInput = async (input) => {
        const filtered = props.listToFiltered.filter((data) => {
            return (
                data.name.toLowerCase().includes(input.toLowerCase())
            );
        });
        setValueFilter(input);
        setListFiltered(filtered);
    };

    return (
        <div>
            <input className="inputSelect" style={{marginBottom: "0px"}} type="text"
                   value={valueFilter}
                   onChange={e => updateInput(e.target.value)} onClick={e => updateInput(e.target.value)}
                   placeholder={props.placeholder}
            />
            <div className="dropdownSelect">
                {listFiltered.map(c => {
                    return (
                        <div
                            className="element_dropdown"
                            style={{background: "white", padding: 10, cursor: "pointer"}}
                            onClick={() => {
                                props.index !== undefined ? props.setValue(props.index, c.id) : props.setValue(c.id)
                                setValueFilter(c.name)
                                setListFiltered([])
                            }}
                        >
                            {c.name}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SelectComponents
