import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";


const TextesPop = (props) => {

    return (
        <div className="coordonnees" style={{width: "100%"}}>
            <div className="subCoord">
                <div className="names">
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2 className="first-subTitles">Textes</h2>
                    </div>
                    <br />

                    <p className="label-2">Venir</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textCome}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextCome(data)
                            } }
                        />
                    </div>

                    <p className="label-2">Se loger</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textHousing}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextHousing(data)
                            } }
                        />
                    </div>

                    <p className="label-2">Restauration</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textFood}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextFood(data)
                            } }
                        />
                    </div>


                    <p className="label-2">Carburants</p>
                    <div className="name">
                        <CKEditor
                            editor={ ClassicEditor }
                            data={props.textOil}
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                props.setTextOil(data)
                            } }
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TextesPop
