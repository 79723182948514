import axios from "../axios"

import {
    DOCUMENTS_CREATE_FAIL,
    DOCUMENTS_CREATE_REQUEST,
    DOCUMENTS_CREATE_SUCCESS,
    DOCUMENTS_DELETE_FAIL,
    DOCUMENTS_DELETE_REQUEST,
    DOCUMENTS_DELETE_SUCCESS,
    DOCUMENTS_DETAILS_FAIL,
    DOCUMENTS_DETAILS_REQUEST,
    DOCUMENTS_DETAILS_SUCCESS,
    DOCUMENTS_LIST_FAIL,
    DOCUMENTS_LIST_REQUEST,
    DOCUMENTS_LIST_SUCCESS,
    DOCUMENTS_UPDATE_FAIL,
    DOCUMENTS_UPDATE_REQUEST,
    DOCUMENTS_UPDATE_SUCCESS
} from "../constants/documentsConstants";

export const listDocuments = () => async (dispatch) => {
    try {
        dispatch({type: DOCUMENTS_LIST_REQUEST})
        const {data} = await axios.get('/documents')
        dispatch({
            type: DOCUMENTS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getDocuments = (id) => async (dispatch) => {
    try {
        dispatch({type: DOCUMENTS_DETAILS_REQUEST})
        const {data} = await axios.get(`/documents/${id}`)
        dispatch({
            type: DOCUMENTS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteDocuments = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DOCUMENTS_DELETE_REQUEST,
        })
        await axios.delete(`/documents/${id}`)

        dispatch({
            type: DOCUMENTS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createDocuments = (createdDocuments) => async (dispatch) => {
    try {
        dispatch({
            type: DOCUMENTS_CREATE_REQUEST,
        })
        await axios.post(`/documents`, createdDocuments)

        dispatch({
            type: DOCUMENTS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateDocuments = (updatedDocuments, id) => async (dispatch) => {
    try {
        dispatch({
            type: DOCUMENTS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/documents/${id}`, updatedDocuments)

        dispatch({
            type: DOCUMENTS_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: DOCUMENTS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
