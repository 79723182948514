import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import MuiAlert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {InfoRounded} from "@material-ui/icons";
import {createChampionnats} from "../../../../actions/championnatsActions";
import Snackbar from "@mui/material/Snackbar";
import {useNavigate} from "react-router-dom";
import {listOrganisations} from "../../../../actions/organisationsActions";
import {listDisciplines} from "../../../../actions/disciplinesActions";
import axios from "../../../../axios";
import {CHAMPIONNATS_CREATE_RESET} from '../../../../constants/championnatsConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewChampionnats = (props) => {
    let history = useNavigate()
    const dispatch = useDispatch()

    let championnatsCreate = useSelector(state => state.championnatsCreate)
    const {success: successCreate, error: errorCreate} = championnatsCreate

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let disciplinesList = useSelector(state => state.disciplinesList)
    const {Disciplines} = disciplinesList

    const [champName, setChampName] = useState("")
    const [champProfilePicture, setChampProfilePicture] = useState("")
    const [organizationSelected, setOrganizationSelected] = useState("")
    const [federationSelected, setFederationSelected] = useState("")
    const [disciplineSelected, setDisciplineSelected] = useState("")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }

    const saveFile = (e) => {
        setChampProfilePicture(e.target.files[0]);

    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        formDataProfile.append("file", champProfilePicture);
        formDataProfile.append("folder", "championship");
        formDataProfile.append("upload_preset", "zd2h6ivm")

        try {
            let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
            return [res.data.public_id]
        } catch (ex) {
            console.log(ex);
        }
    };

    const AddHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: champName,
                profile_picture: res[0],
                disciplines_id: disciplineSelected,
                organization_id: organizationSelected,
                federation_id: federationSelected,
            }

            dispatch(createChampionnats(data))
        })

    }

    useEffect(() => {
        dispatch(listOrganisations())
        dispatch(listDisciplines())
    }, [dispatch])
    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/championnats')
                dispatch({type: CHAMPIONNATS_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le championnats a bien été ajouté !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Championnats"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Championnats</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={champName}
                                   onChange={e => setChampName(e.target.value)}
                            />
                            <p className="label-2">Image de profil</p>
                            <input className="input" placeholder="Profile Picture"
                                   type="file"
                                   onChange={e => saveFile(e)}
                            />
                            <div>
                                <p className="label-2">Organisation</p>
                                <select className="state" value={organizationSelected}
                                        onChange={e => setOrganizationSelected(e.target.value)}>
                                    <option value="">-- Organisation --</option>
                                    {organisations.map(orga => {
                                        return <option key={orga.id}
                                                       value={orga.id}>{orga.name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <p className="label-2">Discipline</p>
                                <select className="state" value={disciplineSelected}
                                        onChange={e => setDisciplineSelected(e.target.value)}>
                                    <option value="">-- Disciplines --</option>
                                    {Disciplines.map(d => {
                                        return <option key={d.id} value={d.id}>{d.name}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <p className="label-2">Fédération</p>
                                <select className="state" value={federationSelected}
                                        onChange={e => setFederationSelected(e.target.value)}>
                                    <option value="">-- Fédération --</option>
                                    {organisations.map(orga => {
                                        if (orga.type === 'Fédération') {
                                            return <option key={orga.id}
                                                           value={orga.id}>{orga.name}</option>
                                        }
                                        return null
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewChampionnats
