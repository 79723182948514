import React from 'react'
import ProfileButton from "../../../components/profileButton";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import {useNavigate} from "react-router-dom";

const Bdd = () => {
    let history = useNavigate()
    const data = [
        {
            name: "Circuits",
            url: "/circuits"
        },
        {
            name: "Organisations",
            url: "/organisations"
        },
        {
            name: "Contact Commercial",
            url: "/contact-commercial"
        },
        {
            name: "Disciplines",
            url: "/disciplines"
        },
        {
            name: "Véhicules Types",
            url: "/vehicules-types"
        },
        {
            name: "Homologations",
            url: "/homologations"
        },
        {
            name: "Licences",
            url: "/licences"
        },
        {
            name: "Fiches d'activités",
            url: "/activite"
        },
        {
            name: "Facilities",
            url: "/facilities"
        },
        {
            name: "Championnat",
            url: "/championnat"
        },
        {
            name: "Catégories de course",
            url: "/race-category"
        },
    ]

    return (
        <div>
            <div>
                <ProfileButton/>
                <br/>
                <div className="breadcrumb">
                    <HomeRoundedIcon className="home_icon" onClick={() => history('/admin')}/>
                    <ArrowForwardIosRoundedIcon className="arrow_icon"/>
                    <span className="text" onClick={() => history('')}>Bdd</span>
                </div>
                <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                    <h2 className="page_title">Base de données</h2>
                </div>
                <div style={{display: "flex", flexWrap: "wrap", marginRight: "45px"}}>
                    {data?.map((elt, index) => {
                        return (
                            <div key={index + elt.name} className="cardBdd">
                                <h3 className="title">{elt.name}</h3>
                                <a className="link" href={elt.url}>Y aller</a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Bdd
