import axios from "../axios"

import {
    FACILITIES_CREATE_FAIL,
    FACILITIES_CREATE_REQUEST, FACILITIES_CREATE_SUCCESS,
    FACILITIES_DELETE_FAIL,
    FACILITIES_DELETE_REQUEST,
    FACILITIES_DELETE_SUCCESS,
    FACILITIES_DETAILS_FAIL,
    FACILITIES_DETAILS_REQUEST,
    FACILITIES_DETAILS_SUCCESS,
    FACILITIES_LIST_FAIL,
    FACILITIES_LIST_REQUEST,
    FACILITIES_LIST_SUCCESS,
    FACILITIES_UPDATE_FAIL,
    FACILITIES_UPDATE_REQUEST,
    FACILITIES_UPDATE_SUCCESS
} from "../constants/facilitiesConstants";

export const listFacilities = () => async (dispatch) => {
    try {
        dispatch({type: FACILITIES_LIST_REQUEST})
        const {data} = await axios.get('/facilities')
        dispatch({
            type: FACILITIES_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: FACILITIES_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getFacilities = (id) => async (dispatch) => {
    try {
        dispatch({type: FACILITIES_DETAILS_REQUEST})
        const {data} = await axios.get(`/facilities/${id}`)
        dispatch({
            type: FACILITIES_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: FACILITIES_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteFacilities = (id) => async (dispatch) => {
    try {
        dispatch({
            type: FACILITIES_DELETE_REQUEST,
        })
        await axios.delete(`/facilities/${id}`)

        dispatch({
            type: FACILITIES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: FACILITIES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createFacilities = (createdFacilities) => async (dispatch) => {
    try {
        dispatch({
            type: FACILITIES_CREATE_REQUEST,
        })
        await axios.post(`/facilities`, createdFacilities)

        dispatch({
            type: FACILITIES_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: FACILITIES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateFacilities = (updatedFacilities, id) => async (dispatch) => {
    try {
        dispatch({
            type: FACILITIES_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/facilities/${id}`, updatedFacilities)

        dispatch({
            type: FACILITIES_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: FACILITIES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
