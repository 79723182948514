import {
    DISCIPLINES_CREATE_FAIL,
    DISCIPLINES_CREATE_REQUEST,
    DISCIPLINES_CREATE_SUCCESS,
    DISCIPLINES_CREATE_RESET,
    DISCIPLINES_DELETE_FAIL,
    DISCIPLINES_DELETE_REQUEST,
    DISCIPLINES_DELETE_SUCCESS,
    DISCIPLINES_DETAILS_FAIL,
    DISCIPLINES_DETAILS_REQUEST,
    DISCIPLINES_DETAILS_SUCCESS,
    DISCIPLINES_LIST_FAIL,
    DISCIPLINES_LIST_REQUEST,
    DISCIPLINES_LIST_SUCCESS,
    DISCIPLINES_UPDATE_FAIL,
    DISCIPLINES_UPDATE_REQUEST,
    DISCIPLINES_UPDATE_SUCCESS,
    DISCIPLINES_UPDATE_RESET
} from "../constants/disciplinesConstants";

export const disciplinesListReducers = (state = {Disciplines: []}, action) => {
    switch (action.type) {
        case DISCIPLINES_LIST_REQUEST:
            return {loading: true, Disciplines: []}
        case DISCIPLINES_LIST_SUCCESS:
            return {loading: false, Disciplines: action.payload}
        case DISCIPLINES_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const disciplinesDetailsReducers = (state = {Disciplines: {}}, action) => {
    switch (action.type) {
        case DISCIPLINES_DETAILS_REQUEST:
            return {loading: true, ...state}
        case DISCIPLINES_DETAILS_SUCCESS:
            return {loading: false, Disciplines: action.payload}
        case DISCIPLINES_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const disciplinesCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case DISCIPLINES_CREATE_REQUEST:
            return {loading: true}
        case DISCIPLINES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case DISCIPLINES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case DISCIPLINES_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const disciplinesDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case DISCIPLINES_DELETE_REQUEST:
            return {loading: true}
        case DISCIPLINES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case DISCIPLINES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const disciplinesUpdateReducers = (state = {Disciplines: {}}, action) => {
    switch (action.type) {
        case DISCIPLINES_UPDATE_REQUEST:
            return {loading: true}
        case DISCIPLINES_UPDATE_SUCCESS:
            return {loading: false, success: true, Disciplines: action.payload}
        case DISCIPLINES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case DISCIPLINES_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
