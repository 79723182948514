export const LEVELS_LIST_REQUEST = "LEVELS_LIST_REQUEST"
export const LEVELS_LIST_SUCCESS = "LEVELS_LIST_SUCCESS"
export const LEVELS_LIST_FAIL = "LEVELS_LIST_FAIL"

export const LEVELS_DETAILS_REQUEST = "LEVELS_DETAILS_REQUEST"
export const LEVELS_DETAILS_SUCCESS = "LEVELS_DETAILS_SUCCESS"
export const LEVELS_DETAILS_FAIL = "LEVELS_DETAILS_FAIL"

export const LEVELS_CREATE_REQUEST = "LEVELS_CREATE_REQUEST"
export const LEVELS_CREATE_SUCCESS = "LEVELS_CREATE_SUCCESS"
export const LEVELS_CREATE_FAIL = "LEVELS_CREATE_FAIL"
export const LEVELS_CREATE_RESET = "LEVELS_CREATE_RESET"

export const LEVELS_DELETE_REQUEST = "LEVELS_DELETE_REQUEST"
export const LEVELS_DELETE_SUCCESS = "LEVELS_DELETE_SUCCESS"
export const LEVELS_DELETE_FAIL = "LEVELS_DELETE_FAIL"

export const LEVELS_UPDATE_REQUEST = "LEVELS_UPDATE_REQUEST"
export const LEVELS_UPDATE_SUCCESS = "LEVELS_UPDATE_SUCCESS"
export const LEVELS_UPDATE_FAIL = "LEVELS_UPDATE_FAIL"
export const LEVELS_UPDATE_RESET = "LEVELS_UPDATE_RESET"
