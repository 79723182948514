import axios from "../axios"

import {
    DICTIONNAIRE_CREATE_FAIL,
    DICTIONNAIRE_CREATE_REQUEST,
    DICTIONNAIRE_CREATE_SUCCESS,
    DICTIONNAIRE_DELETE_FAIL,
    DICTIONNAIRE_DELETE_REQUEST,
    DICTIONNAIRE_DELETE_SUCCESS,
    DICTIONNAIRE_DETAILS_FAIL,
    DICTIONNAIRE_DETAILS_REQUEST,
    DICTIONNAIRE_DETAILS_SUCCESS,
    DICTIONNAIRE_LIST_FAIL,
    DICTIONNAIRE_LIST_REQUEST,
    DICTIONNAIRE_LIST_SUCCESS,
    DICTIONNAIRE_UPDATE_FAIL,
    DICTIONNAIRE_UPDATE_REQUEST,
    DICTIONNAIRE_UPDATE_SUCCESS
} from "../constants/dictionnaireConstants";

export const listDictionnaire = () => async (dispatch) => {
    try {
        dispatch({type: DICTIONNAIRE_LIST_REQUEST})
        const {data} = await axios.get('/dictionnaire')
        dispatch({
            type: DICTIONNAIRE_LIST_SUCCESS,
            payload: data.allDictionnaire,
        })
    } catch (error) {
        dispatch({
            type: DICTIONNAIRE_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getDictionnaire = (id) => async (dispatch) => {
    try {
        dispatch({type: DICTIONNAIRE_DETAILS_REQUEST})
        const {data} = await axios.get(`/dictionnaire/${id}`)
        dispatch({
            type: DICTIONNAIRE_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DICTIONNAIRE_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteDictionnaire = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DICTIONNAIRE_DELETE_REQUEST,
        })
        await axios.delete(`/dictionnaire/${id}`)

        dispatch({
            type: DICTIONNAIRE_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DICTIONNAIRE_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createDictionnaire = (createdDictionnaire) => async (dispatch) => {
    try {
        dispatch({
            type: DICTIONNAIRE_CREATE_REQUEST,
        })
        await axios.post(`/dictionnaire`, createdDictionnaire)

        dispatch({
            type: DICTIONNAIRE_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DICTIONNAIRE_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateDictionnaire = (updatedDictionnaire, id) => async (dispatch) => {
    try {
        dispatch({
            type: DICTIONNAIRE_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/dictionnaire/${id}`, updatedDictionnaire)

        dispatch({
            type: DICTIONNAIRE_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: DICTIONNAIRE_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
