import React, {useEffect, useState} from 'react'
import '../../assets/css/sidebar.css';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import StorageIcon from '@material-ui/icons/Storage';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ArticleIcon from '@mui/icons-material/Article';
import {useNavigate} from 'react-router-dom';

const Sidebar = (props) => {

    let history = useNavigate()


    const [subMenuOpenBdd, setSubMenuOpenBdd] = useState(true);
    const [subMenuOpenOffers, setSubMenuOpenOffers] = useState(true);


    useEffect(() => {
        console.log(subMenuOpenBdd);
    }, [subMenuOpenBdd])
    return (<div style={{display: "flex"}}>
            <div className="sidebar">
                <div>
                    <span className="logo_name">Accelerio</span>
                </div>
                <ul className="nav-links">

                    <li>
                        <div
                            className={subMenuOpenBdd ? "icon_link showMenu" : "icon_link"}
                            onClick={() => {
                                setSubMenuOpenBdd(!subMenuOpenBdd)
                            }}>
                            <p>
                                <i className="img"><StorageIcon/></i>
                                <span className="link_name">BDD</span>
                            </p>
                            <i className="arrow"><ArrowForwardIosRoundedIcon/></i>
                        </div>
                        <ul className={subMenuOpenBdd ? "sub-menu showSubMenu" : "sub-menu"}>
                            <li><p
                                onClick={() => history('/facilities')}>Aménagements</p>
                            </li>
                            <li><p onClick={() => history('/circuits')}>Circuits</p>
                            </li>
                            <li><p
                                onClick={() => history('/contact-commercial')}>Contacts
                                Commercial</p></li>
                            <li><p
                                onClick={() => history('/championnats')}>Championnats</p>
                            </li>
                            <li><p
                                onClick={() => history('/race-category')}>Catégories
                                (races)</p></li>
                            <li><p
                                onClick={() => history('/disciplines')}>Disciplines</p>
                            </li>
                            <li><p
                                onClick={() => history('/documents')}>Documents</p>
                            </li>
                            <li><p onClick={() => history('/activite')}>Fiches
                                d'activités</p></li>
                            <li><p
                                onClick={() => history('/homologations')}>Homologations</p>
                            </li>
                            <li><p onClick={() => history('/licences')}>Licences</p>
                            </li>
                            <li><p onClick={() => history('/levels')}>Niveaux</p>
                            </li>
                            <li><p
                                onClick={() => history('/organisations')}>Organisations</p>
                            </li>
                            <li><p onClick={() => history('/pilotes')}>Pilotes</p>
                            </li>
                            <li><p onClick={() => history('/segments')}>Segments</p>
                            </li>
                            <li><p
                                onClick={() => history('/vehicules-types')}>Véhicules
                                types</p></li>
                        </ul>
                    </li>
                    <li>
                        <div
                            className={subMenuOpenOffers ? "icon_link showMenu" : "icon_link"}
                            onClick={() => {
                                setSubMenuOpenOffers(!subMenuOpenOffers)
                            }}>
                            <p>
                                <i className="img"><ChromeReaderModeIcon/></i>
                                <span className="link_name">Offres</span>
                            </p>
                            <i className="arrow"><ArrowForwardIosRoundedIcon/></i>
                        </div>
                        <ul className={subMenuOpenOffers ? "sub-menu showSubMenu" : "sub-menu"}>
                            <li><p onClick={() => history('/offres')}>Mes offres de
                                roulage</p></li>
                        </ul>
                    </li>

                </ul>
            </div>
            <div className="page">
                {props.children}
            </div>

        </div>)
}

export default Sidebar
