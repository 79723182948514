import React, {useEffect, useState} from 'react';
import Snackbar from "@mui/material/Snackbar";
import ProfileButton from "../../../../components/profileButton";
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import SettingsIcon from "@material-ui/icons/Settings";
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {createActivityTypes} from "../../../../actions/activityTypesActions";
import MuiAlert from "@mui/material/Alert";
import {useNavigate} from "react-router-dom";
import { ACTIVITY_TYPES_CREATE_RESET } from '../../../../constants/activityTypesConstants';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewSimpleActivityTypes = (props) => {

    let history = useNavigate()
    const dispatch = useDispatch()

    let activityTypesCreate = useSelector(state => state.activityTypesCreate)
    const {success: successCreate, error: errorCreate} = activityTypesCreate


    const [name, setName] = useState("")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const AddHandler = () => {
        let data = {
            name: name,
        }
        if (data.name !== "")
            dispatch(createActivityTypes(data))
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setTimeout(() => {
                setOpen(false)
                history('/activite')
                dispatch({type: ACTIVITY_TYPES_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    return(
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    L'activité a bien été ajoutée !
                </Alert>
            </Snackbar>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
                    {errorCreate}
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Activite"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Activité</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewSimpleActivityTypes
