import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import thunk from 'redux-thunk'
import {
    circuitsCreateReducers,
    circuitsDeleteReducers,
    circuitsDetailsReducers,
    circuitsListReducers,
    circuitsUpdateReducers
} from "./reducers/circuitsReducers";
import {
    organisationsCreateReducers,
    organisationsDeleteReducers,
    organisationsDetailsReducers,
    organisationsListReducers,
    organisationsUpdateReducers
} from "./reducers/organisationsReducers";
import {
    contactCommercialCreateReducers,
    contactCommercialDeleteReducers,
    contactCommercialDetailsReducers,
    contactCommercialListReducers,
    contactCommercialUpdateReducers
} from "./reducers/contactCommercialReducers";
import {
    disciplinesCreateReducers,
    disciplinesDeleteReducers,
    disciplinesDetailsReducers,
    disciplinesListReducers,
    disciplinesUpdateReducers
} from "./reducers/disciplinesReducers";
import {
    vehiculeTypeCreateReducers,
    vehiculeTypeDeleteReducers,
    vehiculeTypeDetailsReducers,
    vehiculeTypeListReducers,
    vehiculeTypeUpdateReducers
} from "./reducers/vehiculeTypeReducers";
import {
    licencesCreateReducers,
    licencesDeleteReducers,
    licencesDetailsReducers,
    licencesListReducers,
    licencesUpdateReducers
} from "./reducers/licencesReducers";
import {
    activityTypesCreateReducers,
    activityTypesDeleteReducers,
    activityTypesDetailsReducers,
    activityTypesListReducers,
    activityTypesUpdateReducers
} from "./reducers/activityTypesReducers";
import {
    homologationsCreateReducers,
    homologationsDeleteReducers,
    homologationsDetailsReducers,
    homologationsListReducers,
    homologationsUpdateReducers
} from "./reducers/homologationsReducers";
import {
    facilitiesCreateReducers,
    facilitiesDeleteReducers,
    facilitiesDetailsReducers,
    facilitiesListReducers,
    facilitiesUpdateReducers
} from "./reducers/facilitiesReducers";
import {
    championnatsCreateReducers,
    championnatsDeleteReducers,
    championnatsDetailsReducers,
    championnatsListReducers,
    championnatsUpdateReducers
} from "./reducers/championnatsReducers";
import {
    raceCategoryCreateReducers,
    raceCategoryDeleteReducers,
    raceCategoryDetailsReducers,
    raceCategoryListReducers,
    raceCategoryUpdateReducers
} from "./reducers/raceCategoryReducers";
import {
    offersCreateReducers,
    offersDeleteReducers,
    offersDetailsReducers,
    offersListReducers,
    offersUpdateReducers
} from "./reducers/offersReducers";
import {
    activityPhotosTypesCreateReducers,
    activityPhotosTypesDeleteReducers,
    activityPhotosTypesDetailsReducers,
    activityPhotosTypesListReducers,
    activityPhotosTypesUpdateReducers
} from "./reducers/activityPhotosTypesReducers";
import {
    segmentsCreateReducers,
    segmentsDeleteReducers,
    segmentsDetailsReducers,
    segmentsListReducers,
    segmentsUpdateReducers
} from './reducers/segmentsReducers';
import {
    dictionnaireCreateReducers,
    dictionnaireDeleteReducers,
    dictionnaireDetailsReducers,
    dictionnaireListReducers,
    dictionnaireUpdateReducers
} from './reducers/dictionnaireReducers';
import {
    pilotsCreateReducers,
    pilotsDeleteReducers,
    pilotsDetailsReducers,
    pilotsListReducers,
    pilotsUpdateReducers
} from "./reducers/pilotsReducers";
import {
    levelsCreateReducers,
    levelsDeleteReducers,
    levelsDetailsReducers,
    levelsListReducers,
    levelsUpdateReducers
} from './reducers/levelsReducers';
import {
    documentsCreateReducers,
    documentsDeleteReducers,
    documentsDetailsReducers,
    documentsListReducers,
    documentsUpdateReducers
} from './reducers/documentsReducers';


const reducer = combineReducers({
    circuitsList: circuitsListReducers,
    circuitsDetails: circuitsDetailsReducers,
    circuitsDelete: circuitsDeleteReducers,
    circuitsCreate: circuitsCreateReducers,
    circuitsUpdate: circuitsUpdateReducers,
    organisationsList: organisationsListReducers,
    organisationsDetails: organisationsDetailsReducers,
    organisationsDelete: organisationsDeleteReducers,
    organisationsCreate: organisationsCreateReducers,
    organisationsUpdate: organisationsUpdateReducers,
    pilotsList: pilotsListReducers,
    pilotsDetails: pilotsDetailsReducers,
    pilotsDelete: pilotsDeleteReducers,
    pilotsCreate: pilotsCreateReducers,
    pilotsUpdate: pilotsUpdateReducers,
    contactCommercialList: contactCommercialListReducers,
    contactCommercialDetails: contactCommercialDetailsReducers,
    contactCommercialDelete: contactCommercialDeleteReducers,
    contactCommercialCreate: contactCommercialCreateReducers,
    contactCommercialUpdate: contactCommercialUpdateReducers,
    disciplinesList: disciplinesListReducers,
    disciplinesDetails: disciplinesDetailsReducers,
    disciplinesDelete: disciplinesDeleteReducers,
    disciplinesCreate: disciplinesCreateReducers,
    disciplinesUpdate: disciplinesUpdateReducers,
    vehiculeTypesList: vehiculeTypeListReducers,
    vehiculeTypesDetails: vehiculeTypeDetailsReducers,
    vehiculeTypesDelete: vehiculeTypeDeleteReducers,
    vehiculeTypesCreate: vehiculeTypeCreateReducers,
    vehiculeTypesUpdate: vehiculeTypeUpdateReducers,
    licencesList: licencesListReducers,
    licencesDetails: licencesDetailsReducers,
    licencesDelete: licencesDeleteReducers,
    licencesCreate: licencesCreateReducers,
    licencesUpdate: licencesUpdateReducers,
    activityTypesList: activityTypesListReducers,
    activityTypesDetails: activityTypesDetailsReducers,
    activityTypesDelete: activityTypesDeleteReducers,
    activityTypesCreate: activityTypesCreateReducers,
    activityTypesUpdate: activityTypesUpdateReducers,
    activityPhotosTypesList: activityPhotosTypesListReducers,
    activityPhotosTypesDetails: activityPhotosTypesDetailsReducers,
    activityPhotosTypesDelete: activityPhotosTypesDeleteReducers,
    activityPhotosTypesCreate: activityPhotosTypesCreateReducers,
    activityPhotosTypesUpdate: activityPhotosTypesUpdateReducers,
    homologationsList: homologationsListReducers,
    homologationsDetails: homologationsDetailsReducers,
    homologationsDelete: homologationsDeleteReducers,
    homologationsCreate: homologationsCreateReducers,
    homologationsUpdate: homologationsUpdateReducers,
    facilitiesList: facilitiesListReducers,
    facilitiesDetails: facilitiesDetailsReducers,
    facilitiesDelete: facilitiesDeleteReducers,
    facilitiesCreate: facilitiesCreateReducers,
    facilitiesUpdate: facilitiesUpdateReducers,
    championnatsList: championnatsListReducers,
    championnatsDetails: championnatsDetailsReducers,
    championnatsDelete: championnatsDeleteReducers,
    championnatsCreate: championnatsCreateReducers,
    championnatsUpdate: championnatsUpdateReducers,
    raceCategoryList: raceCategoryListReducers,
    raceCategoryDetails: raceCategoryDetailsReducers,
    raceCategoryDelete: raceCategoryDeleteReducers,
    raceCategoryCreate: raceCategoryCreateReducers,
    raceCategoryUpdate: raceCategoryUpdateReducers,
    offersList: offersListReducers,
    offersDetails: offersDetailsReducers,
    offersDelete: offersDeleteReducers,
    offersCreate: offersCreateReducers,
    offersUpdate: offersUpdateReducers,
    levelsList: levelsListReducers,
    levelsDetails: levelsDetailsReducers,
    levelsDelete: levelsDeleteReducers,
    levelsCreate: levelsCreateReducers,
    levelsUpdate: levelsUpdateReducers,
    documentsList: documentsListReducers,
    documentsDetails: documentsDetailsReducers,
    documentsDelete: documentsDeleteReducers,
    documentsCreate: documentsCreateReducers,
    documentsUpdate: documentsUpdateReducers,
    segmentsList: segmentsListReducers,
    segmentsDetails: segmentsDetailsReducers,
    segmentsCreate: segmentsCreateReducers,
    segmentsUpdate: segmentsUpdateReducers,
    segmentsDelete: segmentsDeleteReducers,
    dictionnaireList: dictionnaireListReducers,
    dictionnaireDetails: dictionnaireDetailsReducers,
    dictionnaireCreate: dictionnaireCreateReducers,
    dictionnaireUpdate: dictionnaireUpdateReducers,
    dictionnaireDelete: dictionnaireDeleteReducers,
})


const middleware = [thunk]

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(...middleware)),
)

export default store
