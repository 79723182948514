import axios from "../axios"

import {
    OFFERS_CREATE_FAIL,
    OFFERS_CREATE_REQUEST,
    OFFERS_CREATE_SUCCESS,
    OFFERS_DELETE_FAIL,
    OFFERS_DELETE_REQUEST,
    OFFERS_DELETE_SUCCESS,
    OFFERS_DETAILS_FAIL,
    OFFERS_DETAILS_REQUEST,
    OFFERS_DETAILS_SUCCESS,
    OFFERS_LIST_FAIL,
    OFFERS_LIST_REQUEST,
    OFFERS_LIST_SUCCESS,
    OFFERS_UPDATE_FAIL,
    OFFERS_UPDATE_REQUEST,
    OFFERS_UPDATE_SUCCESS
} from "../constants/offersConstants";

export const listOffers = () => async (dispatch) => {
    try {
        dispatch({type: OFFERS_LIST_REQUEST})
        const {data} = await axios.get('/offers')
        dispatch({
            type: OFFERS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: OFFERS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getOffers = (id) => async (dispatch) => {
    try {
        dispatch({type: OFFERS_DETAILS_REQUEST})
        const {data} = await axios.get(`/offers/${id}`)
        dispatch({
            type: OFFERS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: OFFERS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteOffers = (id) => async (dispatch) => {
    try {
        dispatch({
            type: OFFERS_DELETE_REQUEST,
        })
        await axios.delete(`/offers/${id}`)

        dispatch({
            type: OFFERS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: OFFERS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createOffers = (createdOffers) => async (dispatch) => {
    try {
        dispatch({
            type: OFFERS_CREATE_REQUEST,
        })
        await axios.post(`/offers`, createdOffers)
        
        dispatch({
            type: OFFERS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: OFFERS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateOffers = (id, updatedOffers) => async (dispatch) => {
    try {
        dispatch({
            type: OFFERS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/offers/${id}`, updatedOffers)

        dispatch({
            type: OFFERS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: OFFERS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
