import React from 'react'
import ContactsIcon from '@material-ui/icons/Contacts';
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Vehicules = (props) => {

    return (
        <div className="vehicules">
            <div className="subPres">
                <p  className="first-subTitles">Véhicules autorisés</p>
                <div className="segmentVehicleContainer">
                {props.Segments.map(segment => {
                    return (
                        <div className="segmentVehicle">
                        <p className="first-subTitles">{segment.name}</p>
                        <div className="subPres">
                            {props.vehiculeTypes?.map((vehicleType, index) => {
                                if (segment.name === vehicleType.category && props.allVehicleTypes.includes(vehicleType.id)) {
                                    return (
                                        <button className="am-button actif" onClick={
                                            () => {
                                                props.removeArray(vehicleType.id, props.allVehicleTypes, props.setAllVehicleTypes)
                                            }}>
                                            {vehicleType.name}
                                        </button>)
                                } else if (segment.name === vehicleType.category && !props.allVehicleTypes.includes(vehicleType.id)) {
                                    return (
                                        <button className="am-button"
                                                onClick={() => props.setAllVehicleTypes([...props.allVehicleTypes, vehicleType.id])}>
                                            {vehicleType.name}
                                        </button>)
                                }
                                return null
                            })}
                        </div>
                    </div>)
                })}
                </div>
            </div>
            <div className="vehicule-status">
                <p className="vehicule-first-subTitles">Choisissez votre style de circuit</p>
                    <div className="vehicule-names">
                    {props.formSegmentValues.map((element, index) => (
                        <div className="vehicule-name" key={`${element}_${index}`}>
                            <ContactsIcon className="vehicule-name-icon"/>
                            <select className="state" value={element.segmentId}
                                    onChange={e => props.handleSegmentChange(index, e)}>
                                <option className="sub" value="">-- Choisissez votre segment --</option>
                                {props.Segments.map(segment => {
                                    return <option key={segment.id} value={segment.id}>{segment.name}</option>
                                })}
                            </select>
                            {
                                index ?
                                    <button type="button" className="button-remove"
                                            onClick={() => props.removeSegmentFormFields(index)}>Remove</button>
                                    : null
                            }
                        </div>
                    ))}
                    <button className="button-add" type="button" onClick={() => props.addSegmentFormFields()}>Add
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Vehicules