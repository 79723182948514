import React from 'react'
import BreadcrumbBDD from "../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../components/profileButton';
import '../../../assets/css/backOfficeBdd.css'
import {useNavigate} from 'react-router-dom';
import {MuiThemeProvider} from "@material-ui/core";
import {getMuiTheme} from "../../../assets/css/MuiTableDesign";
import {useDispatch} from "react-redux";

const Mails = (props) => {


    return (
        <MuiThemeProvider theme={getMuiTheme}>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Mails"/>
            <div style={{marginBottom: "35px", marginTop: "75px", display: "flex"}}>
                <h2 className="page_title">Mails</h2>
            </div>
        </MuiThemeProvider>
    )
}

export default Mails
