import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {listVehicleTypes} from "../../../../actions/vehiculeTypeAction";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {listActivityTypes} from "../../../../actions/activityTypesActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "../../../../axios";
import {ACTIVITY_TYPES_CREATE_RESET} from '../../../../constants/activityTypesConstants';
import {createActivityPhotosTypes} from "../../../../actions/activityPhotosTypesActions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewActivityTypes = (props) => {
    const history = useNavigate()
    const dispatch = useDispatch()

    let vehiculeTypesList = useSelector(state => state.vehiculeTypesList)
    const {vehiculeTypes} = vehiculeTypesList

    let activityPhotosTypesCreate = useSelector(state => state.activityPhotosTypesCreate)
    const {success: successCreate, error: errorCreate} = activityPhotosTypesCreate

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {ActivityTypes: activityTypesSimple} = activityTypesList

    const [activityType, setActivityType] = useState("")
    const [vehiculeType, setVehiculeType] = useState("")
    const [typeProfilePicture, setTypeProfilePicture] = useState()
    const [typeThumbnailPicture, setTypeThumbnailPicture] = useState()

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    const saveFile = (e, type) => {
        switch (type) {
            case "Profile":
                setTypeProfilePicture(e.target.files[0]);
                break
            case "Thumbnail":
                setTypeThumbnailPicture(e.target.files[0]);
                break
            default:
                break
        }

    };

    const uploadFile = async () => {
        setLoading(true)
        const formDataProfile = new FormData();
        formDataProfile.append("file", typeProfilePicture);
        formDataProfile.append("folder", "activity/header");
        formDataProfile.append("upload_preset", "zd2h6ivm")

        const formDataThumbnail = new FormData();
        formDataThumbnail.append("file", typeThumbnailPicture);
        formDataThumbnail.append("folder", "activity/thumbnail");
        formDataThumbnail.append("upload_preset", "zd2h6ivm")

        try {
            let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
            let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataThumbnail)
            return [res.data.public_id, res2.data.public_id]
        } catch (ex) {
            console.log(ex);
        }
    };

    const handleClose = () => {
        setOpen(false)
    }

    const AddHandler = async () => {
        uploadFile().then((res) => {
                let data = {
                    activityType: activityType,
                    vehicleType: vehiculeType,
                    profilePicture: res[0],
                    thumbnail: res[1],
                }

                dispatch(createActivityPhotosTypes(data))
            }
        )
    }

    useEffect(() => {
        if (successCreate) {
            setOpen(true)
            setLoading(false)
            setTimeout(() => {
                setOpen(false)
                history('/activite')
                dispatch({type: ACTIVITY_TYPES_CREATE_RESET})
            }, 1000)
        } else if (errorCreate) {
            setErrorOpen(true)
            setTimeout(() => {
                setErrorOpen(false)
            }, 1000)
        }
    }, [dispatch, successCreate, errorCreate, history])

    useEffect(() => {
        dispatch(listActivityTypes())
        dispatch(listVehicleTypes())
    }, [dispatch])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Cela a bien été ajouté !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <BreadcrumbBDD name="Activite"/>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Activités</h2>
                <button className="modify_button" onClick={() => AddHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Type d'activité</p>
                            <select className="state" value={activityType}
                                    onChange={e => setActivityType(e.target.value)}>
                                <option className="sub" value="">-- Choisissez un type d'activité
                                    --
                                </option>
                                {activityTypesSimple.map(activity => {
                                    return <option key={activity.id}
                                                   value={activity.id}>{activity.name}</option>
                                })}
                            </select>
                            <p className="label-2">Type de véhicule</p>
                            <select className="state" value={vehiculeType}
                                    onChange={e => setVehiculeType(e.target.value)}>
                                <option className="sub" value="">-- Choisissez un type de véhicule
                                    --
                                </option>
                                {vehiculeTypes.map(vt => {
                                    return <option key={vt.id} value={vt.id}>{vt.name}</option>
                                })}
                            </select>
                            <p className="label-2">Image de profil</p>
                            <input className="input" placeholder="Header"
                                   type="file"
                                   onChange={e => saveFile(e, "Profile")}
                            />
                            <p className="label-2">Miniature</p>
                            <input className="input" placeholder="Thumbnail picture"
                                   type="file"
                                   onChange={e => saveFile(e, "Thumbnail")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewActivityTypes
