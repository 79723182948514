import React from 'react'
import Calendar from "../../pages/admin/bdd/Calendar";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";

const CategoryOffers = props => {

    let {formCategory, setFormCategory, variant, category} = props

    let day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"]
    let month = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"]
    let handleCategory = (i, e) => {
        let newFormValues = [...formCategory];
        newFormValues[i][e.target.name] = e.target.value;
        setFormCategory(newFormValues);
    }
    let handleCheckboxCategory = (i, e) => {
        let newFormValues = [...formCategory];
        newFormValues[i][e.target.name] = !newFormValues[i][e.target.name];
        setFormCategory(newFormValues);
    }

    let handleCheckboxCategoryVariants = (indexCategory, indexVariant, e) => {
        let newFormValues = [...formCategory];
        newFormValues[indexCategory]['variants'][indexVariant][e.target.name] = !newFormValues[indexCategory]['variants'][indexVariant][e.target.name];
        setFormCategory(newFormValues);
    }

    let handleVariants = (indexCategory, indexVariant, e) => {
        let newFormValues = [...formCategory];
        newFormValues[indexCategory]['variants'][indexVariant][e.target.name] = e.target.value;
        setFormCategory(newFormValues);
    }

    let handleCalendar = (indexCategory, indexVariant, e) => {
        if (e.length > 0) {
            let splitDate = e[0].split('/')
            let newDate = new Date()
            newDate.setDate(splitDate[0])
            newDate.setMonth(parseInt(splitDate[1]) - 1)
            newDate.setFullYear(splitDate[2])

            let splitEndDate = e[e.length - 1].split('/')
            let newEndDate = new Date()
            newEndDate.setDate(splitEndDate[0])
            newEndDate.setMonth(parseInt(splitEndDate[1]) - 1)
            newEndDate.setFullYear(splitEndDate[2])

            let newFormValues = [...formCategory];

            newFormValues[indexCategory]['variants'][indexVariant]["startDate"] = newDate.getTime();
            newFormValues[indexCategory]['variants'][indexVariant]["endDate"] = newEndDate.getTime();
            setFormCategory(newFormValues);
        }
    }

    let addVariants = (indexOptions) => {
        let newFormValues = [...formCategory];
        newFormValues[indexOptions]['variants'].push(variant)
        setFormCategory(newFormValues)
    }

    let removeVariants = (indexOptions, indexVariant) => {
        let newFormValues = [...formCategory];
        newFormValues[indexOptions]['variants'].splice(indexVariant, 1);
        setFormCategory(newFormValues)
    }

    let addOptions = () => {
        setFormCategory([...formCategory, category])
    }

    let removeOptions = (i) => {
        let newFormValues = [...formCategory];
        newFormValues.splice(i, 1);
        setFormCategory(newFormValues)
    }

    const getFormattedDate = (date) => {
        if (date) {
            let newDate = new Date(date)
            let newDay = day[newDate.getDay()]
            let newMonth = month[newDate.getMonth()]
            let numberDay = newDate.getDate()
            return `${newDay} ${numberDay} ${newMonth}`
        }
    }

    return (<div className="coordonnees" style={{color: "white", width: "100%"}}>
        <div className="subCoord" style={{flexDirection: "column"}}>
            {formCategory.map( (element, index) => {
                return <div className="names" style={{width: "80%"}}>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <h2>{element.title || ("Catégorie n°" + index)}</h2>
                        <div
                            style={{display: "flex", border: "1px solid white", borderRadius: "10px", padding: "15px"}}>
                            <input style={{margin: "auto"}} type="checkbox" id="option" name="option" checked={element.option} onClick={e => handleCheckboxCategory(index, e)}/>
                            <label style={{marginLeft: "20px"}} htmlFor="option">Ceci est une option</label>
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <div style={{width: "50%"}}>
                            <p className="label-2">Nom de la catégorie</p>
                            <div className="name">
                                <input className="input-offer-desc" placeholder="Nom de la catégorie"
                                       value={element.title}
                                       onChange={e => handleCategory(index, e)}
                                       style={{margin: "auto"}}
                                       name="title"
                                />
                            </div>
                        </div>
                        <div>
                            <p className="label-2">Photo de la catégorie</p>
                            <div className="name">
                                <div>
                                    <input className="input" placeholder="Photo de la catégorie"
                                           type="file"
                                           name="image"
                                        //onChange={e => props.saveFile(e, "OfferTrace")}
                                           style={{margin: "auto"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="label-2">Plus d'informations</p>
                    <div className="name">
                        <textarea className="input-offer-desc" placeholder="Plus d'informations"
                                  maxLength="10000" rows="5"
                                  value={element.description}
                                  onChange={e => handleCategory(index, e)}
                                  name="description"
                                  style={{margin: "auto"}}/>
                    </div>
                    <hr style={{margin: "30px 0px"}}/>
                    {element.variants.map((variantCat, indexVar) => {
                        return <div style={{display: "flex"}}>
                            <div style={{flexBasis: "70%"}}>
                                <h2> {variantCat.endDate ? `${getFormattedDate(parseInt(variantCat.startDate))} au ${getFormattedDate(parseInt(variantCat.endDate))}` : `Variante n°${indexVar}`}</h2>
                                <div>
                                    <p className="label-2">Nom de la variante</p>
                                    <div className="name">
                                        <input className="input-offer-desc" placeholder="Nom de la variante"
                                               value={variantCat.title}
                                               name="title"
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               style={{margin: "auto"}}/>
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
                                    <div style={{width: "22%"}}>
                                        <p>Tarif</p>
                                        <input className="input-offer-desc" name="price" type="number" value={variantCat.price}
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               placeholder="Tarifs"/>
                                    </div>
                                    <div style={{width: "22%"}}>
                                        <p>Tarif adhérant</p>
                                        <input className="input-offer-desc" name="minPrice" type="number" value={variantCat.minPrice}
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               placeholder="Tarifs Réduit"/>
                                    </div>
                                    <div style={{width: "22%"}}>
                                        <p>Stock</p>
                                        <input className="input-offer-desc" name="stock" type="number" value={variantCat.stock}
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               placeholder="Stock"/>
                                    </div>
                                    <div>
                                        <p>Carte d'adhérants</p>
                                        <select className="input-offer-desc" name="cardId" value={variantCat.cardId}
                                                onChange={e => handleVariants(index, indexVar, e)}>
                                            <option value="">Pas de carte</option>
                                            <option value="card1">Card 1</option>
                                            <option value="card2">Card 2</option>
                                            <option value="card3">Card 3</option>
                                        </select>
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
                                    <div style={{width: "30%"}}>
                                        <p>TVA</p>
                                        <input className="input-offer-desc" name="tva" type="number" value={variantCat.tva}
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               placeholder="TVA"/>
                                    </div>
                                    <div style={{width: "30%"}}>
                                        <p>Quantité Max / Commande </p>
                                        <input className="input-offer-desc" name="maxQuantity" type="number" value={variantCat.maxQuantity}
                                               onChange={e => handleVariants(index, indexVar, e)}
                                               placeholder="maxQuantity"/>
                                    </div>
                                </div>
                                {!element.option && <div style={{display: "flex", justifyContent: "space-between", marginTop: "15px"}}>
                                    <div style={{width: "50%"}}>
                                        <p>Calendrier</p>
                                        <Calendar dates={[variantCat.startDate, variantCat.endDate]} value={[variantCat.startDate, variantCat.endDate]} handleVariants={e => handleCalendar(index, indexVar, e)}/>
                                    </div>
                                    <div
                                        style={{display: "flex", border: "1px solid white", borderRadius: "10px", padding: "15px", height: "fit-content"}}>
                                        <input style={{margin: "auto"}} checked={variantCat.needVehiclePresentation}
                                               onChange={e => handleCheckboxCategoryVariants(index, indexVar, e)}
                                               type="checkbox" id="vehicle" name="needVehiclePresentation"/>
                                        <label style={{margin: "auto", marginLeft: "20px"}} htmlFor="vehicle">Présenter mon véhicule</label>
                                    </div>
                                </div>
                                }
                                {
                                    indexVar ?
                                        <button type="button" className="button-remove" style={{marginTop: "20px"}}
                                                onClick={() => removeVariants(index, indexVar)}>Remove Variant</button>
                                        : null
                                }
                                <hr style={{margin: "30px 0px"}}/>
                            </div>
                            <div style={{flexBasis: "30%", display: "flex"}}>
                                <div style={{background: "white", color: "black", width: "90%", borderRadius: "10px", margin: "auto", padding: "30px 10px"}}>
                                    <strong>{ variantCat.endDate ? getFormattedDate(parseInt(variantCat.startDate)) : "Vendredi 27" +
                                        " déc." }</strong>
                                    <p style={{margin: "10px 0px"}}>{variantCat.title || "Titre de la catégorie"}</p>
                                    <p style={{fontSize: "20px", fontWeight: "800"}}>{variantCat.price || 50} €</p>
                                    <button style={{padding: "10px", marginTop: "20px", backgroundColor: "#3E495C", border: 0, borderRadius: "10px", outline: 0, color: "white"}}>Sélectionner</button>
                                </div>
                            </div>
                        </div>
                    })}
                    <button className="button-add" type="button" style={{marginTop: "15px"}}  onClick={() => addVariants(index)}>Ajouter une variante d'offre</button>

                    {
                        index ?
                            <button type="button" className="button-remove"
                                    onClick={() => removeOptions(index)}>Remove Category</button>
                            : null
                    }
                </div>
            })}
            <button className="button-add" type="button" onClick={() => addOptions()} style={{margin: "15px 0px", width: "30%"}}>Ajouter une catégorie</button>
        </div>
    </div>)
}

export default CategoryOffers
