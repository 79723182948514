import axios from "../axios"

import {
    DISCIPLINES_CREATE_FAIL,
    DISCIPLINES_CREATE_REQUEST, DISCIPLINES_CREATE_SUCCESS,
    DISCIPLINES_DELETE_FAIL,
    DISCIPLINES_DELETE_REQUEST,
    DISCIPLINES_DELETE_SUCCESS,
    DISCIPLINES_DETAILS_FAIL,
    DISCIPLINES_DETAILS_REQUEST,
    DISCIPLINES_DETAILS_SUCCESS,
    DISCIPLINES_LIST_FAIL,
    DISCIPLINES_LIST_REQUEST,
    DISCIPLINES_LIST_SUCCESS,
    DISCIPLINES_UPDATE_FAIL,
    DISCIPLINES_UPDATE_REQUEST,
    DISCIPLINES_UPDATE_SUCCESS
} from "../constants/disciplinesConstants";

export const listDisciplines = () => async (dispatch) => {
    try {
        dispatch({type: DISCIPLINES_LIST_REQUEST})
        const {data} = await axios.get('/disciplines')
        dispatch({
            type: DISCIPLINES_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DISCIPLINES_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getDisciplines = (id) => async (dispatch) => {
    try {
        dispatch({type: DISCIPLINES_DETAILS_REQUEST})
        const {data} = await axios.get(`/disciplines/${id}`)
        dispatch({
            type: DISCIPLINES_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: DISCIPLINES_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deleteDisciplines = (id) => async (dispatch) => {
    try {
        dispatch({
            type: DISCIPLINES_DELETE_REQUEST,
        })
        await axios.delete(`/disciplines/${id}`)

        dispatch({
            type: DISCIPLINES_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DISCIPLINES_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createDisciplines = (createdDisciplines) => async (dispatch) => {
    try {
        dispatch({
            type: DISCIPLINES_CREATE_REQUEST,
        })
        await axios.post(`/disciplines`, createdDisciplines)

        dispatch({
            type: DISCIPLINES_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: DISCIPLINES_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updateDisciplines = (updatedDisciplines, id) => async (dispatch) => {
    try {
        dispatch({
            type: DISCIPLINES_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/disciplines/${id}`, updatedDisciplines)

        dispatch({
            type: DISCIPLINES_UPDATE_SUCCESS,
            payload: data.response
        })
    } catch (error) {
        dispatch({
            type: DISCIPLINES_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
