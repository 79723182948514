import React from 'react'
import '../../assets/css/newBackOfficeBdd.css';
import '../../assets/css/style.css'


const Licences = (props) => {

    return (
        <div className="licences">
            <p className="licence-titles">Licences admises</p>
            <div className="subPres">
                <div className="admises-FFM">
                    {props.Licences?.map((licences, index) => {
                        if (props.allLicences.includes(licences.id)) {
                            return (
                                <button className="am-button actif" onClick={
                                    () => {
                                        props.removeArray(licences.id, props.allLicences, props.setAllLicences)
                                    }}>
                                    {licences.name}
                                </button>)
                        } else if (!props.allLicences.includes(licences.id)) {
                            return (
                                <button className="am-button"
                                        onClick={() => props.setAllLicences([...props.allLicences, licences.id])}>
                                    {licences.name}
                                </button>)
                        }
                        return null
                    })}
                </div>
            </div>
            <p className="licence-titles">Homologations pistes</p>
            <div className="subPres">
                <div className="admises-FFM">
                    {props.Homologations?.map((homologation, index) => {
                        if (props.allHomologations.includes(homologation.id)) {
                            return (
                                <button className="am-button actif" onClick={
                                    () => {
                                        props.removeArray(homologation.id, props.allHomologations, props.setAllHomologations)
                                    }}>
                                    {homologation.certification}
                                </button>)
                        } else if (!props.allHomologations.includes(homologation.id)) {
                            return (
                                <button className="am-button"
                                        onClick={() => props.setAllHomologations([...props.allHomologations, homologation.id])}>
                                    {homologation.certification}
                                </button>)
                        }
                        return null
                    })}
                </div>
            </div>
        </div>
    )
}

export default Licences