import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {deleteLicences, getLicences, updateLicences} from "../../../../actions/licencesActions";
import {listOrganisations} from "../../../../actions/organisationsActions";
import {listActivityTypes} from "../../../../actions/activityTypesActions";
import { LICENCES_UPDATE_RESET } from "../../../../constants/licencesConstants";
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditLicences = (props) => {
    const {id: licencesId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let licencesDetails = useSelector(state => state.licencesDetails)
    const {loading, error, Licences} = licencesDetails

    let licencesUpdate = useSelector(state => state.licencesUpdate)
    const {error: errorUpdate, success: successUpdate} = licencesUpdate

    let licencesDelete = useSelector(state => state.licencesDelete)
    const {success: successDelete} = licencesDelete

    const [licenceCode, setLicenceCode] = useState("")
    const [licenceOrg, setLicenceOrg] = useState("")
    const [licenceName, setLicenceName] = useState("")
    const [licencePrice, setLicencePrice] = useState("")
    const [licenceDuree, setLicenceDuree] = useState("")

    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formActivityValues, setFormActivityValues] = useState([])

    let activityTypesList = useSelector(state => state.activityTypesList)
    const {ActivityTypes} = activityTypesList

    let organisationsList = useSelector(state => state.organisationsList)
    const {organisations} = organisationsList

    let handleActivityChange = (i, e) => {
        let newFormValues = [...formActivityValues];
        newFormValues[i]["activityId"] = e.target.value;
        setFormActivityValues(newFormValues);
    }

    let addActivityFormFields = () => {
        setFormActivityValues([...formActivityValues, {activityId: ""}])
    }

    let removeActivityFormFields = (i) => {
        let newFormValues = [...formActivityValues];
        newFormValues.splice(i, 1);
        setFormActivityValues(newFormValues)
    }

    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    const EditHandler = () => {
        let data = {
            code: licenceCode,
            name: licenceName,
            duree: licenceDuree,
            prix: licencePrice,
            organizationId: licenceOrg,
            activityTypesIds: formActivityValues
        }
        dispatch(updateLicences(data, licencesId))
    }

    const deleteHandler = () => {
        dispatch(deleteLicences(licencesId))
    }

    useEffect(() => {
        dispatch(getLicences(licencesId))
        dispatch(listOrganisations())
        dispatch(listActivityTypes())
    }, [dispatch, licencesId])

    useEffect(() => {
        if (successDelete) {
            history('/licences')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getLicences(licencesId))
            dispatch({type: LICENCES_UPDATE_RESET})
            setTimeout(() => {
                history('/licences')
            }, 1000)
            setTimeout(() => {
                setOpen(false)
                history('/licences')
            }, 3000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (Licences) {
            setLicenceName(Licences.name)
            setLicenceOrg(Licences.organizations)
            setLicencePrice(Licences.prix)
            setLicenceDuree(Licences.duree)
            setLicenceCode(Licences.code)

            let activitySplit = Licences.activityTypesIds?.split("/")

            let newActivityFormValues = []
            newActivityFormValues.push({activityId: ""})
            activitySplit?.forEach((activity, index) => {
                if (index === 0) {
                    newActivityFormValues.pop()
                }
                newActivityFormValues.push({activityId: activity})
            })
            setFormActivityValues(newActivityFormValues)
        }
    }, [dispatch, licencesId, history, Licences, successUpdate, errorUpdate, successDelete])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    La licence a bien été modifiée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Licences</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <button className="buttonTabs showActiveBackground">
                        <InfoRounded className="tabs-icon"/>
                        <p style={{margin: "auto"}}>Informations</p>
                    </button>
                </div>
                <div className="circuitMenus">
                    <div className="subCoord">
                        <div className="names">
                            <p className="label-2">Nom de la licence</p>
                            <input className="input" placeholder="Nom de la licence"
                                   value={licenceName}
                                   onChange={e => setLicenceName(e.target.value)}
                            />
                            <p className="label-2">Code</p>
                            <input className="input" placeholder="Code"
                                   value={licenceCode}
                                   onChange={e => setLicenceCode(e.target.value)}
                            />
                            <p className="label-2">Activités</p>
                            {formActivityValues.map((element, index) => (
                                <div className="name" key={`${element}_${index}`}>
                                    <select className="state" value={element.activityId}
                                            onChange={e => handleActivityChange(index, e)}>
                                        <option className="sub" value="">-- Choisissez une activité --</option>
                                        {ActivityTypes.map(at => {
                                            return <option key={at.id} value={at.id}>{at.name}</option>
                                        })}
                                    </select>
                                    {
                                        index ?
                                            <button type="button" className="button-remove"
                                                    onClick={() => removeActivityFormFields(index)}>Remove</button>
                                            : null
                                    }
                                </div>
                            ))}
                            <button className="button-add" type="button" onClick={() => addActivityFormFields()}>Add</button>
                            <p className="label-2">Organisations</p>
                            <div className="name" style={{width: "400px"}}>
                                <select className="state"
                                        value={licenceOrg}
                                        label={"Organisations"}
                                        onChange={e => setLicenceOrg(e.target.value)}>
                                    <option className="sub" value="">-- Choisissez une Organisation --</option>
                                    {organisations.map(orga => {
                                        if (orga.id === licenceOrg) {
                                            return <option key={orga.id} value={orga.id} selected>{orga.name}</option>
                                        } else {
                                            return <option key={orga.id} value={orga.id}>{orga.name}</option>
                                        }
                                    })}
                                </select>
                            </div>

                            <p className="label-2">Prix</p>
                            <input className="input" placeholder="Prix"
                                   value={licencePrice}
                                   onChange={e => setLicencePrice(e.target.value)}
                            />
                            <p className="label-2">Durée</p>
                            <textarea className="input" placeholder="Durée"
                                      value={licenceDuree}
                                      onChange={e => setLicenceDuree(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditLicences
