import {
    FACILITIES_CREATE_FAIL,
    FACILITIES_CREATE_REQUEST,
    FACILITIES_CREATE_SUCCESS,
    FACILITIES_CREATE_RESET,
    FACILITIES_DELETE_FAIL,
    FACILITIES_DELETE_REQUEST,
    FACILITIES_DELETE_SUCCESS,
    FACILITIES_DETAILS_FAIL,
    FACILITIES_DETAILS_REQUEST,
    FACILITIES_DETAILS_SUCCESS,
    FACILITIES_LIST_FAIL,
    FACILITIES_LIST_REQUEST,
    FACILITIES_LIST_SUCCESS,
    FACILITIES_UPDATE_FAIL,
    FACILITIES_UPDATE_REQUEST,
    FACILITIES_UPDATE_SUCCESS,
    FACILITIES_UPDATE_RESET
} from "../constants/facilitiesConstants";

export const facilitiesListReducers = (state = {Facilities: []}, action) => {
    switch (action.type) {
        case FACILITIES_LIST_REQUEST:
            return {loading: true, Facilities: []}
        case FACILITIES_LIST_SUCCESS:
            return {loading: false, Facilities: action.payload}
        case FACILITIES_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const facilitiesDetailsReducers = (state = {Facilities: {}}, action) => {
    switch (action.type) {
        case FACILITIES_DETAILS_REQUEST:
            return {loading: true, ...state}
        case FACILITIES_DETAILS_SUCCESS:
            return {loading: false, Facilities: action.payload}
        case FACILITIES_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const facilitiesCreateReducers = (state = {}, action) => {
    switch (action.type) {
        case FACILITIES_CREATE_REQUEST:
            return {loading: true}
        case FACILITIES_CREATE_SUCCESS:
            return {loading: false, success: true}
        case FACILITIES_CREATE_FAIL:
            return {loading: false, error: action.payload}
        case FACILITIES_CREATE_RESET:
            return {}
        default:
            return state
    }
}

export const facilitiesDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case FACILITIES_DELETE_REQUEST:
            return {loading: true}
        case FACILITIES_DELETE_SUCCESS:
            return {loading: false, success: true}
        case FACILITIES_DELETE_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state
    }
}

export const facilitiesUpdateReducers = (state = {Facilities: {}}, action) => {
    switch (action.type) {
        case FACILITIES_UPDATE_REQUEST:
            return {loading: true}
        case FACILITIES_UPDATE_SUCCESS:
            return {loading: false, success: true, Facilities: action.payload}
        case FACILITIES_UPDATE_FAIL:
            return {loading: false, error: action.payload}
        case FACILITIES_UPDATE_RESET:
            return {}
        default:
            return state
    }
}
