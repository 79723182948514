import React from 'react'
import '../../assets/css/breadcrumb.css'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import {useNavigate, useLocation} from "react-router-dom";

const BreadcrumbWill = (props) => {
    const {pathname} = useLocation();
    const path = pathname.split('/');

    const history = useNavigate();
    //const path = history.location.pathname.split("/");

    return (
        <div className="breadcrumbFront">
            {path.map((element, index) => {
                let page;
                if (index === 0 && path.length >= 2) {
                    page = <span className={index !== path.length - 1 ? "text_here" : "text"} onClick={() => history.push(path[index])}>Accueil</span>;
                } else {
                    let tempPath = '';
                    for (let i = 0; i <= index; i++) {
                        tempPath += path[i];
                        if (i !== index)
                            tempPath += '/';
                    }
                    page = <span className={index !== path.length - 1 ? "text_here" : "text"}
                                 onClick={() => history.push(tempPath)}>{element[0].toUpperCase() + element.slice(1, path[index].length)}</span>;
                }
                return path[index + 1] === '' || path[index + 1] === undefined
                    ?
                    page
                    :
                    <>
                        {page}
                        <ArrowForwardIosRoundedIcon className="arrow_icon"/>
                    </>;
            })}
        </div>
    )
}

export default BreadcrumbWill
