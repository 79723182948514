export const LICENCES_LIST_REQUEST = "LICENCES_LIST_REQUEST"
export const LICENCES_LIST_SUCCESS = "LICENCES_LIST_SUCCESS"
export const LICENCES_LIST_FAIL = "LICENCES_LIST_FAIL"

export const LICENCES_DETAILS_REQUEST = "LICENCES_DETAILS_REQUEST"
export const LICENCES_DETAILS_SUCCESS = "LICENCES_DETAILS_SUCCESS"
export const LICENCES_DETAILS_FAIL = "LICENCES_DETAILS_FAIL"

export const LICENCES_CREATE_REQUEST = "LICENCES_CREATE_REQUEST"
export const LICENCES_CREATE_SUCCESS = "LICENCES_CREATE_SUCCESS"
export const LICENCES_CREATE_FAIL = "LICENCES_CREATE_FAIL"
export const LICENCES_CREATE_RESET = "LICENCES_CREATE_RESET"

export const LICENCES_DELETE_REQUEST = "LICENCES_DELETE_REQUEST"
export const LICENCES_DELETE_SUCCESS = "LICENCES_DELETE_SUCCESS"
export const LICENCES_DELETE_FAIL = "LICENCES_DELETE_FAIL"

export const LICENCES_UPDATE_REQUEST = "LICENCES_UPDATE_REQUEST"
export const LICENCES_UPDATE_SUCCESS = "LICENCES_UPDATE_SUCCESS"
export const LICENCES_UPDATE_FAIL = "LICENCES_UPDATE_FAIL"
export const LICENCES_UPDATE_RESET = "LICENCES_UPDATE_RESET"
