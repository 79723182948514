import React, {useEffect, useState} from 'react';
import MuiAlert from "@mui/material/Alert";
import {useDispatch, useSelector} from "react-redux";
import {deleteActivityTypes, getActivityTypes, updateActivityTypes} from "../../../../actions/activityTypesActions";
import Snackbar from "@mui/material/Snackbar";
import ProfileButton from "../../../../components/profileButton";
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import SettingsIcon from "@material-ui/icons/Settings";
import {InfoRounded} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import {useNavigate, useParams} from "react-router-dom";
import { ACTIVITY_TYPES_UPDATE_RESET } from '../../../../constants/activityTypesConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditSimpleActivityTypes = (props) => {
    const {id: activityTypeId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    let activityTypesDetails = useSelector(state => state.activityTypesDetails)
    const {loading, error, ActivityTypes} = activityTypesDetails

    let activityTypesUpdate = useSelector(state => state.activityTypesUpdate)
    const {error: errorUpdate, success: successUpdate} = activityTypesUpdate

    let activityTypesDelete = useSelector(state => state.activityTypesDelete)
    const {success: successDelete} = activityTypesDelete

    const [name, setName] = useState("")
    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }


    const EditHandler = () => {
        let data = {
            name: name,
        }
        dispatch(updateActivityTypes(data, activityTypeId))
    }

    const deleteHandler = () => {
        dispatch(deleteActivityTypes(activityTypeId))
    }

    useEffect(() => {
        if (successDelete) {
            history('/activite')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getActivityTypes(activityTypeId))
            dispatch({type: ACTIVITY_TYPES_UPDATE_RESET})
            setTimeout(() => {
                history('/activity-types')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }

        if (activityTypeId !== ActivityTypes[0]?.id.toString()) {
            dispatch(getActivityTypes(activityTypeId))
        } else {
            setName(ActivityTypes[0].name)
        }
    }, [dispatch, history, activityTypeId, ActivityTypes, successUpdate, errorUpdate, successDelete])

    return(
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    L'activité a bien été modifiée !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Activité</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div className="circuitMenus">
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div>
                <div className="circuitMenus">
                    <div className="subInfos">
                        <div className="infos">
                            <p className="label-2">Nom</p>
                            <input className="input" placeholder="Nom"
                                   value={name}
                                   onChange={e => setName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditSimpleActivityTypes
