import React, {useEffect, useState} from 'react'
import BreadcrumbBDD from "../../../../components/navigation/breadcrumbBdd";
import ProfileButton from '../../../../components/profileButton';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import '../../../../assets/css/newBackOfficeBdd.css'
import {InfoRounded} from "@material-ui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    deleteVehicleType,
    getVehicleTypes,
    updateVehicleType
} from "../../../../actions/vehiculeTypeAction";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {listDisciplines} from "../../../../actions/disciplinesActions";
import {listSegments} from "../../../../actions/segmentsActions";
import axios from "../../../../axios";
import SelectComponents from "../../../../components/ui/SelectComponents";
import {VEHICULES_TYPES_UPDATE_RESET} from '../../../../constants/vehiculeTypeConstants';
import BreadcrumbWill from "../../../../components/navigation/breadcrumbWill";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewVehicleType = (props) => {
    const {id: vehicleId} = useParams()
    const dispatch = useDispatch()
    let history = useNavigate()

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const [disciplines, setDisciplines] = useState([])
    const [segments, setSegments] = useState([])

    let vehiculeTypesUpdate = useSelector(state => state.vehiculeTypesUpdate)
    const {error: errorUpdate, success: successUpdate} = vehiculeTypesUpdate

    let vehiculeTypesDelete = useSelector(state => state.vehiculeTypesDelete)
    const {success: successDelete} = vehiculeTypesDelete



    const [name, setName] = useState("")
    const [header, setHeader] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [headerName, setHeaderName] = useState("")
    const [oldHeaderName, setOldHeaderName] = useState("")
    const [thumbnailName, setThumbnailName] = useState("")
    const [oldThumbnailName, setOldThumbnailName] = useState("")
    const [category, setCategory] = useState("")
    const [monoplace, setMonoplace] = useState(0)


    const [open, setOpen] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const [formValues, setFormValues] = useState([])

    const handleClose = () => {
        setOpen(false)
        setErrorOpen(false)
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i]["disciplineId"] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, {disciplineId: ""}])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const saveFile = async (e, type) => {
        switch (type) {
            case "Header":
                setHeader(e.target.files[0]);
                break;
            case "Thumbnail":
                setThumbnail(e.target.files[0]);
                break
            default:
                break
        }

    };

    const uploadFile = async () => {
        const formDataProfile = new FormData();
        const formDataThumbnail = new FormData();

        let publicIdHeader = oldHeaderName
        let publicIdThumbnail = oldThumbnailName
        try {
            if (header) {
                formDataProfile.append("file", header);
                formDataProfile.append("folder", "vehicleType/profile");
                formDataProfile.append("upload_preset", "zd2h6ivm")
                let res = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataProfile)
                publicIdHeader = res.data.public_id
            }

            if (thumbnail) {
                formDataThumbnail.append("file", thumbnail);
                formDataThumbnail.append("folder", "vehicleType/thumbnail");
                formDataThumbnail.append("upload_preset", "zd2h6ivm")
                let res2 = await axios.post('https://api.cloudinary.com/v1_1/accelerio/image/upload', formDataThumbnail)
                publicIdThumbnail = res2.data.public_id
            }

            return [publicIdHeader, publicIdThumbnail]
        } catch (ex) {
            console.log(ex);
        }
    };


    const EditHandler = async () => {
        uploadFile().then(res => {
            let data = {
                name: name,
                header: res[0],
                thumbnail: res[1],
                category: category,
                monoplace: monoplace,
                disciplinesIds: formValues
            }
            dispatch(updateVehicleType(data, vehicleId))
        })
    }

    const deleteHandler = () => {
        dispatch(deleteVehicleType(vehicleId))
    }

    const fetchVehicle = async () => {
        try {
            const {data} = await axios.get("/api/vehicleTypes/" + vehicleId)
            const listDisciplines = await axios.get("/disciplines")
            const listSegments = await axios.get("/api/segments")
            setDisciplines(listDisciplines.data)
            setSegments(listSegments.data)
            setName(data.name)
            setHeaderName(data.header)
            setOldHeaderName(data.header)
            setThumbnailName(data.thumbnail)
            setOldThumbnailName(data.thumbnail)
            setCategory(data.category)
            setMonoplace(data.monoplace)
            let tempFormValues = []
            data.disciplines.map(discipline => {
                tempFormValues.push({disciplineId: discipline})
            })
            setFormValues(tempFormValues)
        } catch (e) {
            setError(e.message)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchVehicle()
    }, [])

    useEffect(() => {
        if (successDelete) {
            history('/vehicules-types')
        }
        if (successUpdate) {
            setOpen(true)
            //dispatch(getVehicleTypes(vehicleId))
            dispatch({type: VEHICULES_TYPES_UPDATE_RESET})
            setTimeout(() => {
                history('/vehicules-types')
            }, 1000)
        } else if (errorUpdate) {
            setErrorOpen(true)
        }
    }, [dispatch, history, successUpdate, errorUpdate, successDelete])

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Le type de véhicule a bien été modifié !
                </Alert>
            </Snackbar>
            <ProfileButton/>
            <br/>
            <div style={{marginLeft: "40px"}}>
                <BreadcrumbWill/>
            </div>
            <div style={{marginBottom: "35px", marginTop: "35px", display: "flex"}}>
                <h2 className="page_title">Vehicules Types</h2>
                <button className="modify_button" onClick={() => EditHandler()}>
                    <SettingsIcon className="modify_icon"/>
                    <p style={{margin: "auto"}}>Enregistrer</p>
                </button>
                <button className="delete_button" onClick={() => deleteHandler()}>
                    <DeleteIcon className="delete_icon"/>
                    <p style={{margin: "auto"}}>Supprimer</p>
                </button>
            </div>
            <div className="circuitsMenus" style={{marginLeft: "40px"}}>
                <button className="buttonTabs showActiveBackground">
                    <InfoRounded className="tabs-icon"/>
                    <p style={{margin: "auto"}}>Informations</p>
                </button>
            </div>
            <div className="coordonnes" style={{marginLeft: "40px"}}>
                <div className="subCoord">
                    <div className="names">
                        <p className="label-2">Nom du type de véhicule</p>
                        <input className="input" placeholder="Nom du type de véhicules"
                               value={name}
                               onChange={e => setName(e.target.value)}
                        />
                        <p className="label-2">Miniature</p>
                        <input className="input" placeholder="Thumbnail picture"
                               type="file"
                               onChange={e => saveFile(e, "Thumbnail")}
                        />
                        <p className="label-2">Bannière</p>
                        <input className="input" placeholder="Header picture"
                               type="file"
                               onChange={e => saveFile(e, "Header")}
                        />
                        <p className="label-2">Catégorie</p>
                        <div className="name">
                        <select className="state" value={category} onChange={e => setCategory(e.target.value)}>
                            <option value="" className="sub">--- Choisissez votre catégorie ---</option>
                            {segments.map(elt => (
                                <option className="sub" key={elt.id} value={elt.name}>{elt.name}</option>
                            ))}
                        </select>
                        </div>
                        <div
                            style={{display: "flex", border: "1px solid white", borderRadius: "10px", padding: "15px"}}>
                            <input style={{margin: "auto"}} type="checkbox" id="option" name="option" checked={monoplace} onClick={e => setMonoplace(monoplace ? 0 : 1)}/>
                            <label style={{marginLeft: "20px"}} htmlFor="option">C'est une monoplace</label>
                        </div>
                        <p className="label-2">Disciplines</p>
                        {formValues.map((element, index) => (
                            <div className="name">
                                <select className="state" value={element.disciplineId}
                                        onChange={e => handleChange(index, e)}>
                                    <option className="sub" value="">-- Choisissez une Discipline
                                        --
                                    </option>
                                    {disciplines.map(discipline => {
                                        return <option key={discipline.id}
                                                       value={discipline.id}>{discipline.name}</option>
                                    })}
                                </select>
                                {
                                    index ?
                                        <button type="button" className="button-remove"
                                                onClick={() => removeFormFields(index)}>Remove</button>
                                        : null
                                }
                            </div>
                        ))}
                        <button className="button-add" type="button"
                                onClick={() => addFormFields()}>Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewVehicleType
