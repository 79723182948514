import axios from "../axios"

import {
    PILOTS_CREATE_FAIL,
    PILOTS_CREATE_REQUEST,
    PILOTS_CREATE_SUCCESS,
    PILOTS_DELETE_FAIL,
    PILOTS_DELETE_REQUEST,
    PILOTS_DELETE_SUCCESS,
    PILOTS_DETAILS_FAIL,
    PILOTS_DETAILS_REQUEST,
    PILOTS_DETAILS_SUCCESS,
    PILOTS_LIST_FAIL,
    PILOTS_LIST_REQUEST,
    PILOTS_LIST_SUCCESS,
    PILOTS_UPDATE_FAIL,
    PILOTS_UPDATE_REQUEST,
    PILOTS_UPDATE_SUCCESS
} from "../constants/pilotsConstants";

export const listPilots = () => async (dispatch) => {
    try {
        dispatch({type: PILOTS_LIST_REQUEST})
        const {data} = await axios.get('/api/drivers')
        dispatch({
            type: PILOTS_LIST_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PILOTS_LIST_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const getPilots = (id) => async (dispatch) => {
    try {
        dispatch({type: PILOTS_DETAILS_REQUEST})
        const {data} = await axios.get(`/api/drivers/${id}`)
        dispatch({
            type: PILOTS_DETAILS_SUCCESS,
            payload: data,
        })
    } catch (error) {
        dispatch({
            type: PILOTS_DETAILS_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const deletePilots = (id) => async (dispatch) => {
    try {
        dispatch({
            type: PILOTS_DELETE_REQUEST,
        })
        await axios.delete(`/api/drivers/${id}`)

        dispatch({
            type: PILOTS_DELETE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: PILOTS_DELETE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const createPilots = (createdPilots) => async (dispatch) => {
    try {
        dispatch({
            type: PILOTS_CREATE_REQUEST,
        })
        await axios.post(`/api/drivers`, createdPilots)

        dispatch({
            type: PILOTS_CREATE_SUCCESS,
        })
    } catch (error) {
        dispatch({
            type: PILOTS_CREATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}

export const updatePilots = (updatedPilots, id) => async (dispatch) => {
    try {
        dispatch({
            type: PILOTS_UPDATE_REQUEST,
        })
        const {data} = await axios.put(`/api/drivers/${id}`, updatedPilots)

        dispatch({
            type: PILOTS_UPDATE_SUCCESS,
            payload: data
        })
    } catch (error) {
        dispatch({
            type: PILOTS_UPDATE_FAIL,
            payload:
                error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
        })
    }
}
